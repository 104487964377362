import React from 'react';
import { Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

const TableNoResults = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Box justifyContent={'center'} alignItems={'center'} p={2}>
      <Typography variant="body2" textAlign={'center'}>
        {t('GENERIC.NO_RESULTS')}
      </Typography>
    </Box>
  );
};

export default TableNoResults;
