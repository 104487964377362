import React from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Box, Button } from '@mui/material';
import { useStateContext } from '../../utils/stateContext';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { DateComponent } from '../../utils/commonFunctions';
import CancelIcon from '@mui/icons-material/Cancel';

const BBCHUpdate = () => {
  const {
    t,
    setSubscriptionList,
    getData,
    snackbar: { success, error },
    agronomy: {
      BBCHModal: [isBBCHModalOpen, { off: closeBBCHModal }],
      handleUpdateObservationDate,
    },
  } = useStateContext();

  const handleCloseModal = () => {
    reset({});
    closeBBCHModal();
  };

  const validationSchema = Yup.object().shape({
    observation_date: Yup.date().required(),
  });

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema) });

  const submit = async (data) => {
    handleUpdateObservationDate({
      data,
      onSuccess: () => {
        success(t('AGRONOMY.OBSERVATION_DATE_ADDED'));
        setSubscriptionList([]);
        getData();
      },
      onFail: () => error(t('GENERIC.SOMETHING_WRONG')),
    });
    handleCloseModal();
  };

  return (
    <Modal
      open={isBBCHModalOpen}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '40%',
          left: '50%',
          transform: 'translate(-50%,-50%)',
          width: '30%',
          bgcolor: 'white',
          borderRadius: '10px',
        }}
      >
        <Box
          component="form"
          onSubmit={handleSubmit(submit)}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1.2vw',
            width: '100%',
            padding: '0vw 1vw',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography sx={{ fontWeight: 550, fontSize: '0.8em', mt: 1 }}>
              {t('AGRONOMY.BBCH')}
            </Typography>
            <CancelIcon onClick={handleCloseModal} fontSize="small" cursor="pointer" />
          </Box>
          <DateComponent
            name="observation_date"
            label={t('AGRONOMY.PICK_DATE')}
            control={control}
            errors={errors}
          />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-evenly',
              mb: 2,
            }}
          >
            <Button onClick={handleCloseModal} sx={{ width: 150, height: 35 }} variant="outlined">
              <Typography variant="body2">{t('RESET.CANCEL')}</Typography>
            </Button>
            <Button type="submit" sx={{ width: 150, height: 35 }} variant="contained">
              {t('GENERIC.SAVE')}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
export default BBCHUpdate;
