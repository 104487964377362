import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Box, Button, FormControl, InputLabel, OutlinedInput } from '@mui/material';
import { useStateContext } from '../../utils/stateContext';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { DateComponent } from '../../utils/commonFunctions';
import CancelIcon from '@mui/icons-material/Cancel';
import dayjs from 'dayjs';
import GrowerAPI from '../../api/grower';

const ActualSprayDateModal = () => {
  const {
    t,
    getData,
    snackbar: { success, error },
    agronomy: {
      selectedField: [selectedField, setSelectedField],
      ActualSprayDateModal: [isActualSprayDateModalOpen, { off: closeActualSprayDateModal }],
      handleSetActualSprayDate,
    },
  } = useStateContext();

  const [treatment, setTreatment] = useState('');

  const handleTreatment = (value) => {
    setTreatment(value);
  };
  const validationSchema = Yup.object().shape({
    treatment_date: Yup.date().required(),
    dose_rate: Yup.string().required(),
    product: Yup.string().required(),
  });
  const {
    register,
    setValue,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema) });

  useEffect(() => {
    if (selectedField) {
      setValue(
        'treatment_date',
        selectedField?.treatments?.[selectedField?.t_window]?.treatment_date
          ? dayjs(
              selectedField?.treatments?.[selectedField?.t_window]?.treatment_date,
              'YYYY-MM-DD'
            )
          : null
      );
      setValue('dose_rate', selectedField?.treatments?.[selectedField?.t_window]?.dose_rate);
      setValue('product', selectedField?.treatments?.[selectedField?.t_window]?.product);
      setTreatment(selectedField?.t_window);
    }
  }, [selectedField, setValue]);

  const submit = (data) => {
    handleSetActualSprayDate({
      data,
      t_window: treatment,
      onSuccess: () => {
        success(t('AGRONOMY.BBCH.TREATMENT_DETAILS_ADDED'));
        getData();
      },
      onFail: () => {
        error(t('ALERT.MSG2'));
      },
    });
    handleClose(reset);
    setValue('treatment_date', null);
    setValue('product', '');
    setValue('dose_rate', null);
    setTreatment('');
  };

  const handleDelete = () => {
    GrowerAPI.deleteTreatments({
      id: selectedField?.treatments?.[selectedField?.t_window]?.id,
    }).then((response) => {
      if (response?.status === 200) {
        success(t('AGRONOMY.BBCH.TREATMENT_DETAILS_DELETED'));
        getData();
        handleClose(reset);
      } else {
        error(t('AGRONOMY.PRODUCT_DETAILS_NOT_FOUND'));
      }
    });
  };

  const handleClose = () => {
    reset({});
    setSelectedField({});
    closeActualSprayDateModal();
  };

  return (
    <Modal
      open={isActualSprayDateModalOpen}
      onClose={() => handleClose(reset)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 'auto',
          padding: '1vw',
          height: 'auto',
          bgcolor: 'white',
          borderRadius: '10px',
        }}
      >
        <Box
          component="form"
          onSubmit={handleSubmit(submit)}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1.2vw',
            width: '100%',
            padding: '0vw 1vw',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography sx={{ fontWeight: 550, fontSize: '0.8em', mt: 1 }}>
              {t('AGRONOMY.ACT_DATE')}
            </Typography>
            <CancelIcon onClick={handleClose} fontSize="small" cursor="pointer" />
          </Box>
          <DateComponent
            name="treatment_date"
            label={t('AGRONOMY.PICK_DATE')}
            control={control}
            errors={errors}
          />
          <FormControl variant="outlined" size="small" fullWidth>
            <InputLabel htmlFor="outlined-adornment-farmName">{'Product'}</InputLabel>
            <OutlinedInput
              id="outlined-adornment-farmName"
              type="text"
              name="product"
              label="Product"
              {...register('product')}
              error={errors.product ? true : false}
            />
            <span style={{ color: 'red', fontSize: '12px' }}>{errors.product?.message}</span>
          </FormControl>
          <FormControl variant="outlined" size="small" fullWidth>
            <InputLabel htmlFor="outlined-adornment-farmName">{'Dose rate'}</InputLabel>
            <OutlinedInput
              id="outlined-adornment-farmName"
              type="text"
              name="dose_rate"
              label="Dose rate"
              {...register('dose_rate')}
              error={errors.dose_rate ? true : false}
            />
            <span style={{ color: 'red', fontSize: '12px' }}>{errors.dose_rate?.message}</span>
          </FormControl>
          <Box>
            <Typography variant="body2" sx={{ m: 0, p: 0 }}>
              T-window
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '1vw' }}>
              <Button
                sx={{ width: 30, height: 22 }}
                variant={treatment === 'T1' ? 'contained' : 'outlined'}
                value={treatment}
                onClick={() => handleTreatment('T1')}
              >
                T1
              </Button>
              <Button
                sx={{ width: 30, height: 22 }}
                variant={treatment === 'T2' ? 'contained' : 'outlined'}
                value={treatment}
                onClick={() => handleTreatment('T2')}
              >
                T2
              </Button>
              <Button
                sx={{ width: 30, height: 22 }}
                variant={treatment === 'T3' ? 'contained' : 'outlined'}
                value={treatment}
                onClick={() => handleTreatment('T3')}
              >
                T3
              </Button>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-evenly',
              mb: 2,
              gap: 2,
            }}
          >
            <Button
              onClick={() => {
                handleDelete();
              }}
              sx={{ width: 150, height: 35 }}
              variant="outlined"
            >
              <Typography variant="body2">{t('GENERIC.DELETE')}</Typography>
            </Button>
            <Button type="submit" sx={{ width: 150, height: 35 }} variant="contained">
              {t('GENERIC.SAVE')}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
export default ActualSprayDateModal;
