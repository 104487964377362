import React from 'react';
import { TableCell, TableRow } from '@mui/material';
import { StyledTableCell } from '../../utils/commonFunctions';
import DateFilter from '../Agronomy/DateFilter';
import RiskFilter from '../Agronomy/RiskFilter';
import TextFilter from '../Agronomy/TextFilter';
import TWindowFilter from '../Agronomy/TWindowFilter';
import BooleanFilter from './BooleanFilter';

const FilterBar = ({
  columns,
  criteria,
  handleUpdateFilter,
  handleUpdateDateFilter,
  isOpen = false,
  selectable = true,
}): JSX.Element => {
  if (!isOpen) return <></>;

  return (
    <TableRow sx={{ bgcolor: '#E8F1F7', padding: 'none' }}>
      {selectable && <TableCell sx={{ bgcolor: '#E8F1F7', height: '69px' }}></TableCell>}
      {columns.map((column, i) => (
        <React.Fragment key={column.name}>
          <StyledTableCell
            sx={{
              border: '1px solid #DDDDDD',
              bgcolor: '#E8F1F7',
              m: 0,
              p: 0,
              width: `${column.width}px`,
              height: '69px',
              textAlign: 'center',
              ...(column.sticky
                ? { position: 'sticky', left: `${i * column.width}px`, zIndex: 10 }
                : {}),
            }}
          >
            {column.search && (
              <TextFilter
                key={column.name}
                value={criteria[column.name] || ''}
                onChange={handleUpdateFilter(column.name)}
              />
            )}
            {column.date && (
              <DateFilter
                onChange={handleUpdateDateFilter}
                column={column}
                criteria={criteria[column.name]}
              />
            )}
            {column.risk && (
              <RiskFilter
                onChange={handleUpdateFilter(column.name)}
                value={criteria[column.name]}
              />
            )}
            {column.treatmentFiter && (
              <TWindowFilter
                onChange={handleUpdateFilter(column.name)}
                value={criteria[column.name]}
              />
            )}
            {column.boolean && (
              <BooleanFilter
                onChange={handleUpdateFilter(column.name)}
                value={criteria[column.name]}
              />
            )}
          </StyledTableCell>
        </React.Fragment>
      ))}
    </TableRow>
  );
};

export default FilterBar;
