import React, { useState } from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import SubscriptionTable from './SubscriptionTable';
import ProductDelivery from './ProductDelivery';

const CustomTable = ({ ...props1 }) => {
  const [value, setValue] = React.useState(0);
  const [, setLegend] = useState(true);

  const handleChange = (event, newValue) => {
    newValue === 1 ? setLegend(false) : setLegend(true);
    setValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ padding: '1vw 0vw' }}>{children}</Box>}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

  return (
    <Box
      sx={{
        margin: '10px 10px',
        backgroundColor: 'white',
        width: 'auto',
        minHeight: '90vh',
        height: 'auto',
      }}
    >
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          sx={{ minHeight: 40, height: 40 }}
        >
          <Tab
            label="Subscription list"
            {...a11yProps(0)}
            color="primary"
            sx={{
              '&.MuiTab-root': {
                textTransform: 'none',
                color: '#0B617F',
              },
            }}
          />
          <Tab
            label="Product delivery"
            {...a11yProps(1)}
            color="primary"
            sx={{
              '&.MuiTab-root': {
                textTransform: 'none',
                color: '#0B617F',
              },
            }}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <SubscriptionTable props={props1} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ProductDelivery />
      </TabPanel>
    </Box>
  );
};

export default CustomTable;
