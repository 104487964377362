import React from 'react';
import { Switch, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useStateContext } from '../../utils/stateContext';

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 180,
  height: 32,
  padding: 0,
  border: '1px solid #0B617F',
  borderRadius: '17px',
  '& .MuiSwitch-switchBase': {
    padding: 0,

    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(95px)',

      color: '#fff',

      '& + .MuiSwitch-track': {
        backgroundColor: '#E9E9EA',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #0B617F',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 100,
    height: 31,
    borderRadius: 32 / 2,
    backgroundColor: '#0B617F',
  },
  '& .MuiSwitch-track': {
    borderRadius: 32 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#0B617F',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

function CustomSwitch({ isChecked, setIsChecked }) {
  const { t, local } = useStateContext();

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      position="relative"
      width={180}
      height={40}
      borderRadius={20}
      onClick={() => setIsChecked(!isChecked)}
    >
      <IOSSwitch
        checked={isChecked}
        onChange={() => setIsChecked(!isChecked)}
        color="default"
        sx={{
          position: 'absolute',
          left: isChecked ? 'auto' : 0,
          right: isChecked ? 0 : 'auto',
        }}
        inputProps={{ 'aria-label': 'controlled' }}
      />
      <Typography
        variant="subtitle1"
        onClick={() => setIsChecked(!isChecked)}
        sx={{
          fontSize: 14,
          position: 'absolute',
          left: local === 'fr' ? 16 : 10,
          color: isChecked ? 'black' : 'white',
          '&:hover': { cursor: 'pointer' },
        }}
      >
        {t('GENERIC.SUBSCRIPTION')}
      </Typography>
      <Typography
        variant="subtitle1"
        onClick={() => setIsChecked(!isChecked)}
        sx={{
          fontSize: '85%',
          position: 'absolute',
          right: 10,
          color: isChecked ? 'white' : 'black',
          '&:hover': { cursor: 'pointer' },
        }}
      >
        {t('GENERIC.AGRONOMY')}
      </Typography>
    </Box>
  );
}

export default CustomSwitch;
