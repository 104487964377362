import React, { useEffect, useState } from 'react';
import { Typography, Box, AppBar, Toolbar, Avatar, Menu, MenuItem, Badge } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import BrandIcon from '../../images/brandlogo2x.png';
import GrowerAPI from '../../api/grower';
import Divider from '@mui/material/Divider';
import ToggleButton from '../../components/ToggleButton';
import { useStateContext } from '../../utils/stateContext';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';

import SnackbarCustom from '../Snackbar';

const styleObj = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  gap: '1vw',
  width: '70%',
};

const Header = ({ isChecked, setIsChecked, isToggle }) => {
  const { t, navigate, userDetails, userData, user, logout, notifications, setNotifications } =
    useStateContext();
  const [badgeContent, setBadgeContent] = useState(null);

  const [openBar, setOpenBar] = useState(false);
  const [severity] = useState('');
  const [alertMessage] = useState('');

  const getNotifications = () => {
    GrowerAPI.getNotifications(userDetails?.distributor_id).then((data) => {
      setNotifications(data);
      setBadgeContent(data.filter((item) => item.is_read !== true).length);
    });
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorNoteEl, setNoteAnchorEl] = React.useState(null);
  const openNote = Boolean(anchorNoteEl);
  const handleNoteClick = (event) => {
    setNoteAnchorEl(event.currentTarget);
    getNotifications();
  };
  const handleNoteClose = () => {
    setNoteAnchorEl(null);
  };
  useEffect(() => {
    if (notifications.length <= 0) {
      getNotifications();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" color="" sx={{ height: '9%' }}>
        <Toolbar sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <SnackbarCustom
            open={openBar}
            setOpen={setOpenBar}
            alertMessage={alertMessage}
            severity={severity}
          />
          <Box sx={styleObj}>
            <MenuIcon fontSize="medium" onClick={() => navigate('/home')} />
            <img
              src={BrandIcon}
              alt=""
              style={{ width: '15%', padding: '0.5vw' }}
              onClick={() => navigate('/home')}
            />
            <Divider orientation="vertical" variant="middle" flexItem />
            {!isToggle && (
              <ToggleButton isChecked={isChecked} setIsChecked={setIsChecked}></ToggleButton>
            )}
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end', gap: '0.5vw' }}>
            <Badge
              badgeContent={badgeContent}
              color="primary"
              sx={{ mr: 2 }}
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={openNote ? 'true' : undefined}
              onClick={handleNoteClick}
            >
              <NotificationsOutlinedIcon color="primary" />
            </Badge>
            <Menu
              anchorEl={anchorNoteEl}
              open={openNote}
              onClose={handleNoteClose}
              PaperProps={{
                style: {
                  maxHeight: 48 * 4.5,
                  width: '40ch',
                },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  p: '0 10px',
                }}
              >
                <Typography variant="body2" fontWeight="550" color="primary">
                  {t('NOTIFICATIONS')}
                </Typography>
                <OpenInFullIcon color="primary" onClick={() => navigate('home/notifications')} />
              </Box>

              {notifications.map((notification) => (
                <MenuItem key={notification.id} onClick={handleClose}>
                  <Typography variant="body2">
                    {notification.created_date}
                    <br />
                    {notification.body}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
            <Avatar
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              sx={{ width: 32, height: 32, bgcolor: '#0B617F' }}
            >
              <Typography variant="body2">
                {userData?.profile?.firstName.split('')[0]}
                {userData?.profile?.lastName.split('')[0]}
              </Typography>
            </Avatar>
            <div>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem onClick={() => navigate('/profile')}>{'My Profile'}</MenuItem>
                <MenuItem onClick={logout}>{t('LOGOUT')}</MenuItem>
              </Menu>
            </div>
            <Typography variant="body2" sx={{ fontSize: '0.9em' }}>
              <b>
                {userData?.profile?.firstName || user?.profile?.firstName}{' '}
                {userData?.profile?.lastName || user?.profile?.lastName}
              </b>
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};
export default Header;
