import { useState } from 'react';
import useBoolean from '../hooks/useBoolean';
import GrowerAPI from '../api/grower';
import dayjs from 'dayjs';

const useAgronomy = () => {
  const BBCHModal = useBoolean();
  const ActualSprayDateModal = useBoolean();
  const SearchBar = useBoolean();
  const InviteModal = useBoolean();
  const selectedField = useState();

  const [isUpdatingObservationDate, setIsUpdatingObservationDate] = useState(false);
  const [isSettingActualSprayDate, setIsSettingActualSprayDate] = useState(false);

  const handleUpdateObservationDate = async ({ data, onSuccess = () => {}, onFail = () => {} }) => {
    const [{ field_id, fieldName }] = selectedField;
    try {
      setIsUpdatingObservationDate(true);
      const response = await GrowerAPI.addObservationDate(
        {
          observation_date: dayjs(data.observation_date).format('YYYY-MM-DD'),
          spike_height: 9,
          field_id: field_id,
        },
        fieldName
      );

      if (response?.response?.status === 200) {
        onSuccess();
      } else {
        onFail();
      }
    } catch (e) {
      onFail();
    } finally {
      setIsUpdatingObservationDate(false);
    }
  };

  const handleSetActualSprayDate = async ({
    data,
    t_window,
    onSuccess = () => {},
    onFail = () => {},
  }) => {
    const [{ field_id, fieldName }] = selectedField;
    try {
      setIsSettingActualSprayDate(true);
      const response = await GrowerAPI.setActualSprayDate(fieldName, {
        field_id: field_id,
        product: data.product,
        dose_rate: parseFloat(data.dose_rate.replaceAll(',', '.')),
        t_window,
        treatment_date: dayjs(data.treatment_date).format('YYYY-MM-DD'),
      });

      if (response?.response?.status === 200) {
        onSuccess();
      } else {
        onFail();
      }
    } catch (e) {
      onFail();
    } finally {
      setIsSettingActualSprayDate(false);
    }
  };

  return {
    selectedField,
    BBCHModal,
    ActualSprayDateModal,
    SearchBar,
    InviteModal,
    isUpdatingObservationDate,
    isSettingActualSprayDate,
    handleUpdateObservationDate,
    handleSetActualSprayDate,
  };
};

export default useAgronomy;
