import { Box, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import React, { useState } from 'react';
import useSortedList from '../../hooks/useSortedList';
import CustomTablePagination from '../../utils/CustomTablePagination';
import {
  NoDataAvailable,
  StyledTableCell,
  StyledTableCell1,
  YYYYMMDD,
  formatDate,
} from '../../utils/commonFunctions';
import { useStateContext } from '../../utils/stateContext';
import CircularProgress from '../CircularProgress';
import TableNoResults from '../Layout/TableNoResults';
import TableSortLabelCustom from '../Layout/TableSortLabelCustom';
import ActualSprayDateModal from './ActualSprayDateModal';
import useFilteredList from '../../hooks/useFilteredList';
import FilterBar from '../Table/FilterBar';
import './agronomy.css';

const stickyColumnWidth = 160;

const Row = ({ rowData }) => {
  const {
    agronomy: {
      selectedField: [, setSelectedField],
      ActualSprayDateModal: [, { on: openActualSprayDateModal }],
    },
  } = useStateContext();

  const handleTreatment = (t_window) => {
    setSelectedField({
      field_id: rowData.field_id,
      fieldName: rowData.field_name,
      treatments: rowData.treatments,
      t_window: t_window,
    });
    openActualSprayDateModal();
  };

  const TreatmentCell = ({ value, t_window, dataFormat = 'number' }) => {
    const getFormattedValue = (value, dataFormat) => {
      const defaultString = '--';
      if (dataFormat === 'number') {
        return isNaN(value) ? defaultString : value.toFixed(2);
      }
      if (dataFormat === 'date' && value) {
        return formatDate(value, { inputFormat: YYYYMMDD });
      }
      return defaultString;
    };

    return (
      <StyledTableCell
        onClick={() => handleTreatment(t_window)}
        sx={{ cursor: 'pointer' }}
        align="center"
      >
        {getFormattedValue(value, dataFormat)}
      </StyledTableCell>
    );
  };

  const {
    created_user_name,
    field_name,
    name,
    field_area,
    treatments,
    rec_spray_date,
    distributor,
    contact_name = '',
  } = rowData;
  const stickyProps = {
    position: 'sticky',
    width: `${stickyColumnWidth}px`,
    backgroundColor: '#FFF',
  };

  return (
    <>
      <TableRow
        sx={{
          '& > *': { borderBottom: 'unset' },
          '&:hover': { backgroundColor: '#BEDCE7' },
        }}
      >
        <StyledTableCell align="center" sx={{ ...stickyProps, left: 0 }}>
          {distributor}
        </StyledTableCell>
        <StyledTableCell align="center" sx={{ ...stickyProps, left: stickyColumnWidth }}>
          {created_user_name}
        </StyledTableCell>
        <StyledTableCell align="center" sx={{ ...stickyProps, left: stickyColumnWidth * 2 }}>
          {contact_name}
        </StyledTableCell>
        <StyledTableCell align="center" sx={{ ...stickyProps, left: stickyColumnWidth * 3 }}>
          {name}
        </StyledTableCell>
        <StyledTableCell align="center" sx={{ ...stickyProps, left: stickyColumnWidth * 4 }}>
          {field_name}
        </StyledTableCell>
        <StyledTableCell align="center" sx={{ ...stickyProps, left: stickyColumnWidth * 5 }}>
          {field_area}
        </StyledTableCell>

        <TreatmentCell t_window={'T1'} value={treatments?.T1?.treatment_date} dataFormat="date" />
        <TreatmentCell
          t_window={'T1'}
          value={rec_spray_date?.T1?.rec_spray_date}
          dataFormat="date"
        />
        <TreatmentCell t_window={'T1'} value={treatments?.T1?.product} />

        <TreatmentCell t_window={'T2'} value={treatments?.T2?.treatment_date} dataFormat="date" />
        <TreatmentCell
          t_window={'T2'}
          value={rec_spray_date?.T2?.rec_spray_date}
          dataFormat="date"
        />
        <TreatmentCell t_window={'T2'} value={treatments?.T2?.product} />

        <TreatmentCell t_window={'T3'} value={treatments?.T3?.treatment_date} />
        <TreatmentCell
          t_window={'T3'}
          value={rec_spray_date?.T3?.rec_spray_date}
          dataFormat="date"
        />
        <TreatmentCell t_window={'T3'} value={treatments?.T3?.product} />
      </TableRow>
    </>
  );
};

const Application = () => {
  const {
    t,
    loading,
    subscriptionList,
    agronomy: {
      selectedField: [selectedField, setSelectedField],
      SearchBar: [isSearchBarOpen],
      ActualSprayDateModal: [isActualSprayDateModalOpen, { off: closeActualSprayDateModal }],
    },
  } = useStateContext();

  const columns = [
    {
      name: 'distributor',
      label: t('GENERIC.DISTRIBUTOR'),
      color: '#0091DF',
      search: true,
      sticky: true,
      width: stickyColumnWidth,
    },
    {
      name: 'created_user_name',
      label: t('HOME.SALES_REP_NAME'),
      color: '#0091DF',
      search: true,
      sticky: true,
      width: stickyColumnWidth,
    },
    {
      name: 'contact_name',
      label: 'Grower Name',
      color: '#0091DF',
      search: true,
      sticky: true,
      width: stickyColumnWidth,
    },
    {
      name: 'name',
      label: t('GENERIC.FARM'),
      color: '#0091DF',
      search: true,
      sticky: true,
      width: stickyColumnWidth,
    },
    {
      name: 'field_name',
      label: t('GENERIC.FIELD'),
      color: '#0091DF',
      search: true,
      sticky: true,
      width: stickyColumnWidth,
    },
    {
      name: 'field_area',
      label: '',
      color: '#0091DF',
      search: true,
      sticky: true,
      width: stickyColumnWidth,
    },
    {
      name: 'treatments.T1.treatment_date',
      label: '',
      color: '#0091DF',
      date: true,
      inputFormat: YYYYMMDD,
    },
    {
      name: 'rec_spray_date.T1.rec_spray_date',
      label: '',
      color: '#0091DF',
      date: true,
    },
    {
      name: 'treatments.T1.product',
      label: '',
      color: '#0091DF',
      search: true,
    },
    {
      name: 'treatments.T2.treatment_date',
      label: '',
      color: '#0091DF',
      date: true,
      inputFormat: YYYYMMDD,
    },
    {
      name: 'rec_spray_date.T2.rec_spray_date',
      label: '',
      color: '#0091DF',
      date: true,
    },
    {
      name: 'treatments.T2.product',
      label: '',
      color: '#0091DF',
      search: true,
    },
    {
      name: 'treatments.T3.treatment_date',
      label: '',
      color: '#0091DF',
      date: true,
      inputFormat: YYYYMMDD,
    },
    {
      name: 'rec_spray_date.T3.rec_spray_date',
      label: '',
      color: '#0091DF',
      date: true,
    },
    {
      name: 'treatments.T3.product',
      label: '',
      color: '#0091DF',
      search: true,
    },
  ];

  const [page, setPage] = useState(0);
  const { sortedData, handleSort, sortDirection } = useSortedList(subscriptionList, columns);
  const { filteredData: tableData, ...filterProps } = useFilteredList(sortedData, columns);
  const stickyProps = { position: 'sticky', width: `${stickyColumnWidth}px`, zIndex: 10 };

  return (
    <Box sx={{ width: '100%' }}>
      <ActualSprayDateModal
        title={t('AGRONOMY.ACT_DATE')}
        open={isActualSprayDateModalOpen}
        handleClose={(reset) => {
          reset({});
          setSelectedField({});
          closeActualSprayDateModal();
        }}
        field={selectedField}
      />
      <TableContainer sx={{ maxHeight: 600 }}>
        <Table stickyHeader aria-label="sticky table" sx={{ overflow: 'auto', width: '150%' }}>
          <TableHead sx={{ position: 'sticky', top: 0, zIndex: 2 }}>
            <TableRow>
              <TableCell
                align="center"
                sx={{
                  ...stickyProps,
                  left: 0,
                  backgroundColor: '#0091DF',
                  padding: 0,
                }}
                rowSpan={2}
              >
                <TableSortLabelCustom
                  sortDirection={sortDirection}
                  onClick={() => handleSort('distributor')}
                  text={t('GENERIC.DISTRIBUTOR')}
                />
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  ...stickyProps,
                  left: stickyColumnWidth,
                  backgroundColor: '#0091DF',
                  padding: 0,
                }}
                rowSpan={2}
              >
                <TableSortLabelCustom
                  sortDirection={sortDirection}
                  onClick={() => handleSort('created_user_name')}
                  text={t('AGRONOMY.SALES_REP_NAME')}
                />
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  ...stickyProps,
                  left: stickyColumnWidth * 2,
                  backgroundColor: '#0091DF',
                  padding: 0,
                }}
                rowSpan={2}
              >
                <TableSortLabelCustom
                  sortDirection={sortDirection}
                  onClick={() => handleSort('contact_name')}
                  text={t('SUBSCRIPTION.GROWER_NAME')}
                />
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  ...stickyProps,
                  left: stickyColumnWidth * 3,
                  backgroundColor: '#0091DF',
                  border: '1px solid #DDDDDD',
                  padding: 0,
                }}
                rowSpan={2}
              >
                <TableSortLabelCustom
                  sortDirection={sortDirection}
                  text={t('GENERIC.FARM')}
                  onClick={() => handleSort('name')}
                />
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  ...stickyProps,
                  left: stickyColumnWidth * 4,
                  backgroundColor: '#0091DF',
                  padding: 0,
                }}
                rowSpan={2}
              >
                <TableSortLabelCustom
                  direction={sortDirection}
                  text={t('GENERIC.FIELD')}
                  onClick={() => handleSort('field_name')}
                />
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  ...stickyProps,
                  left: stickyColumnWidth * 5,
                  backgroundColor: '#0091DF',
                  border: '1px solid #DDDDDD',
                  padding: 0,
                }}
                rowSpan={2}
              >
                <TableSortLabelCustom
                  sortDirection={sortDirection}
                  onClick={() => handleSort('field_area')}
                  text={t('AGRONOMY.HECTARE')}
                />
              </TableCell>
              <StyledTableCell1 colSpan={3} align="center" sx={{ backgroundColor: '#02A8A8' }}>
                T1
              </StyledTableCell1>
              <StyledTableCell1 colSpan={3} align="center" sx={{ backgroundColor: '#00A6E1' }}>
                T2
              </StyledTableCell1>
              <StyledTableCell1 colSpan={3} align="center" sx={{ backgroundColor: '#01849D' }}>
                T3
              </StyledTableCell1>
            </TableRow>
            <TableRow>
              <StyledTableCell1 align="center" sx={{ backgroundColor: '#02A8A8' }}>
                {t('AGRONOMY.ACT_DATE')}
              </StyledTableCell1>
              <StyledTableCell1 align="center" sx={{ backgroundColor: '#02A8A8' }}>
                {t('AGRONOMY.REC_DATE')}
              </StyledTableCell1>
              <StyledTableCell1 align="center" sx={{ backgroundColor: '#02A8A8' }}>
                {t('AGRONOMY.PRODUCT')}
              </StyledTableCell1>

              <StyledTableCell1 align="center" sx={{ backgroundColor: '#00A6E1' }}>
                {t('AGRONOMY.ACT_DATE')}
              </StyledTableCell1>
              <StyledTableCell1 align="center" sx={{ backgroundColor: '#00A6E1' }}>
                {t('AGRONOMY.REC_DATE')}
              </StyledTableCell1>
              <StyledTableCell1 align="center" sx={{ backgroundColor: '#00A6E1' }}>
                {t('AGRONOMY.PRODUCT')}
              </StyledTableCell1>

              <StyledTableCell1 align="center" sx={{ backgroundColor: '#01849D' }}>
                {t('AGRONOMY.ACT_DATE')}
              </StyledTableCell1>
              <StyledTableCell1 align="center" sx={{ backgroundColor: '#01849D' }}>
                {t('AGRONOMY.REC_DATE')}
              </StyledTableCell1>
              <StyledTableCell1 align="center" sx={{ backgroundColor: '#01849D' }}>
                {t('AGRONOMY.PRODUCT')}
              </StyledTableCell1>
            </TableRow>
            <FilterBar
              {...filterProps}
              isOpen={isSearchBarOpen}
              columns={columns}
              selectable={false}
            />
          </TableHead>
          <TableBody>
            {tableData.map((row, i) => (
              <Row rowData={row} key={`${i}${row.id}`} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {tableData.length === 0 && <TableNoResults />}
      <CustomTablePagination dataList={sortedData} page={page} setPage={setPage} />
      {loading ? <CircularProgress /> : null}
      {!loading && sortedData.length === 0 ? (
        <NoDataAvailable text1={t('HOME.NO_DATA_NOTE')} text2={t('HOME.ADD_GROWER_TEXT')} />
      ) : null}
    </Box>
  );
};

export default Application;
