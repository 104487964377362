import React from 'react';
import {
  Box,
  Button,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  Checkbox,
  Divider,
  TableCell,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Header from '../Header';
import { useStateContext } from '../../utils/stateContext';
import { StyledTableCell, sortData } from '../../utils/commonFunctions';

import ChemicalTin from '../../images/chemical.png';

const CheckOfferPage = () => {
  const { t, navigate, rows, initialState, handleCheckboxClick, getTotalArea } = useStateContext();
  const { selectedIndexes } = initialState;
  return (
    <Box sx={{ height: '90%' }}>
      <Header isToggle={true} />

      <Box
        sx={{
          bgcolor: '#fff',
          border: '2px solid #E6E9ED',
          borderRadius: '5px',
          margin: '1vw',
          width: 'auto',
          height: 'auto',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '1vw',
            borderBottom: '2px solid #DDDDDD',
            padding: '0.5vw',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5vw', padding: '0.5vw 0.5vw' }}>
            <ArrowBackIcon
              fontSize="small"
              onClick={() => {
                navigate(`/home/growerdetail`);
              }}
            />
            <p style={{ fontSize: '14px', fontWeight: 550 }}>{t('GROWER.CHECK_OFFER')}</p>
          </Box>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ width: '40%', height: '100%', position: 'relative' }}>
            <Typography variant="body2" sx={{ padding: '1vw 1vw' }}>
              {t('CHECKOFFER.SELECT_FIELDS')}
              <span style={{ color: '#0091DF' }}>{t('CHECKOFFER.PREDIMA_PROGRAM')}</span>
            </Typography>
            <TableContainer sx={{ minWidth: 80, minHeight: 340, maxHeight: 350, overflow: 'auto' }}>
              <Table size="small" stickyHeader>
                <TableHead>
                  <TableRow>
                    <StyledTableCell sx={{ backgroundColor: '#0091DF' }}>
                      {t('GENERIC.FIELD')}
                    </StyledTableCell>
                    <StyledTableCell sx={{ backgroundColor: '#0091DF' }}>{`${t(
                      'GENERIC.SIZE'
                    )} (HA)`}</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortData(rows, 'address').map((row, index) => {
                    return (
                      <TableRow key={row.id}>
                        <TableCell size="small" padding="none" sx={{ border: '1px solid #DDDDDD' }}>
                          {' '}
                          <Checkbox
                            color="primary"
                            checked={selectedIndexes.indexOf(row.id) !== -1}
                            onChange={() => handleCheckboxClick(row)}
                          />
                          {row.field_name}
                        </TableCell>
                        <TableCell sx={{ border: '1px solid #DDDDDD' }}>{row.area}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '10vw',
                backgroundColor: '#E8F1F7',
                padding: '0.7vw 0vw',
              }}
            >
              <Typography
                sx={{
                  backgroundColor: '#E8F1F7',
                  fontWeight: 550,
                  paddingLeft: '2vw',
                  fontSize: '80%',
                }}
              >
                {t('DELIVERY.TOTAL_FIELDS_AREA')}
              </Typography>
              <Typography sx={{ backgroundColor: '#E8F1F7', fontWeight: 550, fontSize: '80%' }}>
                {getTotalArea()}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{ width: '60%', height: '100%', bgcolor: '#F0F9FD', padding: '1vw 2vw 3vw 1vw' }}
          >
            <Typography
              sx={{ textAlign: 'center', fontWeight: 550, color: '#0B617F', fontSize: '1em' }}
            >
              {t('GENERIC.CONGRACTULATIONS').toUpperCase()}
            </Typography>
            <Typography sx={{ textAlign: 'center', fontSize: '0.8em' }}>
              {t('CHECKOFFER.TEXT')}
            </Typography>
            <Box sx={{ display: 'flex', gap: '2vw', margin: '2vw 1vw 3vw 2vw', height: '100%' }}>
              <Box
                sx={{
                  width: '40%',
                  height: '50%',
                  border: '1px solid #ACD0E4',
                  borderRadius: '10px',
                  display: 'flex',
                  gap: '1.5vw',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  padding: '1vw 1vw 0.7vw 0vw',
                }}
              >
                <Typography
                  sx={{ fontSize: '0.7em', borderLeft: '2px solid #FF3162', paddingLeft: '0.7vw' }}
                >
                  {t('CHECKOFFER.SUGGESTION1')}
                </Typography>
                <Divider />
                <Typography
                  sx={{ fontSize: '0.7em', borderLeft: '2px solid #FF3162', paddingLeft: '0.7vw' }}
                >
                  {t('CHECKOFFER.SUGGESTION2')}
                </Typography>
                <Divider />
                <Typography
                  sx={{ fontSize: '0.7em', borderLeft: '2px solid #FF3162', paddingLeft: '0.7vw' }}
                >
                  {t('CHECKOFFER.SUGGESTION3')}
                </Typography>
              </Box>
              <Box sx={{ width: '50%', height: '100%' }}>
                <span
                  style={{
                    borderBottom: '2px solid #FF3162',
                    fontWeight: 550,
                    fontSize: '14px',
                    margin: '1vw',
                  }}
                >
                  {t('CHECKOFFER.HEADING')}
                </span>
                <Box sx={{ display: 'flex', alignItems: 'center', mt: 3, mb: 8, gap: '2vw' }}>
                  <img src={ChemicalTin} alt="fertilizer" style={{ height: '36%', width: '34%' }} />
                  <Box>
                    <Typography sx={{ fontWeight: 550, fontSize: '0.7em', padding: '0.3vw 0vw' }}>
                      {t('CHECKOFFER.DETAIL1')}
                    </Typography>
                    <Typography sx={{ fontWeight: 550, fontSize: '0.7em', padding: '0.3vw 0vw' }}>
                      {t('CHECKOFFER.DETAIL2')}
                      <span> =0.8l/ha</span>
                    </Typography>
                    <Typography sx={{ fontWeight: 550, fontSize: '0.7em', padding: '0.3vw 0vw' }}>
                      {t('CHECKOFFER.DETAIL3')}
                      <span> 5L {t('PRODUCT.SIZE')}</span>
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
              <Box sx={{ width: '52%', backgroundColor: '#0091DF', borderRadius: '5px' }}>
                <Typography
                  sx={{
                    color: 'white',
                    padding: '0.2vw 0.3vw',
                    textAlign: 'left',
                    fontSize: '0.9vw',
                  }}
                >
                  {t('CHECKOFFER.OFFER_PRICE').toUpperCase()}
                  <span style={{ fontSize: '16px', fontWeight: 550 }}>
                    <span>€</span> {(getTotalArea() * 59).toFixed(2)}
                  </span>
                  <br />
                  <span style={{ fontSize: '0.9vw', float: 'right' }}>{t('CHECKOFFER.PRICE')}</span>
                </Typography>
              </Box>
              <Button
                disabled={selectedIndexes.length < 1 ? true : false}
                onClick={() => navigate(`/home/fielddetails`)}
                variant="contained"
                sx={{ padding: '0.7vw 0vw', width: '48%', textAlign: 'center' }}
              >
                {t('CHECKOFFER.ACCEPT_PROCEED')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default CheckOfferPage;
