import React, { useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Icon,
  Divider,
  Menu,
  FormControl,
  OutlinedInput,
  InputAdornment,
} from '@mui/material';
import Header from '../Header';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useStateContext } from '../../utils/stateContext';
import dayjs from 'dayjs';
import SnackbarCustom from '../Snackbar';
import GrowerAPI from '../../api/grower';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const Notification = ({ children, text1, text2, date }) => {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        padding: '0.1vw 0.5vw',
        gap: '0.5vw',
      }}
    >
      {children}
      <Typography color="primary" sx={{ fontSize: '1vw', width: '100%' }}>
        <b>{text1}</b>
        <span style={{ float: 'right' }}>{date}</span> <br />
        {text2}
      </Typography>
    </Box>
  );
};

const Notifications = () => {
  const { t, navigate, setNotifications, notifications } = useStateContext();
  const [snackOpen, setOpen] = useState(false);
  const [severity, setSeverity] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMarkAsRead = () => {
    const ids = notifications?.map((item) => item.id);
    GrowerAPI.markAsRead({ id: ids }).then((response) => {
      if (response.status === 200) {
        setOpen(true);
        setAlertMessage(t('All notifications are marked as read'));
        setSeverity('success');
        setNotifications([{ created_date: '', body: '', is_read: false }]);
      }
    });
  };
  return (
    <Box>
      <Header isToggle={true} />
      <SnackbarCustom
        open={snackOpen}
        setOpen={setOpen}
        alertMessage={alertMessage}
        severity={severity}
      />
      <Box sx={{ backgroundColor: 'white', margin: '0.7vw', height: '90vh', width: '98%' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '1vw',
            borderBottom: '2px solid #DDDDDD',
            padding: '0.5vw',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5vw' }}>
            <ArrowBackIcon fontSize="small" onClick={() => navigate(`/home`)} />
            <p style={{ fontSize: '14px', fontWeight: 550 }}>{t('NOTIFICATIONS')}</p>
          </Box>
          <Box sx={{ display: 'flex', gap: '1vw' }}>
            <Button
              onClick={handleMarkAsRead}
              sx={{
                color: '#00617F',
                border: '1.5px solid #00617F',
                fontSize: '12px',
                fontWeight: 530,
                padding: '0.1vw 0.2vw',
              }}
            >
              {t('NOTIFICATIONS.MARK_READ')}
            </Button>
            <Button
              sx={{ borderRadius: '5px', border: '1px solid #CCCCCC', padding: 0, minWidth: 0 }}
            >
              <SearchOutlinedIcon
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
              />
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  padding: '1em',
                  alignItems: 'center',
                  gap: '1vw',
                  flexDirection: 'column',
                }}
              >
                <FormControl variant="outlined" size="small" fullWidth>
                  <OutlinedInput
                    id="outlined-adornment-telephone"
                    type="text"
                    name="date"
                    placeholder="Search"
                    endAdornment={
                      <InputAdornment position="end">
                        <Icon color="primary" edge="end">
                          <CalendarMonthIcon />
                        </Icon>
                      </InputAdornment>
                    }
                  />
                </FormControl>
                <Button type="submit" sx={{ width: 250, height: 30 }} variant="contained">
                  {t('NOTIFICATIONS.SEARCH')}
                </Button>
              </Box>
            </Menu>
          </Box>
        </Box>
        <Box sx={{ overflow: 'auto', height: '90%' }}>
          <Box>
            {notifications
              .sort(
                (a, b) =>
                  dayjs(b?.created_date, 'YYYY-MM-DD') - dayjs(a?.created_date, 'YYYY-MM-DD')
              )
              .map((notification) => (
                <>
                  <Notification
                    date={dayjs(notification.created_date).format('DD-MM-YYYY')}
                    children={
                      <CircleNotificationsIcon
                        sx={{ color: notification.is_read ? '#B7CBD3' : '#00BCFF' }}
                        fontSize="large"
                      />
                    }
                    text1={notification.title}
                    text2={notification.body}
                  />
                  <Divider sx={{ mt: 0.5 }} />
                </>
              ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Notifications;
