import dayjs from 'dayjs';
import { useMemo, useState, useEffect } from 'react';
import { dateFormat, getCellValue } from '../utils/commonFunctions';

type SortDirection = 'asc' | 'desc';

interface DataItem {
  [key: string]: any;
}

const useSortedList = <T extends DataItem>(initialData: T[], columns) => {
  const [data, setData] = useState<T[]>([]);
  const [sortDirection, setSortDirection] = useState<SortDirection>('asc');
  const [sortBy, setSortBy] = useState<keyof T | null>(null);

  const handleSort = (property) => {
    const isAsc = sortBy === property && sortDirection === 'asc';
    const newDirection = isAsc ? 'desc' : 'asc';

    setSortDirection(newDirection);
    setSortBy(property);
  };

  const sortingColumn = useMemo(() => {
    if (sortBy) {
      return columns?.find((col) => col.name === sortBy);
    }
    return null;
  }, [sortBy, columns]);

  const sortedData = useMemo(() => {
    if (!sortBy) return data;

    return [...data].sort((a, b) => {
      let aValue = getCellValue(a, sortingColumn);
      let bValue = getCellValue(b, sortingColumn);

      let aDate = dayjs(aValue, dateFormat);
      let bDate = dayjs(bValue, dateFormat);

      if (sortingColumn?.date) {
        if (aDate.isValid() && bDate.isValid()) {
          if (aDate.isBefore(bDate)) return sortDirection === 'asc' ? -1 : 1;
          if (aDate.isAfter(bDate)) return sortDirection === 'asc' ? 1 : -1;
          return 0;
        }
      }

      if (sortingColumn?.boolean) {
        if (aValue === bValue) return 0;
        return aValue ? (sortDirection === 'asc' ? -1 : 1) : sortDirection === 'asc' ? 1 : -1;
      }

      if (aValue < bValue) return sortDirection === 'asc' ? -1 : 1;
      if (aValue > bValue) return sortDirection === 'asc' ? 1 : -1;
      return 0;
    });
  }, [data, sortDirection, sortBy, sortingColumn]);

  useEffect(() => {
    if (initialData) {
      setData(initialData);
    }
  }, [initialData]);

  return { sortedData, handleSort, sortBy, sortDirection };
};

export default useSortedList;
