import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  Icon,
  InputLabel,
  InputAdornment,
  OutlinedInput,
  Typography,
} from '@mui/material';

import BrandLogo from '../../images/background2x.png';
import BrandIcon from '../../images/brandlogo2x.png';
import MailIcon from '../../images/MailIcon2x.png';
import Visibility from '../../images/Visibility2x.png';

import LoginAPI from '../../api/login';
import UserAPI from '../../api/user';

import Circular from '../CircularProgress';
import TooltipCustom from '../Tooltip';
import SnackbarCustom from '../Snackbar';

import { useStateContext } from '../../utils/stateContext';
import { getFirebaseToken } from '../../utils/firebase';
import { getToken1 } from '../../firebase';

import { LoginDataProps } from './types';
import './login.css';

const defaultLocale = 'fr';

const Login = () => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [rememberMe, setRememberMe] = useState(true);
  const [password, setPassword] = useState('');

  const { setUser, open, setOpen, severity, setSeverity, alertMessage, setAlertMessage } =
    useStateContext();

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newPassword = event.target.value;

    setPassword(newPassword);
  };

  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    email: Yup.string().required(t('REGISTER.ERR_EMAIL')).email(t('REGISTER.ERR_EMAIL_INVALID')),
    password: Yup.string().required(t('REGISTER.ERR_PASS')),
  });

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setRememberMe(event.target.checked);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema) });

  const handleMouseDownPassword = (event: React.MouseEvent): void => {
    event.preventDefault();
  };

  getToken1();

  const submit = async ({ email, password }: LoginDataProps) => {
    localStorage.clear();

    const response = await LoginAPI.loginUser({
      loginID: email,
      password,
    });

    if (response.statusCode === 403) {
      setLoading(true);
      setOpen(true);
      setSeverity('error');
      setAlertMessage(t('ALERT.MSG13'));
    }

    // handling any other error, some other message?
    if (response.statusCode !== 200) {
      setLoading(true);
      setOpen(true);
      setSeverity('error');
      setAlertMessage(t('ALERT.MSG13')); // probably different message
    }

    setLoading(false);

    const tokenResponse = await LoginAPI.getJWT(response.UID);

    if (tokenResponse.statusCode !== 200) {
      setOpen(true);
      setSeverity('error');
      setAlertMessage(t('ALERT.MSG11'));
      return;
    }

    const id_token = tokenResponse.id_token;
    localStorage.setItem('token', JSON.stringify(id_token));

    const firebaseToken = getFirebaseToken();

    // this will be moved to the registration component as a part of 7661 ticket
    // the relevant data is obtained from the backend by the getUserByEmail call below
    await UserAPI.addUser({
      gigya_uuid: response.UID,
      email: response.profile.email,
      name: response.profile.firstName + ' ' + response.profile.lastName,
      token: firebaseToken,
    });

    const predimaUserResponse = await UserAPI.getUserByEmail({ email });

    if (predimaUserResponse.status !== 200) {
      setOpen(true);
      setSeverity('error');
      setAlertMessage(t('ALERT.MSG11'));
      return;
    }
    localStorage.setItem('userDetails', JSON.stringify(predimaUserResponse.data));

    const accountResponse = await LoginAPI.accountSearch(email);

    // some error handling here maybe?
    const accountLocale = accountResponse.results[0]?.profile?.locale || defaultLocale;
    localStorage.setItem('locale', JSON.stringify(accountLocale));

    setUser(response);

    if (rememberMe) {
      await i18n.changeLanguage(accountLocale);
      localStorage.setItem('rememberMe', JSON.stringify(true));
      localStorage.setItem('user', JSON.stringify(response));
    }

    navigate('/home');
  };

  // this is pulling out stringified "true", worth checking if there's no corner case where we save "false"
  // to localStorage and then treat "false" as a truthful string
  const remember = localStorage.getItem('rememberMe');

  // unusual way to treat the "remember me" option
  // right now it works this way, that if you did not check "remember me", you can get back to the login page
  // even if you're logged in.
  useEffect(() => {
    if (remember) {
      navigate('/home');
    }
  }, [navigate, remember]);

  if (!loading) return <Circular />;

  return (
    <div className="loginmain">
      <div className="logincontent">
        <img id="background" src={BrandLogo} alt="background" />
        <img id="brand" src={BrandIcon} alt="Tool Logo" />
        <Box
          component="form"
          onSubmit={handleSubmit(submit)}
          noValidate
          autoComplete="on"
          sx={{
            mt: 0,
            marginTop: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography sx={{ borderBottom: '2px solid #FF3162', fontSize: '16px', fontWeight: 550 }}>
            {t('LOGIN.TEXT').toUpperCase()}
          </Typography>
          <SnackbarCustom
            open={open}
            setOpen={setOpen}
            alertMessage={alertMessage}
            severity={severity}
          />
          <Typography sx={{ mt: 1, fontSize: '12px' }}>
            {t('LOGIN.NOTE') + ' ' + t('GENERIC.MANDATORY')}
          </Typography>

          <Container sx={{ mt: 0.5, width: '50%' }}>
            <FormControl fullWidth variant="outlined" sx={{ mt: 1 }} size="small">
              <InputLabel htmlFor="outlined-adornment-email">{t('LOGIN.EMAIL')}*</InputLabel>
              <OutlinedInput
                id="outlined-adornment-email"
                type="email"
                endAdornment={
                  <InputAdornment position="end">
                    <Icon color="primary">
                      <img src={MailIcon} alt="email" style={{ height: '70%', width: '100%' }} />
                    </Icon>
                  </InputAdornment>
                }
                label={t('LOGIN.EMAIL')}
                autoComplete="email"
                {...register('email')}
                error={errors.email ? true : false}
              />{' '}
              <span style={{ color: 'red', fontSize: '12px' }}>{errors.email?.message}</span>
            </FormControl>
            <TooltipCustom
              children={
                <FormControl fullWidth variant="outlined" sx={{ mt: 1 }} size="small">
                  <InputLabel htmlFor="outlined-adornment-password">
                    {t('LOGIN.PASSWORD')}*
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type="text"
                    endAdornment={
                      <InputAdornment position="end">
                        <Icon
                          color="primary"
                          aria-label="toggle password visibility"
                          onMouseDown={handleMouseDownPassword}
                        >
                          <img
                            src={Visibility}
                            alt="password"
                            style={{ height: '70%', width: '100%' }}
                          />
                        </Icon>
                      </InputAdornment>
                    }
                    label={t('LOGIN.PASSWORD')}
                    autoComplete="password"
                    {...register('password')}
                    onChange={handlePasswordChange}
                    error={errors.password ? true : false}
                  />{' '}
                  <span style={{ color: 'red', fontSize: '12px' }}>{errors.password?.message}</span>
                </FormControl>
              }
              password={password}
            />
            <Box
              sx={{
                mt: 1,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <FormControlLabel
                control={<Checkbox checked={rememberMe} onChange={handleCheckboxChange} />}
                label={t('LOGIN.REMEMBER_ME')}
              />

              <Link to="/send-reset-mail">{t('LOGIN.RESET_PASSWORD')}?</Link>
            </Box>

            <Button
              type="submit"
              fullWidth
              color="primary"
              variant="contained"
              sx={{ mt: 1, mb: 2, height: '40px' }}
            >
              {t('LOGIN.TEXT')}
            </Button>
            <Grid container justifyContent="center" sx={{ mb: 3 }}>
              <Grid item>
                {t('GENERIC.DONT')}
                <Link to="/register">{t('REGISTRATION.REGISTER_NOW')}</Link>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </div>

      <h5 style={{ float: 'right' }}>V2.1</h5>
    </div>
  );
};
export default Login;
