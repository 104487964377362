import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { Box, Button, Tab, Tabs, Tooltip, Typography, CircularProgress } from '@mui/material';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import GrowerAPI from '../../api/grower';
import { useStateContext } from '../../utils/stateContext';
import './agronomy.css';
import Application from './Application';
import BBCHModal from './ActualSprayDateModal';
import BBCHUpdate from './BBCHUpdate';
import InviteModal from './InviteModal';
import Summary from './Summary';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ padding: '1vw 0vw' }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}
const colors = {
  30: '#FF0000',
  20: '#FFC400',
  10: '#66B512',
  50: '#0091DF',
  0: '#BABABA',
};

const Dot = ({ color, size }) => (
  <Box
    component="span"
    sx={{
      height: size,
      width: size,
      backgroundColor: colors[`${color}`],
      borderRadius: '50%',
      display: 'inline-block',
    }}
  ></Box>
);

const AgronomyTable = ({ subscriptionList, loading }) => {
  const {
    t,
    growers,
    setGrowers,
    setSubscriptionList,
    getData,
    snackbar: { error },
    agronomy: {
      isUpdatingObservationDate,
      isSettingActualSprayDate,
      InviteModal: [IsInviteModalOpen, { on: openInviteModal, off: closeInviteModal }],
      SearchBar: [, { toggle: toggleSearchBar }],
    },
  } = useStateContext();

  const [legend, setLegend] = useState(true);
  const [value, setValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    newValue === 1 ? setLegend(false) : setLegend(true);
    setValue(newValue);
  };

  let date = new Date();

  const handleInviteGrowers = () => {
    if (growers.length < 1) {
      error('Please select growers');
      return;
    }
    GrowerAPI.inviteGrower({ to: growers }).then((response) => {
      if (response.status === 200) {
        openInviteModal();
      }
    });
  };

  return (
    <Box sx={{ backgroundColor: 'white', margin: '0.7vw' }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '0.5vw 1vw',
        }}
      >
        <Box sx={{ display: 'flex', gap: '1vw' }}>
          <Box>
            <Typography variant="body2" sx={{ fontWeight: 550, fontSize: '14px' }}>
              {t('AGRONOMY.DATA')}
            </Typography>
            <Typography sx={{ fontSize: '12px', opacity: '0.5' }}>
              {t('AGRONOMY.STATUS')} {dayjs(date).format('DD-MM-YYYY')}
            </Typography>
          </Box>
          {legend && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5vw' }}>
              <Dot color={30} size="0.5em" />
              <Typography variant="body2" fontSize="0.7em">
                High Risk
              </Typography>
              <Dot color={20} size="0.5em" />
              <Typography variant="body2" fontSize="0.7em">
                Medium Risk
              </Typography>
              <Dot color={10} size="0.5em" />
              <Typography variant="body2" fontSize="0.7em">
                Low Risk
              </Typography>
              <Dot color={50} size="0.5em" />
              <Typography variant="body2" fontSize="0.7em">
                Protected
              </Typography>
              <Dot color={0} size="0.5em" />
              <Typography variant="body2" fontSize="0.7em">
                No data
              </Typography>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '0.7vw',
          }}
        >
          {legend && (
            <Button
              sx={{
                borderRadius: '5px',
                border: '1px solid #00617F',
                fontSize: '10px',
                padding: '3px 6px',
                fontWeight: 550,
              }}
              onClick={() => handleInviteGrowers()}
            >
              {t('AGRONOMY.INVITE')}
            </Button>
          )}
          <Button
            sx={{ borderRadius: '5px', border: '1px solid #CCCCCC', padding: 0, minWidth: 0 }}
          >
            <Tooltip title="Search Data">
              <SearchOutlinedIcon onClick={toggleSearchBar} />
            </Tooltip>
          </Button>
        </Box>
      </Box>
      <InviteModal
        open={IsInviteModalOpen}
        handleClose={() => {
          closeInviteModal();
          setSubscriptionList([]);
          getData();
          setGrowers([]);
        }}
        growers={growers}
      />
      <BBCHUpdate />
      <BBCHModal />
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleTabChange}
            variant="fullWidth"
            sx={{ minHeight: 40, height: 40 }}
          >
            <Tab
              label={
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  position={'relative'}
                  width={'100%'}
                  justifyContent={'center'}
                >
                  {t('AGRONOMY.SUMMARY')}{' '}
                  {isUpdatingObservationDate && (
                    <CircularProgress size="20px" sx={{ position: 'absolute', right: '0' }} />
                  )}
                </Box>
              }
              {...a11yProps(0)}
              color="primary"
              sx={{
                '&.MuiTab-root': {
                  textTransform: 'none',
                  color: '#0B617F',
                },
              }}
            />
            <Tab
              label={
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  position={'relative'}
                  width={'100%'}
                  justifyContent={'center'}
                >
                  {t('AGRONOMY.APPLICATION')}{' '}
                  {isSettingActualSprayDate && (
                    <CircularProgress size="20px" sx={{ position: 'absolute', right: '0' }} />
                  )}
                </Box>
              }
              {...a11yProps(0)}
              color="primary"
              sx={{
                '&.MuiTab-root': {
                  textTransform: 'none',
                  color: '#0B617F',
                },
              }}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Summary subscriptionList={subscriptionList} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Application subscriptionList={subscriptionList} />
        </TabPanel>
      </Box>
    </Box>
  );
};

export default AgronomyTable;
