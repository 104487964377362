import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import '@mui/material/styles';
import './i18n/config';
import ErrorBoundry from '../src/utils/ErrorBoundry';

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#0B617F',
    },
    secondary: {
      main: '#0091DF',
    },
    other: '#66B512',
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider theme={defaultTheme}>
    <ErrorBoundry>
      <App />
    </ErrorBoundry>
  </ThemeProvider>
);
