import React from 'react';
import { Snackbar, Alert } from '@mui/material';

export default function SnackbarCustom({ open, setOpen, severity, alertMessage, ...props }) {
  return (
    <Snackbar
      anchorOrigin={{
        horizontal: props.horizontal ? props.horizontal : 'center',
        vertical: props.vertical ? props.vertical : 'bottom',
      }}
      sx={{ width: '100%' }}
      open={open}
      autoHideDuration={2000}
      onClose={() => setOpen(!open)}
    >
      <Alert
        {...props}
        variant="filled"
        onClose={() => setOpen(!open)}
        severity={severity || 'info'}
      >
        {alertMessage}
      </Alert>
    </Snackbar>
  );
}
