import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Box,
  Typography,
  Divider,
  TextField,
  MenuItem,
  Grid,
  Button,
  Switch,
  FormControl,
  InputLabel,
  Select,
  FormControlLabel,
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FieldImage from '../../images/fielddetails.png';
import Header from '../Header';
import { useStateContext } from '../../utils/stateContext';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { BpCheckbox } from '../../utils/commonFunctions';
import dayjs from 'dayjs';
import Autocomplete from '@mui/material/Autocomplete';
import SnackbarCustom from '../Snackbar';
import GrowerAPI from '../../api/grower';
import ArvalisAPI from '../../api/arvalis';

export const FieldBox = ({
  field,
  index,
  handleFieldsUpdate,
  handleDateUpdate,
  soilTypes = [],
  varieties = [],
}) => {
  const { t, editable, handleOptionChange } = useStateContext();
  const {
    formState: { errors },
  } = useForm();
  const local = localStorage.getItem('locale') ? JSON.parse(localStorage.getItem('locale')) : null;
  return (
    <Box
      sx={{
        border: '2px solid #0091DF4A',
        borderRadius: '10px',
        padding: '1vw 1.5vw',
        mt: 2,
      }}
    >
      <Box sx={{ display: 'flex', gap: '1.5vw' }}>
        <Typography sx={{ fontSize: '12px' }}>
          {t('GENERIC.FIELD')} <br />
          <b>{field.field_name}</b>
        </Typography>
        <Divider orientation="vertical" variant="middle" flexItem />
        <Typography sx={{ fontSize: '12px' }}>
          {`${t('GENERIC.SIZE')} (HA)`} <br />
          {field.area}
        </Typography>
        <Divider orientation="vertical" variant="middle" flexItem />
        <Typography sx={{ fontSize: '12px' }}>
          {t('GROWER.CROP_TYPE')} <br />
          {'wheat'}
        </Typography>
      </Box>
      <Divider component="hr" sx={{ border: '1px solid #B5CDD7', mt: 1 }} />
      <Box component="form" noValidate sx={{ mt: 2 }}>
        <Grid container rowSpacing={2} columnSpacing={2}>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              value={field?.variety}
              onChange={(event, newValue) => handleOptionChange(index, newValue, 'variety')}
              options={varieties.map((x) => x.variety_name)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('FIELD.VARIETY') + '*'}
                  variant="outlined"
                  size="small"
                  InputProps={{
                    ...params.InputProps,
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={
                <BpCheckbox
                  name="mixed_variety"
                  checked={field.mixed_variety ? field.mixed_variety : false}
                  onChange={(event, newValue) =>
                    handleOptionChange(index, newValue, 'mixed_variety')
                  }
                />
              }
              label={<Typography sx={{ fontSize: '12px' }}>{t('FIELD.MIXED_VARIETY')}</Typography>}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              value={field?.region}
              onChange={(event, newValue) => handleOptionChange(index, newValue, 'region')}
              options={[...new Set(soilTypes.map((x) => x.region))]}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('FIELD.REGION') + '*'}
                  variant="outlined"
                  size="small"
                  InputProps={{
                    ...params.InputProps,
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              value={{
                soil_type_id: field?.soil_code ? field?.soil_code : '',
                nom_arvalis: field?.soil_type ? field?.soil_type : '',
              }}
              onChange={(event, newValue) => handleOptionChange(index, newValue, 'soil')}
              options={[...new Set(soilTypes.filter((x) => x.region === field?.region))]}
              getOptionLabel={(option) => option.nom_arvalis}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('FIELD.SOIL_TYPE') + '*'}
                  variant="outlined"
                  size="small"
                  InputProps={{
                    ...params.InputProps,
                  }}
                  helperText={t('FIELD.DETAILS.HELPERTEXT')}
                />
              )}
            />
          </Grid>
          {field?.soil_type && (
            <Grid item xs={12}>
              <Box sx={{ backgroundColor: '#F2F9FF', padding: '0vw 1vw' }}>
                <Typography sx={{ fontWeight: 530, fontSize: '80%' }}>
                  {t('FIELD.DETAILS.SOIL')}
                </Typography>
                <Typography sx={{ fontSize: '80%' }}>
                  {t('FIELD.DETAILS.TEXTURE')} -{' '}
                  {
                    soilTypes.find(
                      (x) => x.region === field?.region && x.nom_arvalis === field?.soil_type
                    )?.classif_texture
                  }
                </Typography>
                <Typography sx={{ fontSize: '80%' }}>
                  {t('FIELD.DETAILS.CALCAIRE')} -{' '}
                  {
                    soilTypes.find(
                      (x) => x.region === field?.region && x.nom_arvalis === field?.soil_type
                    )?.classif_calcaire
                  }
                </Typography>
                <Typography sx={{ fontSize: '80%' }}>
                  {t('FIELD.DETAILS.PIERROSITE')} -{' '}
                  {
                    soilTypes.find(
                      (x) => x.region === field?.region && x.nom_arvalis === field?.soil_type
                    )?.classif_pierrosite
                  }
                </Typography>
              </Box>
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label={t('FIELD.SOWING_DATE') + '*'}
                value={
                  field.sowing_date === null
                    ? dayjs(null)
                    : editable
                      ? dayjs(field.sowing_date, 'DD-MM-YYYY')
                      : dayjs(field.sowing_date, 'DD-MM-YYYY')
                }
                format="DD-MM-YYYY"
                minDate={dayjs('01-01-2023')}
                onChange={(newValue) => {
                  handleDateUpdate(newValue, index);
                }}
                renderInput={(params) => <TextField {...params} />}
                slotProps={{
                  textField: {
                    size: 'small',
                    fullWidth: 'true',
                    readOnly: true,
                    error: errors.sowing_date ? true : false,
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth size="small">
              <InputLabel>{t('FIELD.PREVIOUS_CROP') + '*'}</InputLabel>
              <Select
                value={field.previous_crop}
                label={t('FIELD.PREVIOUS_CROP') + '*'}
                name="previous_crop"
                onChange={handleFieldsUpdate(index)}
              >
                <MenuItem value="Wheat">{local !== 'fr' ? 'Wheat' : 'Blé'}</MenuItem>
                <MenuItem value="Barley">{local !== 'fr' ? 'Barley' : 'Orge'}</MenuItem>
                <MenuItem value="Grain Corn">
                  {local !== 'fr' ? 'Grain Corn' : 'Mais Grain'}
                </MenuItem>
                <MenuItem value="Corn silage">
                  {local !== 'fr' ? 'Corn silage' : 'Mais Ensilage'}
                </MenuItem>
                <MenuItem value="Oilseed rape">
                  {local !== 'fr' ? 'Oilseed rape' : 'Colza'}
                </MenuItem>
                <MenuItem value="Peas">{local !== 'fr' ? 'Peas' : 'Pois'}</MenuItem>
                <MenuItem value="Sunflower">{local !== 'fr' ? 'Sunflower' : 'Tournesol'}</MenuItem>
                <MenuItem value="Sorghum">{local !== 'fr' ? 'Sorghum' : 'Sorgho'}</MenuItem>
                <MenuItem value="Others">{local !== 'fr' ? 'Others' : 'Autres'}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={8} sm={5}>
            {/* <CustomSwitch isChecked={field.tillage ? false : true} index={index} handleFieldsUpdate={handleFieldsUpdate} /> */}
            <FormControlLabel
              control={
                <Switch
                  checked={field.tillage ? field.tillage : false}
                  onChange={handleFieldsUpdate(index)}
                  name="tillage"
                  color="primary"
                />
              }
              label={<Typography variant="body2">{t('FIELD.TILLAGE')}</Typography>}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

const FieldDetails = () => {
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [soilTypes, setSoilTypes] = useState([]);
  const [varieties, setVarieties] = useState([]);
  const {
    t,
    navigate,
    initialState,
    setInitialState,
    setSelectedRows,
    handleFieldsUpdate,
    handleDateUpdate,
    editable,
    setEditable,
    validateMandatoryProperties,
    editFromReview,
    setEditFromReview,
  } = useStateContext();
  const { sharedState, selectedRows } = initialState;
  useEffect(() => {
    if (editable) {
      setSelectedRows(sharedState.orders[0]?.order_fields);
      setInitialState({ ...initialState, selectedRows: sharedState.orders[0]?.order_fields });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editable]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await ArvalisAPI.getVarietiesSoilTypes();
      await setSoilTypes(data?.soil_types);
      await setVarieties(data?.varieties);
    };
    fetchData();
  }, []);

  const validationSchema = Yup.object().shape({
    variety: Yup.string().required(t('FIELD.DETAILS.VARIETY.REQUIRED')),
    mixed_variety: Yup.boolean().oneOf([true]),
    soil_type: Yup.string().required(t('FIELD.DETAILS.SOIL_TYPE.REQUIRED')),
    sowing_date: Yup.date().required(t('FIELD.DETAILS.SOWING_DATE.REQUIRED')),
    previous_crop: Yup.string().required(t('FIELD.DETAILS.PREVIOUS_CROP.REQUIRED')),
    tillage: Yup.boolean().oneOf([true]),
  });

  const { handleSubmit } = useForm({ resolver: yupResolver(validationSchema) });

  const submit = (data) => {};

  return (
    <>
      <Header isToggle={true} />
      <SnackbarCustom
        open={open}
        setOpen={setOpen}
        alertMessage={alertMessage}
        severity={severity}
      />
      <Box
        sx={{
          bgcolor: '#fff',
          border: '2px solid #E6E9ED',
          borderRadius: '5px',
          margin: '1vw',
          width: 'auto',
          height: 'auto',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: '0.5vw',
            borderBottom: '3px solid #DDDDDD',
            padding: '0.5vw 0.5vw',
          }}
        >
          <ArrowBackIcon
            fontSize="small"
            onClick={() => {
              if (editable) {
                navigate(-1);
                setEditable(false);
              } else if (editFromReview) {
                navigate(`/home/reviewdetails`);
                setEditFromReview(false);
              } else {
                navigate(`/home/checkoffer`);
              }
            }}
          />
          <p style={{ fontSize: '14px', fontWeight: 550 }}>{t('FIELD.DETAILS')}</p>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ width: '40%', height: '100%', mt: 2 }}>
            <img src={FieldImage} alt="AddForm Icon" style={{ width: '100%', height: '100%' }} />
          </Box>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit(submit)}
            sx={{ height: '100%', width: '60%', padding: '1vw 2vw 3vw 3vw' }}
          >
            <Typography variant="body2" sx={{ color: '#0B617F' }}>
              {t('GENERIC.MANDATORY')}
            </Typography>
            {selectedRows.map((field, index) => {
              return (
                <FieldBox
                  key={index}
                  field={field}
                  index={index}
                  handleFieldsUpdate={handleFieldsUpdate}
                  handleDateUpdate={handleDateUpdate}
                  soilTypes={soilTypes}
                  varieties={varieties}
                />
              );
            })}

            <Button
              onClick={() => {
                let mandatoryProperties = [
                  'variety',
                  'region',
                  'sowing_date',
                  'previous_crop',
                  'soil_type',
                ];
                let isValid = validateMandatoryProperties(selectedRows, mandatoryProperties);
                if (editable) {
                  if (!isValid) {
                    setOpen(true);
                    setAlertMessage(t('ALERT.MSG8'));
                    setSeverity('error');
                    return;
                  }
                  return GrowerAPI.updateFieldviewData(selectedRows).then((response) => {
                    if (response.status === 200) {
                      setOpen(true);
                      setAlertMessage(t('ALERT.MSG9'));
                      setSeverity('success');
                      setTimeout(() => {
                        setEditable(false);
                        navigate(`/home`);
                      }, 3000);
                    } else {
                      navigate(`/home/growerOverview`);
                    }
                  });
                } else {
                  if (isValid) {
                    navigate(`/home/deliverydetails`);
                  } else {
                    setOpen(true);
                    setAlertMessage(t('ALERT.MSG8'));
                    setSeverity('error');
                  }
                }
              }}
              sx={{
                display: 'block',
                height: 40,
                width: 300,
                margin: '2vw 16vw',
              }}
              variant="contained"
            >
              {editable ? t('GENERIC.UPDATE') : t('DELIVERY.PROCEED')}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default FieldDetails;
