import React, { useState } from 'react';
import {
  Box,
  Button,
  Typography,
  Paper,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Grid,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Header from '../Header';
import GrowerAPI from '../../api/grower';
import { useStateContext } from '../../utils/stateContext';
import { FlexBox1, FlexBox, EditIcon } from '../../utils/commonFunctions';
import FarmHouse from '../../images/house.png';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import HouseOutlinedIcon from '@mui/icons-material/HouseOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import SnackbarCustom from '../Snackbar';
import LoginIcon from '@mui/icons-material/Login';

const GrowerDetailOverview = () => {
  const { t, navigate, initialState, setEditable } = useStateContext();
  const { sharedState } = initialState;
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [status, setStatus] = useState(sharedState?.contract ? sharedState?.contract : 'Ongoing');

  const handleChange = (e) => {
    setStatus(e.target.value);
  };

  const submit = () => {
    let body = { uuid: sharedState?.uuid, contract: status };
    return GrowerAPI.updateGrower(body).then((response) => {
      if (response?.status === 200 || response?.status === 201) {
        setOpen(true);
        setAlertMessage(t('ALERT.MSG10'));
        setSeverity('success');
        setTimeout(() => {
          navigate('/home');
        }, 3000);
      }
    });
  };

  return (
    <>
      <Header isToggle={true} />
      <SnackbarCustom
        open={open}
        setOpen={setOpen}
        alertMessage={alertMessage}
        severity={severity}
      />
      <Box
        sx={{
          bgcolor: '#fff',
          border: '2px solid #E6E9ED',
          borderRadius: '5px',
          margin: '1vw',
          width: '98%',
          height: '88vh',
          overflow: 'auto',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '1vw',
            borderBottom: '2px solid #DDDDDD',
            padding: '0.5vw',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5vw' }}>
            <ArrowBackIcon fontSize="small" onClick={() => navigate(`/home`)} />
            <p style={{ fontSize: '14px', fontWeight: 550 }}>{t('GROWER.DETAIL')}</p>
          </Box>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ width: '45%', height: '100%', padding: '1vw' }}>
            <Box sx={{ display: 'flex', gap: '3vw' }}>
              <Paper sx={{ maxWidth: 200 }}>
                <FlexBox1>
                  <img
                    src={FarmHouse}
                    alt=""
                    style={{ borderRadius: '50%', height: '30px', width: '30px' }}
                  />
                  <Typography sx={{ lineHeight: '20px' }}>
                    <b>{sharedState.orders[0].order_total_no_fields}</b>
                    <br />
                    <span style={{ fontSize: '80%' }}>{t('TOTAL.FIELDS')}</span>
                  </Typography>
                </FlexBox1>
              </Paper>
              <Paper sx={{ maxWidth: 200 }}>
                <FlexBox1>
                  <img
                    src={FarmHouse}
                    alt=""
                    style={{ borderRadius: '50%', height: '30px', width: '30px' }}
                  />
                  <Typography sx={{ lineHeight: '20px' }}>
                    <b>{sharedState.orders[0].order_total_ha}</b>
                    <br />
                    <span style={{ fontSize: '80%' }}>{t('TOTAL.HECTARES')}</span>
                  </Typography>
                </FlexBox1>
              </Paper>
              <Paper sx={{ maxWidth: 200 }}>
                <FlexBox1>
                  <img
                    src={FarmHouse}
                    alt=""
                    style={{ borderRadius: '50%', height: '30px', width: '30px' }}
                  />
                  <Typography sx={{ lineHeight: '20px' }}>
                    <b>{sharedState.orders[0].order_price.toFixed(2)}</b>
                    <br />
                    <span style={{ fontSize: '80%' }}>{t('TOTAL.PRICE')} </span>
                  </Typography>
                </FlexBox1>
              </Paper>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0.5vw 1vw 0vw 0.5vw',
              }}
            >
              <Typography sx={{ fontSize: '12px', fontWeight: 550 }}>
                {t('PERSONAL.DETAILS')}
              </Typography>
              <EditIcon
                onClick={() => {
                  setEditable(true);
                  navigate('/home/addgrower');
                }}
              />
            </Box>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2} columnSpacing={2}>
                <Grid item xs={4}>
                  <FlexBox
                    children={<PersonOutlinedIcon />}
                    text1={t('GENERIC.CONTACT')}
                    text2={sharedState.contact_name}
                  />
                </Grid>
                <Grid item xs={8}>
                  <FlexBox
                    children={<PhoneAndroidOutlinedIcon />}
                    text1={t('GENERIC.MOBILE_NO')}
                    text2={sharedState.mobile_no ? sharedState.mobile_no : 'not available'}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FlexBox
                    children={<PhoneOutlinedIcon />}
                    text1={t('GENERIC.TELEPHONE')}
                    text2={sharedState.telephone_no}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FlexBox
                    children={<EmailOutlinedIcon />}
                    text1={t('REGISTRATION.EMAIL')}
                    text2={sharedState.email}
                  />
                </Grid>
                <Grid item sx={{ ml: '5vw' }}>
                  <FlexBox
                    children={<LoginIcon />}
                    text1={t('LOGIN.TEXT')}
                    text2={sharedState.app_login ? 'Yes' : 'No'}
                  />
                </Grid>
              </Grid>
            </Box>
            <hr style={{ color: '#0091DF4A', display: 'block' }} />
            <Typography sx={{ fontSize: '12px', fontWeight: 550, mt: 2 }}>
              {t('FARM.DETAILS')}
            </Typography>
            <Box sx={{ display: 'flex', gap: '3vw', mt: 1 }}>
              <FlexBox
                children={<HouseOutlinedIcon />}
                text1={t('GENERIC.FARM')}
                text2={sharedState?.name}
              />
              <FlexBox
                children={<HouseOutlinedIcon />}
                text1={t('GENERIC.ADDRESS')}
                text2={sharedState?.address1}
              />
            </Box>
            <hr style={{ color: '#0091DF4A', display: 'block', margin: '1vw 0vw' }} />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0.5vw 1vw 0vw 0.5vw',
              }}
            >
              <Typography sx={{ fontSize: '12px', fontWeight: 550 }}>
                {t('GENERIC.DELIVERY') + ' ' + t('GENERIC.DETAILS')}
              </Typography>
              <EditIcon
                onClick={() => {
                  setEditable(true);
                  navigate('/home/deliverydetails');
                }}
              />
            </Box>

            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <FlexBox
                    children={<PersonOutlinedIcon />}
                    text1={t('GENERIC.CONTACT')}
                    text2={
                      sharedState?.orders[0].order_contact_name
                        ? sharedState?.orders[0].order_contact_name
                        : 'not available'
                    }
                  />
                </Grid>
                <Grid item xs={8}>
                  <FlexBox
                    children={<PhoneAndroidOutlinedIcon />}
                    text1={t('GENERIC.MOBILE_NO')}
                    text2={
                      sharedState?.orders[0].order_mobile_no
                        ? sharedState?.orders[0].order_mobile_no
                        : 'not available'
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <FlexBox
                    children={<LocalShippingOutlinedIcon />}
                    text1={t('GENERIC.DEL_ADDRESS')}
                    text2={
                      sharedState?.orders[0].order_del_address
                        ? sharedState?.orders[0].order_del_address
                        : 'not available'
                    }
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box sx={{ width: '55%', height: '100%', padding: '1vw' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography sx={{ fontSize: '14px', fontWeight: 550 }}>
                {t('FIELD.DETAILS')}
              </Typography>
              <EditIcon
                onClick={() => {
                  setEditable(true);
                  navigate('/home/fielddetails');
                }}
              />
            </Box>
            <Box sx={{ backgroundColor: '#E9F9FF' }}>
              <Box sx={{ display: 'flex', gap: '2vw', padding: '1vw' }}>
                <FormControl size="small" sx={{ width: 200 }}>
                  <InputLabel>{t('GENERIC.CONTRACT') + ' ' + t('GENERIC.STATUS')}</InputLabel>
                  <Select
                    value={status}
                    label={t('GENERIC.CONTRACT') + ' ' + t('GENERIC.STATUS')}
                    onChange={handleChange}
                  >
                    <MenuItem value="Ongoing">Ongoing</MenuItem>
                    <MenuItem value="Signed">Signed</MenuItem>
                  </Select>
                </FormControl>
                <Button
                  onClick={submit}
                  sx={{ display: 'block', height: 38, width: 150 }}
                  variant="contained"
                >
                  {t('GENERIC.SAVE')}
                </Button>
              </Box>
              {sharedState.orders[0].order_fields.map((field) => {
                return (
                  <>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '3vw', padding: '1vw' }}>
                      <Typography variant="body2">
                        {t('GENERIC.FIELD')}
                        <br />
                        <b>{field.field_name}</b>
                      </Typography>
                      <Typography variant="body2">
                        {t('GENERIC.SIZE')}
                        <br />
                        {field.area}
                      </Typography>
                      <Typography variant="body2">
                        {t('FIELD.VARIETY')}
                        <br />
                        {field.variety}
                      </Typography>
                      <Typography variant="body2">
                        {t('FIELD.MIXED')}
                        <br />
                        {field?.mixed_variety ? 'Yes' : 'No'}
                      </Typography>
                      <Typography variant="body2">
                        {t('FIELD.PREVIOUS_CROP')}
                        <br />
                        {field.previous_crop}
                      </Typography>
                      <Typography variant="body2">
                        {t('FIELD.TILLAGE')}
                        <br />
                        {field.tillage ? 'Yes' : 'No'}
                      </Typography>
                    </Box>
                    <hr style={{ color: '#0091DF4A', display: 'block' }} />
                  </>
                );
              })}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default GrowerDetailOverview;
