import React from 'react';
import { TableCell, TableRow } from '@mui/material';
import { BpCheckbox } from '../../utils/commonFunctions';
import TableSortLabelCustom from '../Layout/TableSortLabelCustom';

const HeaderRow = ({
  sortBy,
  handleSort,
  sortDirection,
  checked,
  columns,
  onSelectAll,
}): JSX.Element => {
  return (
    <TableRow>
      <TableCell
        padding="checkbox"
        sx={{ backgroundColor: '#0091DF', border: '1px solid #DDDDDD' }}
      >
        <BpCheckbox checked={checked} onChange={onSelectAll} />
      </TableCell>
      {columns.map((column, i) => (
        <TableCell
          key={column.name}
          align="center"
          sx={{
            backgroundColor: '#0091DF',
            border: '1px solid #DDDDDD',
            padding: 0,
            ...(column.sticky ? { position: 'sticky', left: `${i * column.width}px` } : {}),
          }}
        >
          <TableSortLabelCustom
            active={sortBy === column.name}
            sortDirection={sortDirection}
            onClick={() => handleSort(column.name)}
            text={column.label}
          />
        </TableCell>
      ))}
    </TableRow>
  );
};

export default HeaderRow;
