import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Icon from '@mui/material/Icon';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import MailIcon from '../../images/MailIcon2x.png';
import Visibility from '../../images/Visibility2x.png';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import PersonOutlineIcon from '../../images/person2x.png';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import CssBaseline from '@mui/material/CssBaseline';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import LoginAPI from '../../api/login';
import './registration.css';
import BrandLogo from '../../images/background2x.png';
import BrandIcon from '../../images/brandlogo2x.png';
import SnackbarCustom from '../Snackbar';
import { useTranslation } from 'react-i18next';
import TermsAndConditions from '../../utils/TermsAndConditions';

const Registration = () => {
  const { t } = useTranslation();
  const [salutation, setSalutation] = useState('');
  const [language, setLanguage] = useState('');
  const [checked, setChecked] = useState(false);
  const [, setPassword] = useState('');
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const [openTandC, setOpenTandC] = useState(false);

  const navigate = useNavigate();

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
  };

  const getCharacterValidationError = (str) => {
    return `Your password must have at least 1 ${str} character`;
  };

  const validationSchema = Yup.object().shape({
    salutation: Yup.string(),
    firstName: Yup.string().required(t('REGISTER.ERR_FIRST')),
    lastName: Yup.string().required(t('REGISTER.ERR_LAST')),
    email: Yup.string().required(t('REGISTER.ERR_EMAIL')).email(t('REGISTER.ERR_EMAIL_INVALID')),
    password: Yup.string()
      .required(t('REGISTER.ERR_PASS'))
      // check minimum characters
      .min(8, t('REGISTER.ERR_MIN'))
      // different error messages for different requirements
      .matches(/[0-9]/, getCharacterValidationError('digit'))
      .matches(/[a-z]/, getCharacterValidationError('lowercase'))
      .matches(/[A-Z]/, getCharacterValidationError('uppercase'))
      .matches('[!@#$%^&*(),.?":{}|<>]', getCharacterValidationError('special')),
    confirmPassword: Yup.string().oneOf([Yup.ref('password'), null]),
    language: Yup.string(),
  });
  const defaultValues = {
    salutation: '',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    language: '',
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues,
  });

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const submit = async (data) => {
    try {
      let response = await LoginAPI.accountSearch(data.email);

      if (response.objectsCount > 0 && response.results[0].emails.unverified.length > 0) {
        //  const UID = response.results[0].UID;
        //  LoginAPI.resendVerifyEmail(data.email,UID);
        setOpen(true);
        setAlertMessage(<Typography sx={{ fontSize: '12px' }}>{t('ALERT.MSG14')}</Typography>);
        setSeverity('warning');
      } else if (response.objectsCount > 0) {
        setOpen(true);
        setAlertMessage(t('ALERT.MSG14'));
        setSeverity('error');
        return;
      } else {
        let response = await LoginAPI.initRegistration();
        let initToken = response.regToken;
        const response2 = await LoginAPI.register({ data, initToken });
        if (response.statusCode >= 300) {
          setOpen(true);
          setAlertMessage(t('ALERT.MSG14'));
          setSeverity('error');
          setTimeout(() => {
            navigate('/register');
          }, 3000);
          return;
        }

        // let regToken = response2.regToken;
        // let response3 = await LoginAPI.finalizeRegistration(regToken);
        // let body = {gigya_uuid:response2?.UID,email:response2?.profile?.email,name:response2?.profile?.firstName +" "+ response2?.profile?.lastName}

        //let response3 = await UserAPI.addUser(body);
        if (response2.statusCode === 200) {
          reset({});
          setSalutation('');
          setLanguage('');
          setOpen(true);
          setAlertMessage(t('ALERT.MSG15'));
          setSeverity('success');
          setTimeout(() => {
            navigate('/');
          }, 3000);
        }
      }
    } catch (error) {
      console.error('Error during registration', error);
    }
  };

  return (
    <div className="regmain">
      <div className="regcontent">
        <img id="regbackground" src={BrandLogo} alt="background" />
        <img id="regbrand" src={BrandIcon} alt="Tool Logo" />
        <Box>
          <Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <Typography
                sx={{ borderBottom: '2px solid #FF3162', fontWeight: 550, fontSize: '1em' }}
              >
                {t('REGISTRATION.TEXT').toUpperCase()}
              </Typography>
              <Typography component="p" variant="body2" sx={{ mt: 0.5 }}>
                {t('REGISTRATION.NOTE') + ' ' + t('GENERIC.MANDATORY')}
              </Typography>
            </Box>

            <Box sx={{ width: '80%', margin: 'auto', height: 'auto', position: 'relative' }}>
              <CssBaseline />
              <TermsAndConditions open={openTandC} setOpenTandC={setOpenTandC} />
              <SnackbarCustom
                open={open}
                setOpen={setOpen}
                alertMessage={alertMessage}
                severity={severity}
              />
              <Box
                sx={{
                  marginTop: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Box component="form" noValidate onSubmit={handleSubmit(submit)} sx={{ mt: 1 }}>
                  <Grid
                    container
                    columnSpacing={1}
                    rowSpacing={1.5}
                    columns={12}
                    justifyContent="center"
                  >
                    <Grid item xs={10} sm={5}>
                      <FormControl size="small" fullWidth>
                        <InputLabel id="salutationLabel">{t('REGISTRATION.SALUTATION')}</InputLabel>
                        <Select
                          labelId="salutationLabel"
                          id="salutation"
                          name="salutation"
                          value={salutation}
                          label={t('REGISTRATION.SALUTATION')}
                          {...register('salutation')}
                          onChange={(e) => setSalutation(e.target.value)}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value="Mr">Mr</MenuItem>
                          <MenuItem value="Mrs">Mrs</MenuItem>
                          <MenuItem value="Miss">Miss</MenuItem>
                          <MenuItem value="Ms">Ms</MenuItem>
                          <MenuItem value="Dr">Dr</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={10} sm={5}></Grid>
                    <Grid item xs={10} sm={5}>
                      <FormControl variant="outlined" size="small" fullWidth>
                        <InputLabel htmlFor="outlined-adornment-firstName">
                          {t('REGISTRATION.FIRST')}*
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-firstName"
                          type="text"
                          name="firstName"
                          endAdornment={
                            <InputAdornment position="end">
                              <Icon edge="end" color="primary">
                                <img
                                  src={PersonOutlineIcon}
                                  alt="password"
                                  style={{ height: '60%', width: '80%' }}
                                />
                              </Icon>
                            </InputAdornment>
                          }
                          label={t('REGISTRATION.FIRST')}
                          autoComplete="firstName"
                          {...register('firstName')}
                          error={errors.firstName ? true : false}
                        />{' '}
                        <span style={{ color: 'red', fontSize: '12px' }}>
                          {errors.firstName?.message}
                        </span>
                      </FormControl>
                    </Grid>
                    <Grid item xs={10} sm={5}>
                      <FormControl variant="outlined" size="small" fullWidth>
                        <InputLabel htmlFor="outlined-adornment-lastName">
                          {t('REGISTRATION.LAST')}*
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-lastName"
                          type="text"
                          name={t('REGISTRATION.FIRST')}
                          endAdornment={
                            <InputAdornment position="end">
                              <Icon color="primary" edge="end">
                                <img
                                  src={PersonOutlineIcon}
                                  alt="password"
                                  style={{ height: '60%', width: '80%' }}
                                />
                              </Icon>
                            </InputAdornment>
                          }
                          label={t('REGISTRATION.LAST')}
                          autoComplete="lastName"
                          {...register('lastName')}
                          error={errors.lastName ? true : false}
                        />{' '}
                        <span style={{ color: 'red', fontSize: '12px' }}>
                          {errors.lastName?.message}
                        </span>
                      </FormControl>
                    </Grid>
                    <Grid item xs={10} sm={5}>
                      <FormControl variant="outlined" size="small" fullWidth>
                        <InputLabel htmlFor="outlined-adornment-email">
                          {t('LOGIN.EMAIL')}*
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-email"
                          type="email"
                          name="email"
                          endAdornment={
                            <InputAdornment position="end">
                              <Icon edge="end" color="primary">
                                <img
                                  src={MailIcon}
                                  alt="password"
                                  style={{ height: '60%', width: '90%' }}
                                />
                              </Icon>
                            </InputAdornment>
                          }
                          label={t('LOGIN.EMAIL')}
                          autoComplete="email"
                          {...register('email')}
                          error={errors.email ? true : false}
                        />{' '}
                        <span style={{ color: 'red', fontSize: '12px' }}>
                          {errors.email?.message}
                        </span>
                      </FormControl>
                    </Grid>
                    <Grid item xs={10} sm={5}>
                      <FormControl variant="outlined" size="small" fullWidth>
                        <InputLabel htmlFor="outlined-adornment-password">
                          {t('LOGIN.PASSWORD')}*
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-password"
                          type="password"
                          name="password"
                          label={t('LOGIN.PASSWORD')}
                          autoComplete="current-password"
                          {...register('password')}
                          onChange={handlePasswordChange}
                          error={errors.password ? true : false}
                        />{' '}
                        <span style={{ color: 'red', fontSize: '12px' }}>
                          {errors.password?.message}
                        </span>
                      </FormControl>
                    </Grid>
                    <Grid item xs={10} sm={5}>
                      <FormControl variant="outlined" size="small" fullWidth>
                        <InputLabel htmlFor="outlined-adornment-confirmPassword">
                          {t('REGISTRATION.CONFIRM')}*
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-confirmPassword"
                          type={showPassword ? 'text' : 'password'}
                          name="confirmPassword"
                          endAdornment={
                            <InputAdornment position="end">
                              <Icon
                                color="primary"
                                aria-label="toggle password visibility"
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                <img
                                  src={Visibility}
                                  alt="password"
                                  style={{ height: '60%', width: '90%' }}
                                  onClick={() => {
                                    setShowPassword(!showPassword);
                                  }}
                                />
                              </Icon>
                            </InputAdornment>
                          }
                          label={t('REGISTRATION.CONFIRM')}
                          autoComplete="confirm-password"
                          {...register('confirmPassword')}
                          error={errors.confirmPassword ? true : false}
                        />{' '}
                        <span style={{ color: 'red', fontSize: '12px' }}>
                          {errors.confirmPassword && "Password doesn't match"}
                        </span>
                      </FormControl>
                    </Grid>
                    <Grid item xs={10} sm={5}>
                      <FormControl size="small" fullWidth>
                        <InputLabel id="demo-language">
                          {t('REGISTRATION.LANGUAGE_PREFERENCE')}
                        </InputLabel>
                        <Select
                          labelId="demo-language"
                          id="language"
                          name="language"
                          value={language}
                          label="Language Preference"
                          {...register('language')}
                          onChange={(e) => setLanguage(e.target.value)}
                        >
                          <MenuItem value="">
                            <em>{t('NONE')}</em>
                          </MenuItem>
                          <MenuItem value="en">{t('PROFILE.ENGLISH')}</MenuItem>
                          <MenuItem value="fr">{t('PROFILE.FRENCH')}</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={10}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <FormControlLabel
                          size="small"
                          control={
                            <Checkbox
                              value="allowExtraEmails"
                              color="primary"
                              checked={checked}
                              onChange={(event) => setChecked(event.target.checked)}
                            />
                          }
                          label={t('REGISTRATION.ACCEPT')}
                        />
                        <Typography
                          component="span"
                          sx={{ textDecoration: 'underline' }}
                          color="primary"
                          onClick={() => setOpenTandC(true)}
                        >
                          {t('REGISTRATION.ACS')}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container justifyContent="center">
                    <Button
                      type="submit"
                      sx={{ width: 250, height: 40, mt: 1 }}
                      variant="contained"
                      disabled={!checked}
                    >
                      {t('REGISTRATION.REGISTER')}
                    </Button>
                  </Grid>
                  <Grid container justifyContent="center" sx={{ mb: 2 }}>
                    <Grid item>
                      {t('GENERIC.ALREADY')}?<span> </span>
                      <Link to="/" style={{ color: '#00617F' }}>
                        {t('GENERIC.LOGIN')}
                      </Link>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </div>
    </div>
  );
};
export default Registration;
