import React from 'react';
import { Modal, Box, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useStateContext } from './stateContext';

const TermsAndConditions = ({ open, setOpenTandC }) => {
  const { t } = useStateContext();
  return (
    <Modal
      open={open}
      onClose={() => setOpenTandC(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%,-50%)',
          width: '90%',
          height: '85%',
          bgcolor: 'white',
          borderRadius: '10px',
          padding: '1vw',
        }}
      >
        <Box sx={{ height: '100%' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '0vw 1vw' }}>
            <Typography sx={{ fontWeight: 550 }}>{t('REGISTRATION.ACS')}</Typography>
            <CloseIcon fontSize="large" onClick={() => setOpenTandC(false)} />
          </Box>
          <object
            aria-label="gigya"
            data="https://accounts.eu1.gigya.com/accounts.store.downloadPublicConsentDocument?docID=2090441_7666831_90b737bf7435464c897e683723e11927"
            style={{ width: '100%', height: '90%' }}
          ></object>
        </Box>
      </Box>
    </Modal>
  );
};

export default TermsAndConditions;
