import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import MailIcon from '@mui/icons-material/Mail';
import BrandLogo from '../../images/background2x.png';
import BrandIcon from '../../images/brandlogo2x.png';

import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import Mail from '../../images/resetMail.png';
import LoginAPI from '../../api/login';

import './resetpassword.css';
import { Link, Divider } from '@mui/material';
import SnackbarCustom from '../Snackbar';
import { useTranslation } from 'react-i18next';

export default function ResetPassword() {
  const { t } = useTranslation();
  const [resetStatus, setResetStatus] = useState(false);
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  const validationSchema = Yup.object().shape({
    email: Yup.string().required(t('REGISTER.ERR_EMAIL')).email(t('REGISTER.ERR_EMAIL_INVALID')),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({ resolver: yupResolver(validationSchema) });

  let navigate = useNavigate();
  const redirectLogin = (path) => {
    navigate(path);
  };

  const submit = (data) => {
    if (data.email !== '') {
      let userCredentials = {
        loginID: data.email || getValues('email'),
      };

      return LoginAPI.resetPassword(userCredentials).then(({ data }) => {
        if (data?.statusCode === 200) {
          setResetStatus(true);
        } else {
          setOpen(true);
          setAlertMessage(t('ALERT.MSG16'));
          setSeverity('error');
        }
      });
    }
  };
  const resubmit = () => {
    let userCredentials = {
      loginID: getValues('email'),
    };

    return LoginAPI.resetPassword(userCredentials).then(({ data }) => {
      if (data?.statusCode === 200) {
        setOpen(true);
        setAlertMessage(t('ALERT.MSG17'));
        setSeverity('success');
      }
    });
  };
  return (
    <Box>
      <SnackbarCustom
        open={open}
        setOpen={setOpen}
        alertMessage={alertMessage}
        severity={severity}
      />
      <div className="resetmain">
        <div className="resetcontent">
          <img id="resetbackground" src={BrandLogo} alt="background" />
          <img id="resetbrand" src={BrandIcon} alt="Tool Logo" />
          {!resetStatus ? (
            <Box
              component="form"
              onSubmit={handleSubmit(submit)}
              noValidate
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Typography
                component="h6"
                variant="body1"
                sx={{ borderBottom: '2px solid #FF3162', fontWeight: 550 }}
              >
                {t('LOGIN.RESET_PASSWORD').toUpperCase()}
              </Typography>
              <Typography component="p" variant="body2" sx={{ mt: 2, padding: '0vw 1vw' }}>
                {t('RESET.MANDATORY') + ' ' + t('GENERIC.MANDATORY')}
              </Typography>

              <Container maxWidth="xs" sx={{ mt: 2 }}>
                <FormControl fullWidth size="small" variant="outlined" sx={{ mt: 1 }}>
                  <InputLabel htmlFor="outlined-adornment-email">{t('LOGIN.EMAIL')}*</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-email"
                    type="email"
                    name="email"
                    endAdornment={
                      <InputAdornment position="end">
                        <Icon edge="end" color="primary">
                          <MailIcon />
                        </Icon>
                      </InputAdornment>
                    }
                    label={t('LOGIN.EMAIL')}
                    autoComplete="email"
                    {...register('email')}
                    error={errors.email ? true : false}
                  />{' '}
                  <span style={{ color: 'red', fontSize: '12px' }}>{errors.email?.message}</span>
                </FormControl>
                <Button
                  type="submit"
                  fullWidth
                  color="primary"
                  variant="contained"
                  sx={{ mt: 4, mb: 1, height: '40px' }}
                >
                  {t('GENERIC.SEND')}
                </Button>
                <Button
                  type="button"
                  fullWidth
                  color="primary"
                  variant="outlined"
                  sx={{ mt: 1, mb: 3, height: '40px' }}
                  onClick={() => redirectLogin(`/`)}
                >
                  {t('RESET.CANCEL')}
                </Button>
              </Container>
            </Box>
          ) : (
            <Box
              sx={{
                mt: 5,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img src={Mail} style={{ width: '10%', height: '5%' }} alt="" />
              <Typography color="primary" component="h1" variant="h6" sx={{ mb: 1 }}>
                <strong>{t('GENERIC.CONGRACTULATIONS')}!</strong>
              </Typography>
              <Typography variant="body2">{t('FORGOT.TEXT1')} </Typography>
              <Typography variant="body2">
                - <strong>{getValues('email')}</strong>
              </Typography>
              <Typography variant="body2">{t('FORGOT.TEXT3')}</Typography>
              <Divider
                sx={{ margin: '1vw 4vw' }}
                orientation="horizontal"
                variant="middle"
                flexItem
              />

              <Typography variant="body2">{t('FORGOT.TEXT4')}</Typography>
              <Link>
                <Button sx={{ fontSize: '13px', mb: 3 }} onClick={resubmit}>
                  {t('FORGOT.RESEND_LINK')}
                </Button>
              </Link>
              <Link to="/" style={{ color: '#00617F' }}>
                {t('GENERIC.LOGIN')}
              </Link>
            </Box>
          )}
        </div>
      </div>
    </Box>
  );
}
