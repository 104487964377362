import React from 'react';
import { TableSortLabel } from '@mui/material';
import UnfoldMoreOutlinedIcon from '@mui/icons-material/UnfoldMoreOutlined';

const TableSortLabelCustom = ({ sortDirection, onClick, text, active }) => {
  return (
    <TableSortLabel
      active={active}
      sx={{
        '&.MuiTableSortLabel-root': {
          color: 'white',
          fontSize: '95%',
          padding: 0,
          margin: '5px 0px',
        },
        '&.MuiTableSortLabel-root:hover': {
          color: 'white',
          padding: 0,
          margin: '5px 0px',
        },
        '&.Mui-active': {
          color: 'white',
          padding: 0,
          margin: '5px 0px',
        },
        '& .MuiTableSortLabel-icon': {
          color: 'white !important',
          padding: 0,
          margin: '5px 0px',
        },
      }}
      direction={sortDirection}
      IconComponent={UnfoldMoreOutlinedIcon}
      onClick={onClick}
    >
      {text}
    </TableSortLabel>
  );
};

export default TableSortLabelCustom;
