import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import RocketLaunchOutlinedIcon from '@mui/icons-material/RocketLaunchOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import {
  Box,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import GrowerAPI from '../../api/grower';
import CustomTablePagination from '../../utils/CustomTablePagination';
import { HtmlTooltip, StyledTableCell, StyledTableCell1 } from '../../utils/commonFunctions';
import { useStateContext } from '../../utils/stateContext';
import CircularProgress from '../CircularProgress';
import TableNoResults from '../Layout/TableNoResults';
import useSortedList from '../../hooks/useSortedList';
import TableSortLabelCustom from '../Layout/TableSortLabelCustom';
import FilterBar from '../Table/FilterBar';
import useFilteredList from '../../hooks/useFilteredList';
import Addproduct from './AddProduct';

const ProductMixTable = ({ color, product, dosage, total_area }) => {
  const { t } = useTranslation();
  let productArray = product.length > 0 ? product.split('/') : [];
  let dosageArray = dosage?.length > 0 ? dosage.split('/') : [];
  let area = dosageArray.map((x) => x * total_area);

  return (
    <Box>
      <Paper>
        <Table sx={{ width: 250 }}>
          <TableHead>
            <TableRow>
              <StyledTableCell1 sx={{ background: color }}>
                {t('PRODUCT_DELIVERY.PRODUCT')}
              </StyledTableCell1>
              <StyledTableCell1 sx={{ background: color }}>
                {t('PRODUCT_DELIVERY.DOSAGE')}
              </StyledTableCell1>
              <StyledTableCell1 sx={{ background: color }}>
                {t('PRODUCT_DELIVERY.TOTAL')}
              </StyledTableCell1>
            </TableRow>
          </TableHead>
          <TableBody>
            {productArray.map((product, index) => (
              <TableRow key={`${product}${index}`}>
                <StyledTableCell1>{product}</StyledTableCell1>
                <StyledTableCell1>{dosageArray[index]}</StyledTableCell1>
                <StyledTableCell1>{area[index] ? area[index].toFixed(2) : '--'}</StyledTableCell1>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </Box>
  );
};

const ProductDelivery = () => {
  const {
    t,
    getData,
    loading,
    productList,
    snackbar: { success },
  } = useStateContext();
  const [filteredData] = useState(productList);
  const [page, setPage] = useState(0);
  const [searchOpen, setSearchOpen] = useState(false);
  const [openProduct, setOpenProduct] = useState(false);
  const [product, setProduct] = useState({});
  const [edit, setEdit] = useState(false);

  let date = new Date();

  const stickyColumnWidth = 160;
  const columns = [
    {
      name: 'created_user_name',
      label: t('HOME.SALES_REP_NAME'),
      width: stickyColumnWidth,
      color: '#0091DF',
      search: true,
      sticky: true,
    },
    {
      name: 'grower_name',
      label: t('GENERIC.FARM'),
      width: stickyColumnWidth,
      color: '#0091DF',
      search: true,
      sticky: true,
    },
    {
      name: 'farm_name',
      label: t('GENERIC.FIELD'),
      width: stickyColumnWidth,
      color: '#0091DF',
      search: true,
      sticky: true,
    },
    {
      name: 'total_area',
      label: '',
      width: stickyColumnWidth,
      color: '#0091DF',
      search: true,
      sticky: true,
    },
    { name: 'T1product', label: '', color: '#0091DF', search: true },
    { name: 'T1dosage', label: '', color: '#0091DF', search: true },
    { name: 'T1total', label: '', color: '#0091DF', search: false },
    { name: 'T2product', label: '', color: '#0091DF', search: true },
    { name: 'T2dosage', label: '', color: '#0091DF', search: true },
    { name: 'T2total', label: '', color: '#0091DF', search: false },
    { name: 'T3product', label: '', color: '#0091DF', search: true },
    { name: 'T3dosage', label: '', color: '#0091DF', search: true },
    { name: 'T3total', label: '', color: '#0091DF', search: false },
  ];

  const { sortedData, handleSort, sortDirection, sortBy } = useSortedList(filteredData, columns);
  const { filteredData: tableData, ...filterProps } = useFilteredList(sortedData, columns);

  const productSend = (body) => {
    GrowerAPI.addProduct(body).then((response) => {
      if (response.status === 200) {
        success(`Product ${body?.sent ? 'sent' : 'unsent'} successfully`);
        setEdit(false);
        setTimeout(() => {
          getData();
        }, 2000);
      }
    });
  };
  const calculateDosage = (array, total_area) => {
    if (array.length > 0) {
      return array
        .split('/')
        .map((x) => (x * total_area).toFixed(2))
        .join(' / ');
    }
    return null;
  };
  const style = {
    fontSize: 13,
    display: 'inline-block',
    maxWidth: '60%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineHeight: 3,
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '0vw 1vw',
        }}
      >
        <Box>
          <Typography variant="body2" sx={{ fontWeight: 550, fontSize: '14px' }}>
            {'Product Delivery'}
          </Typography>
          <Typography sx={{ fontSize: '12px', opacity: '0.5' }}>
            {t('GENERIC.LAST_UPDATED')}{' '}
            {dayjs(date).format('DD-MM-YYYY') + ' ' + date.getHours() + ':' + date.getMinutes()}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '0.7vw',
          }}
        >
          <Button
            onClick={() => getData()}
            sx={{ borderRadius: '5px', border: '1px solid #CCCCCC', padding: 0, minWidth: 0 }}
          >
            <Tooltip title="Refresh Data">
              <RefreshOutlinedIcon />
            </Tooltip>
          </Button>
          <Button
            sx={{ borderRadius: '5px', border: '1px solid #CCCCCC', padding: 0, minWidth: 0 }}
            onClick={() => setSearchOpen(!searchOpen)}
          >
            <Tooltip title="Search">
              <SearchOutlinedIcon />
            </Tooltip>
          </Button>
        </Box>
      </Box>
      <Addproduct
        openProduct={openProduct}
        setOpenProduct={setOpenProduct}
        product={product}
        setProduct={setProduct}
        edit={edit}
        setEdit={setEdit}
      />
      <Box sx={{ mt: 1 }}>
        <TableContainer sx={{ height: '100%', maxHeight: 600, overflow: 'auto' }}>
          <Table stickyHeader aria-label="sticky table" sx={{ width: '150%' }}>
            <TableHead sx={{ position: 'sticky', top: 0, zIndex: 2 }}>
              <TableRow>
                <TableCell
                  align="center"
                  sx={{
                    backgroundColor: '#0091DF',
                    border: '1px solid #DDDDDD',
                    padding: 0,
                    width: `${stickyColumnWidth}px`,
                    position: 'sticky',
                    left: 0,
                    zIndex: 10,
                  }}
                  rowSpan={2}
                >
                  <TableSortLabelCustom
                    active={sortBy === 'created_user_name'}
                    sortDirection={sortDirection}
                    onClick={() => handleSort('created_user_name')}
                    text={t('AGRONOMY.SALES_REP_NAME')}
                  />
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    backgroundColor: '#0091DF',
                    border: '1px solid #DDDDDD',
                    padding: 0,
                    width: `${stickyColumnWidth}px`,
                    position: 'sticky',
                    left: stickyColumnWidth,
                    zIndex: 10,
                  }}
                  rowSpan={2}
                >
                  <TableSortLabelCustom
                    active={sortBy === 'grower_name'}
                    sortDirection={sortDirection}
                    onClick={() => handleSort('grower_name')}
                    text={'Grower name'}
                  />
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    backgroundColor: '#0091DF',
                    border: '1px solid #DDDDDD',
                    padding: 0,
                    width: `${stickyColumnWidth}px`,
                    position: 'sticky',
                    left: stickyColumnWidth * 2,
                    zIndex: 10,
                  }}
                  rowSpan={2}
                >
                  <TableSortLabelCustom
                    active={sortBy === 'farm_name'}
                    sortDirection={sortDirection}
                    onClick={() => handleSort('farm_name')}
                    text={t('GENERIC.FARM')}
                  />
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    backgroundColor: '#0091DF',
                    border: '1px solid #DDDDDD',
                    padding: 0,
                    width: `${stickyColumnWidth}px`,
                    position: 'sticky',
                    left: stickyColumnWidth * 3,
                    zIndex: 10,
                  }}
                  rowSpan={2}
                >
                  <TableSortLabelCustom
                    active={sortBy === 'total_area'}
                    sortDirection={sortDirection}
                    onClick={() => handleSort('total_area')}
                    text={'Total farm(Ha)'}
                  />
                </TableCell>
                <StyledTableCell1 colSpan={3} align="center" sx={{ backgroundColor: '#02A8A8' }}>
                  T1
                </StyledTableCell1>
                <StyledTableCell1 colSpan={3} align="center" sx={{ backgroundColor: '#00A6E1' }}>
                  T2
                </StyledTableCell1>
                <StyledTableCell1 colSpan={3} align="center" sx={{ backgroundColor: '#01849D' }}>
                  T3
                </StyledTableCell1>
              </TableRow>
              <TableRow>
                <StyledTableCell1
                  align="center"
                  sx={{ backgroundColor: '#02A8A8', maxWidth: '7vw' }}
                >
                  {t('AGRONOMY.PRODUCT')}
                </StyledTableCell1>
                <StyledTableCell1
                  align="center"
                  sx={{ backgroundColor: '#02A8A8', maxWidth: '7vw' }}
                >
                  {t('AGRONOMY.DOSAGE')} (L/HA)
                </StyledTableCell1>
                <StyledTableCell1
                  align="center"
                  sx={{ backgroundColor: '#02A8A8', maxWidth: '7vw' }}
                >
                  {'Total product'} (L)
                </StyledTableCell1>
                <StyledTableCell1
                  align="center"
                  sx={{ backgroundColor: '#00A6E1', maxWidth: '7vw' }}
                >
                  {t('AGRONOMY.PRODUCT')}
                </StyledTableCell1>
                <StyledTableCell1
                  align="center"
                  sx={{ backgroundColor: '#00A6E1', maxWidth: '7vw' }}
                >
                  {t('AGRONOMY.DOSAGE')} (L/HA)
                </StyledTableCell1>
                <StyledTableCell1
                  align="center"
                  sx={{ backgroundColor: '#00A6E1', maxWidth: '7vw' }}
                >
                  {'Total product'} (L)
                </StyledTableCell1>
                <StyledTableCell1
                  align="center"
                  sx={{ backgroundColor: '#01849D', maxWidth: '7vw' }}
                >
                  {t('AGRONOMY.PRODUCT')}
                </StyledTableCell1>
                <StyledTableCell1
                  align="center"
                  sx={{ backgroundColor: '#01849D', maxWidth: '7vw' }}
                >
                  {t('AGRONOMY.DOSAGE')} (L/HA)
                </StyledTableCell1>
                <StyledTableCell1
                  align="center"
                  sx={{ backgroundColor: '#01849D', maxWidth: '7vw' }}
                >
                  {'Total product'} (L)
                </StyledTableCell1>
              </TableRow>
              <FilterBar
                {...filterProps}
                isOpen={searchOpen}
                columns={columns}
                selectable={false}
              />
            </TableHead>
            <TableBody>
              {tableData.map((row, index) => (
                <TableRow key={row.id}>
                  <StyledTableCell1
                    align="center"
                    sx={{ position: 'sticky', left: 0, backgroundColor: '#FFF', zIndex: 1 }}
                  >
                    <Typography sx={{ fontSize: 13, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      {row.created_user_name}
                    </Typography>
                  </StyledTableCell1>
                  <StyledTableCell1
                    align="center"
                    sx={{
                      position: 'sticky',
                      left: stickyColumnWidth,
                      backgroundColor: '#FFF',
                      zIndex: 1,
                    }}
                  >
                    <Typography sx={{ fontSize: 13, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      {row.grower_name}
                    </Typography>
                  </StyledTableCell1>
                  <StyledTableCell1
                    align="center"
                    sx={{
                      position: 'sticky',
                      left: stickyColumnWidth * 2,
                      backgroundColor: '#FFF',
                      zIndex: 1,
                    }}
                  >
                    <Typography sx={{ fontSize: 13, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      {row.farm_name}
                    </Typography>
                  </StyledTableCell1>
                  <StyledTableCell1
                    align="center"
                    sx={{
                      position: 'sticky',
                      left: stickyColumnWidth * 3,
                      backgroundColor: '#FFF',
                      zIndex: 1,
                    }}
                  >
                    <Typography sx={{ fontSize: 13, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      {row.total_area}
                    </Typography>
                  </StyledTableCell1>
                  <StyledTableCell
                    align="center"
                    sx={{ maxWidth: '7vw', '&:hover': { cursor: 'pointer' } }}
                    onClick={() => {
                      setOpenProduct(true);
                      setProduct({
                        id: row?.T1id,
                        pd_window: 'T1',
                        order_id: row.order_id,
                        sent: row?.T1sent,
                      });
                    }}
                  >
                    <HtmlTooltip
                      title={
                        <ProductMixTable
                          color="#02A8A8"
                          product={row?.T1product}
                          dosage={row?.T1dosage}
                          total_area={row?.total_area}
                        />
                      }
                    >
                      <Typography sx={style}>{row.T1product}</Typography>
                    </HtmlTooltip>
                  </StyledTableCell>
                  <StyledTableCell1 align="center" sx={{ maxWidth: '7vw' }}>
                    <Typography sx={style}>{row.T1dosage}</Typography>
                  </StyledTableCell1>
                  <StyledTableCell1 align="center" sx={{ maxWidth: '7vw' }}>
                    <Typography sx={style}>
                      {row?.T1dosage ? calculateDosage(row.T1dosage, row?.total_area) : '--'}
                    </Typography>
                    {row.T1sent ? (
                      <IconButton sx={{ float: 'right' }}>
                        <RocketLaunchIcon
                          onClick={() =>
                            productSend({
                              pd_window: row?.pd_window,
                              product: row?.T1product,
                              dosage: row?.T1dosage,
                              order_id: row?.order_id,
                              id: row?.T1id,
                              sent: false,
                            })
                          }
                          color="primary"
                          fontSize="medium"
                        />
                      </IconButton>
                    ) : (
                      <IconButton sx={{ float: 'right' }}>
                        <RocketLaunchOutlinedIcon
                          onClick={() =>
                            productSend({
                              pd_window: row?.pd_window,
                              product: row?.T1product,
                              dosage: row?.T1dosage,
                              order_id: row?.order_id,
                              id: row?.T1id,
                              sent: true,
                            })
                          }
                          color="primary"
                          fontSize="medium"
                        />
                      </IconButton>
                    )}
                  </StyledTableCell1>
                  <StyledTableCell
                    sx={{ maxWidth: '7vw' }}
                    align="center"
                    onClick={() => {
                      setOpenProduct(true);
                      setProduct({
                        id: row?.T2id,
                        pd_window: 'T2',
                        order_id: row.order_id,
                        sent: row?.T2sent,
                      });
                    }}
                  >
                    <HtmlTooltip
                      title={
                        <ProductMixTable
                          color="#00A6E1"
                          product={row?.T2product}
                          dosage={row?.T2dosage}
                          total_area={row?.total_area}
                        />
                      }
                    >
                      <Typography sx={style}>{row.T2product}</Typography>
                    </HtmlTooltip>
                  </StyledTableCell>
                  <StyledTableCell1 align="center" sx={{ maxWidth: '7vw' }}>
                    <Typography sx={style}>{row.T2dosage}</Typography>
                  </StyledTableCell1>
                  <StyledTableCell1 align="center" sx={{ maxWidth: '7vw' }}>
                    <Typography sx={style}>
                      {row?.T2dosage ? calculateDosage(row.T2dosage, row?.total_area) : '--'}
                    </Typography>
                    {row.T2sent ? (
                      <IconButton sx={{ float: 'right' }}>
                        <RocketLaunchIcon
                          onClick={() =>
                            productSend({
                              pd_window: row?.pd_window,
                              product: row?.T2product,
                              dosage: row?.T2dosage,
                              order_id: row?.order_id,
                              id: row?.T2id,
                              sent: false,
                            })
                          }
                          color="primary"
                          fontSize="medium"
                        />
                      </IconButton>
                    ) : (
                      <IconButton sx={{ float: 'right' }}>
                        <RocketLaunchOutlinedIcon
                          onClick={() =>
                            productSend({
                              pd_window: row?.pd_window,
                              product: row?.T2product,
                              dosage: row?.T2dosage,
                              order_id: row?.order_id,
                              id: row?.T2id,
                              sent: true,
                            })
                          }
                          color="primary"
                          fontSize="medium"
                        />
                      </IconButton>
                    )}
                  </StyledTableCell1>
                  <StyledTableCell
                    sx={{ maxWidth: '7vw' }}
                    align="center"
                    onClick={() => {
                      setOpenProduct(true);
                      setProduct({
                        id: row?.T3id,
                        pd_window: 'T3',
                        order_id: row.order_id,
                        sent: row?.T3sent,
                      });
                    }}
                  >
                    <HtmlTooltip
                      title={
                        <ProductMixTable
                          color="#01849D"
                          product={row?.T3product}
                          dosage={row?.T3dosage}
                          total_area={row?.total_area}
                        />
                      }
                    >
                      <Typography sx={style}>{row.T3product}</Typography>
                    </HtmlTooltip>
                  </StyledTableCell>
                  <StyledTableCell1 align="center" sx={{ maxWidth: '7vw' }}>
                    <Typography sx={style}>{row.T3dosage}</Typography>
                  </StyledTableCell1>
                  <StyledTableCell1 align="center" sx={{ maxWidth: '7vw' }}>
                    <Typography sx={style}>
                      {row?.T3dosage ? calculateDosage(row.T3dosage, row?.total_area) : '--'}
                    </Typography>
                    {row.T3sent ? (
                      <IconButton sx={{ float: 'right' }}>
                        <RocketLaunchIcon
                          onClick={() =>
                            productSend({
                              pd_window: row?.pd_window,
                              product: row?.T3product,
                              dosage: row?.T3dosage,
                              order_id: row?.order_id,
                              id: row?.T3id,
                              sent: false,
                            })
                          }
                          color="primary"
                          fontSize="medium"
                        />
                      </IconButton>
                    ) : (
                      <IconButton sx={{ float: 'right' }}>
                        <RocketLaunchOutlinedIcon
                          onClick={() =>
                            productSend({
                              pd_window: row?.pd_window,
                              product: row?.T3product,
                              dosage: row?.T3dosage,
                              order_id: row?.order_id,
                              id: row?.T3id,
                              sent: true,
                            })
                          }
                          color="primary"
                          fontSize="medium"
                        />
                      </IconButton>
                    )}
                  </StyledTableCell1>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {tableData.length === 0 && <TableNoResults />}
      <CustomTablePagination dataList={filteredData} page={page} setPage={setPage} />
      {loading && <CircularProgress />}
    </Box>
  );
};
export default ProductDelivery;
