import DeleteIcon from '@mui/icons-material/Delete';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import TableCell from '@mui/material/TableCell';
import React, { useState } from 'react';

import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import {
  BpCheckbox,
  HtmlTooltip,
  NoDataAvailable,
  StyledTableCell,
  uniqueNames,
  formatDate,
} from '../../utils/commonFunctions';
import { useStateContext } from '../../utils/stateContext';
import CircularProgress from '../CircularProgress';

import CustomTablePagination from '../../utils/CustomTablePagination';
import useSortedList from '../../hooks/useSortedList';
import TableNoResults from '../Layout/TableNoResults';
import HeaderRow from '../Table/HeaderRow';
import FilterBar from '../Table/FilterBar';
import useFilteredList from '../../hooks/useFilteredList';

const SubscriptionTable = ({ props }) => {
  const { title, columns, deleteData, loading } = props;
  const {
    t,
    getData,
    subscriptionList,
    navigate,
    updateSharedState,
    selectedSalesPersons,
    setSelectedSalesPersons,
    handleSalesRepCheckboxClick,
    setEditable,
    userDetails,
    setOpenComment,
    setSelectedField,
  } = useStateContext();

  const [page, setPage] = useState(0);
  const { sortedData, ...sortingProps } = useSortedList(subscriptionList, columns);
  const { filteredData: tableData, ...filterProps } = useFilteredList(sortedData, columns);

  const [searchOpen, setSearchOpen] = useState(false);
  let aggregate = tableData.reduce(
    (init, acc) => {
      init.totalFields += acc?.field_name ? 1 : 0;
      init.hectares += parseFloat(acc.field_area);
      init.price += parseFloat(acc.price);
      return init;
    },
    {
      totalFarms: 0,
      totalFields: 0,
      hectares: 0,
      price: 0,
    }
  );

  let date = new Date();

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = subscriptionList.map((n) => n.id);
      setSelectedSalesPersons(newSelected);
      return;
    }
    setSelectedSalesPersons([]);
  };

  const style = {
    fontSize: 13,
    display: 'inline-block',
    maxWidth: '7vw',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineHeight: 3,
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '0vw 1vw',
        }}
      >
        <Box>
          <Typography variant="body2" sx={{ fontWeight: 550, fontSize: '14px' }}>
            {title}
          </Typography>
          <Typography sx={{ fontSize: '12px', opacity: '0.5' }}>
            {t('GENERIC.LAST_UPDATED')}{' '}
            {`${formatDate(date)} ${date.getHours()}:${date.getMinutes()}`}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '0.7vw',
          }}
        >
          <Button
            sx={{
              borderRadius: '5px',
              border: '1px solid #00617F',
              fontSize: '10px',
              padding: '3px',
              fontWeight: 550,
            }}
            onClick={() => {
              setEditable(false);
              navigate(`/home/addgrower`);
            }}
          >
            {t('HOME.ADD_GROWER')}
          </Button>
          <Button
            onClick={() => getData()}
            sx={{ borderRadius: '5px', border: '1px solid #CCCCCC', padding: 0, minWidth: 0 }}
          >
            <RefreshOutlinedIcon />
          </Button>
          <Button
            sx={{ borderRadius: '5px', border: '1px solid #CCCCCC', padding: 0, minWidth: 0 }}
            onClick={() => setSearchOpen(!searchOpen)}
          >
            <SearchOutlinedIcon />
          </Button>
          <div>
            <Button
              onClick={() => {
                deleteData();
              }}
              sx={{ borderRadius: '5px', border: '1px solid #CCCCCC', padding: 0, minWidth: 0 }}
            >
              <DeleteIcon color="primary" />
            </Button>
          </div>
        </Box>
      </Box>
      <Box sx={{ height: '100%', mt: 1 }}>
        <TableContainer sx={{ height: '100%', maxHeight: 600 }}>
          <Table stickyHeader aria-label="sticky table" size="small" sx={{ width: '100%' }}>
            <TableHead sx={{ position: 'sticky', top: 0, zIndex: 2 }}>
              <HeaderRow
                {...sortingProps}
                columns={columns}
                onSelectAll={handleSelectAllClick}
                checked={selectedSalesPersons.length === subscriptionList.length}
              />
              <FilterBar {...filterProps} isOpen={searchOpen} columns={columns} />
            </TableHead>
            <TableBody>
              {tableData.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{ '&:hover': { backgroundColor: '#BEDCE7', cursor: 'pointer' } }}
                >
                  <TableCell padding="checkbox">
                    <BpCheckbox
                      color="primary"
                      checked={selectedSalesPersons.indexOf(row.id) !== -1}
                      onChange={() => handleSalesRepCheckboxClick(row.id)}
                    />
                  </TableCell>
                  <StyledTableCell onClick={() => updateSharedState(row)}>
                    {row.distributor}
                  </StyledTableCell>
                  <StyledTableCell onClick={() => updateSharedState(row)}>
                    {row.created_user_name}
                  </StyledTableCell>
                  <StyledTableCell onClick={() => updateSharedState(row)}>
                    {row.contact_name}
                  </StyledTableCell>
                  <StyledTableCell onClick={() => updateSharedState(row)}>
                    {row.status}
                  </StyledTableCell>
                  <StyledTableCell onClick={() => updateSharedState(row)}>
                    {formatDate(row.start_date)}
                  </StyledTableCell>
                  <StyledTableCell align="center" onClick={() => updateSharedState(row)}>
                    {row.app_login ? 'Yes' : 'No'}
                  </StyledTableCell>
                  <StyledTableCell onClick={() => updateSharedState(row)} title={row.name}>
                    <Typography
                      sx={{
                        fontSize: 13,
                        maxWidth: '7vw',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {row.name}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell onClick={() => updateSharedState(row)}>
                    <HtmlTooltip placement="right" title={row?.field_name}>
                      <Typography sx={style}>{row.field_name}</Typography>
                    </HtmlTooltip>
                  </StyledTableCell>
                  <StyledTableCell onClick={() => updateSharedState(row)}>
                    {row.field_area}
                  </StyledTableCell>
                  <StyledTableCell onClick={() => updateSharedState(row)}>
                    {row?.price}
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography sx={{ display: 'inline', fontSize: 13 }}>
                      {row?.compliance ? 'No' : 'Yes'}
                    </Typography>
                    <ChatBubbleOutlineIcon
                      sx={{
                        color: !row.field_id
                          ? '#d2d2d2'
                          : row?.comments && row?.comments.length > 0
                            ? '#FF3162'
                            : '#0B617F',
                        float: 'right',
                        cursor: !row.field_id ? 'not-allowed' : 'pointer',
                      }}
                      onClick={() => {
                        if (row.field_id) {
                          setSelectedField({
                            compliance: row.compliance,
                            id: row?.field_id,
                            field_name: row?.field_name,
                            comments: row?.comments,
                          });
                          setOpenComment(true);
                        }
                      }}
                    />
                  </StyledTableCell>
                </TableRow>
              ))}
              {tableData.length > 0 && (
                <TableRow sx={{ backgroundColor: '#E8F1F7' }}>
                  <StyledTableCell colSpan={userDetails?.role === 200 ? 7 : 6} />
                  <StyledTableCell>
                    <b>{uniqueNames(tableData).length}</b>
                  </StyledTableCell>
                  <StyledTableCell>
                    <b>{aggregate.totalFields}</b>
                  </StyledTableCell>
                  <StyledTableCell>
                    <b>{aggregate.hectares.toFixed(2)}</b>
                  </StyledTableCell>
                  <StyledTableCell>
                    <b>{aggregate.price.toFixed(2)}</b>
                  </StyledTableCell>
                  <StyledTableCell />
                </TableRow>
              )}
            </TableBody>
          </Table>
          {loading && <CircularProgress />}
          {!loading && subscriptionList.length === 0 && (
            <NoDataAvailable text1={t('HOME.NO_DATA_NOTE')} text2={t('HOME.ADD_GROWER_TEXT')} />
          )}
        </TableContainer>
        {tableData.length === 0 && <TableNoResults />}
        <CustomTablePagination dataList={tableData} page={page} setPage={setPage} />
      </Box>
    </Box>
  );
};
export default SubscriptionTable;
