import axios from 'axios';

const Arvalis = new (class {
  getAuthToken() {
    const token = localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')) : null;
    return token;
  }
  headers() {
    return {
      'Content-Type': 'application/json',
      authorizationToken: `Bearer ${this.getAuthToken()}`,
    };
  }
  getVarietiesSoilTypes() {
    return axios
      .get(`/api/get_varieties_soil_types`, { headers: this.headers() })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  editBBCH30(field_name) {
    return axios
      .get(`/api/update_observations/${field_name}`, { headers: this.headers() })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
})();

export default Arvalis;
