import { useState } from 'react';

const useSnackbar = () => {
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState('info');
  const [alertMessage, setAlertMessage] = useState('');

  const success = (msg) => {
    setSeverity('success');
    setAlertMessage(msg);
    setOpen(true);
  };
  const error = (msg) => {
    setSeverity('error');
    setAlertMessage(msg);
    setOpen(true);
  };
  const info = (msg) => {
    setSeverity('info');
    setAlertMessage(msg);
    setOpen(true);
  };

  const snackbarProps = {
    alertMessage,
    open,
    setOpen,
    severity,
  };

  return {
    success,
    error,
    info,
    snackbarProps,
  };
};

export default useSnackbar;
