import React, { useState, useEffect } from 'react';
import Header from '../Header';
import { Box } from '@mui/material';
import CustomTable from '../Table';
import GrowerAPI from '../../api/grower';
import { useStateContext } from '../../utils/stateContext';
import SnackbarCustom from '../Snackbar';
import Agronomy from '../Agronomy';
import Drawer from '../Table/Drawer';
import { dateFormat } from '../../utils/commonFunctions';

const Admin = () => {
  const [isChecked, setIsChecked] = useState(false);

  const {
    t,
    navigate,
    subscriptionList,
    setSubscriptionList,
    selectedSalesPersons,
    setSelectedSalesPersons,
    setSelectedRows,
    setRows,
    setGrowerState,
    userDetails,
    setInitialState,
    defaultState,
    setSelectedIndexes,
    getData,
    snackbar: { error, success, snackbarProps },
    selectedField,
    setOpenComment,
    openComment,
  } = useStateContext();

  const columns = [
    {
      name: 'distributor',
      label: t('GENERIC.DISTRIBUTOR'),
      width: '9vw',
      color: '#0091DF',
      search: true,
    },
    {
      name: 'created_user_name',
      label: t('HOME.SALES_REP_NAME'),
      width: '13vw',
      color: '#0091DF',
      search: true,
    },
    {
      name: 'contact_name',
      label: t('SUBSCRIPTION.GROWER_NAME'),
      width: '6vw',
      color: '#0091DF',
      search: true,
    },
    { name: 'status', label: t('GENERIC.STATUS'), width: '6vw', color: '#0091DF', search: true },
    {
      name: 'start_date',
      label: t('GENERIC.START_DATE'),
      width: '6vw',
      color: '#0091DF',
      date: true,
      inputFormat: dateFormat,
    },
    { name: 'app_login', label: 'Login in the App', width: '6vw', color: '#0091DF', boolean: true },
    { name: 'name', label: t('GENERIC.FARM'), width: '7vw', color: '#007EC1', search: true },
    { name: 'field_name', label: t('GENERIC.FIELD'), width: '7vw', color: '#007EC1', search: true },
    { name: 'field_area', label: 'HA', width: '5vw', color: '#007EC1', search: true },
    {
      name: 'price',
      label: t('GENERIC.PRICE') + ' ' + t('GENERIC.IN_EUROS'),
      width: '5vw',
      color: '#007EC1',
      search: true,
    },
    {
      name: 'consent',
      label: 'Compliance',
      width: '7vw',
      color: '#007EC1',
      boolean: true,
      valueGetter: (rowData) => !!rowData?.compliance,
    },
  ];

  const columns1 = [
    { id: 'created_user_name', label: t('HOME.SALES_REP_NAME'), search: true },
    { id: 'contact_name', label: t('SUBSCRIPTION.GROWER_NAME'), search: true },
    { id: 'status', label: t('GENERIC.STATUS'), search: true },
    { id: 'start_date', label: t('GENERIC.START_DATE'), date: true, inputFormat: dateFormat },
    // { id: "contract", label: t('GENERIC.CONTRACT') },
    { id: 'name', label: t('GENERIC.FARM'), search: true },
    { id: 'field_name', label: t('GENERIC.FIELD'), search: true },
    { id: 'field_area', label: 'HA', search: true },
    { id: 'price', label: `${t('GENERIC.PRICE')} ${t('GENERIC.IN_EUROS')}`, search: true },
    {
      id: 'consent',
      label: 'Compliance',
      boolean: true,
      valueGetter: (rowData) => !!rowData?.compliance,
    },
  ];

  const deleteData = () => {
    if (selectedSalesPersons.length < 1) {
      error(t('ALERT.MSG5'));
    } else {
      GrowerAPI.deleteGrowers({ id: [...new Set(selectedSalesPersons)] }).then((response) => {
        if (response.status === 200) {
          success(t('ALERT.MSG6'));
          setSelectedSalesPersons([]);
          getData();
          setSubscriptionList([]);
          setTimeout(() => {
            navigate('/home');
          }, 1000);
        }
      });
    }
  };

  useEffect(() => {
    if (subscriptionList.length <= 0) {
      getData();
      setSelectedRows([]);
      setRows([]);
      setGrowerState({});
      setSelectedIndexes([]);
      setInitialState(defaultState);
      setSelectedSalesPersons([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ backgroundColor: '#E6E9ED', paddingBottom: '0.5vw', height: '90%' }}>
      <Header isChecked={isChecked} setIsChecked={setIsChecked} />
      <SnackbarCustom {...snackbarProps} />
      {userDetails?.role === 100 ? (
        <Box
          sx={{
            m: 2,
            display: 'flex',
            height: '83vh',
            width: '98%',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'white',
          }}
        >
          {t('ALERT.MSG19')}
        </Box>
      ) : !isChecked ? (
        <CustomTable
          title={t('GENERIC.LIST')}
          columns={userDetails?.role === 200 ? columns : columns1}
          deleteData={deleteData}
        />
      ) : (
        <Agronomy subscriptionList={subscriptionList.filter((x) => x.orders.length > 0)} />
      )}
      <Drawer
        openComment={openComment}
        setOpenComment={setOpenComment}
        field={selectedField}
        onPostComment={getData}
      />
    </Box>
  );
};
export default Admin;
