import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyDzamVtLsWrmw8MStWP83NtR_Hc2CfFkus',
  authDomain: 'predima-31603.firebaseapp.com',
  projectId: 'predima-31603',
  storageBucket: 'predima-31603.appspot.com',
  messagingSenderId: '471395284884',
  appId: '1:471395284884:web:8b2771bdbd7a98217dc181',
  measurementId: 'G-D84P840SXV',
};

const firebaseApp = initializeApp(firebaseConfig);
export const messaging = getMessaging(firebaseApp);

export const getToken1 = (setToken) => {
  return getToken(messaging, {
    vapidKey:
      'BEqQcghV2hrQDpC7w_SxauIaWJ9VCqC_BIqLPOf_5dc1Gd2ODzE45d-G_NqUGEx6UB-q3NrxNJBXh_PiotwwQ_A',
  })
    .then((currentToken) => {
      if (currentToken) {
        console.log('current token for client: ', currentToken);
        localStorage.setItem('firebase', JSON.stringify(currentToken));
        setToken(currentToken);
      } else {
        console.log('No registration token available. Request permission to generate one.');
        setToken(currentToken);
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
