import React, { createContext, useContext, useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import GrowerAPI from '../api/grower';
import useSnackbar from '../hooks/useSnackbar';
import useAgronomy from '../hooks/useAgronomy';

const StateContext = createContext();

export function StateProvider({ children }) {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  const [user, setUser] = useState(null);
  const [sharedState, setSharedState] = useState({});
  const [list, setList] = useState([]);
  const [subscriptionList, setSubscriptionList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [agronomyList, setAgronomyList] = useState([]);

  const [rows, setRows] = useState([]);

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedIndexes, setSelectedIndexes] = useState([]);
  const [selectedSalesPersons, setSelectedSalesPersons] = useState([]);
  const [growers, setGrowers] = useState([]);
  const [editable, setEditable] = useState(false);
  const [editFromGrower, setEditFromGrower] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);

  const [selectedField, setSelectedField] = useState({});
  const [openComment, setOpenComment] = useState(false);

  const [comments, setComments] = useState([]);

  const snackbar = useSnackbar();
  const agronomy = useAgronomy();

  const defaultState = {
    sharedState: {},
    selectedRows: [],
    selectedIndexes: [],
    growerState: {},
  };
  const getInitialState = () => {
    const data = localStorage.getItem('data');
    return data ? JSON.parse(data) : defaultState;
  };

  const [initialState, setInitialState] = useState(getInitialState);

  const getTotalArea = () => {
    let totalArea = 0;
    initialState?.selectedRows.forEach((row) => {
      totalArea += row.area;
    });
    return totalArea.toFixed(2);
  };

  const [growerState, setGrowerState] = useState({});
  const handleCheckboxClick = (row) => {
    const selectedIndex = selectedIndexes.indexOf(row.id);
    let newSelected = [];
    let newIndex = [];
    if (selectedIndex === -1) {
      if (selectedRows.length >= 15) {
        setOpen(true);
        setAlertMessage('Maximum 15 fields allowed');
        setSeverity('error');
        return null;
      }
      row.address = 'add';
      newSelected = [...selectedRows, row];
      newIndex = [...selectedIndexes, row.id];
    } else {
      row.address = 'sub';
      newSelected = [
        ...selectedRows.slice(0, selectedIndex),
        ...selectedRows.slice(selectedIndex + 1),
      ];
      newIndex = [
        ...selectedIndexes.slice(0, selectedIndex),
        ...selectedIndexes.slice(selectedIndex + 1),
      ];
    }
    setSelectedIndexes(newIndex);
    setSelectedRows(newSelected);
    setInitialState({ ...initialState, selectedRows: newSelected, selectedIndexes: newIndex });
  };

  const handleFieldsUpdate = (index) => (e) => {
    if (e.target.name === 'tillage') {
      const newArray = initialState?.selectedRows.map((item, i) => {
        if (index === i) {
          return { ...item, [e.target.name]: e.target.checked };
        } else {
          return item;
        }
      });
      setSelectedRows(newArray);
      setInitialState({ ...initialState, selectedRows: newArray });
    } else {
      const newArray = initialState?.selectedRows.map((item, i) => {
        if (index === i) {
          return { ...item, [e.target.name ? e.target.name : e.target.id]: e.target.value };
        } else {
          return item;
        }
      });
      setSelectedRows(newArray);
      setInitialState({ ...initialState, selectedRows: newArray });
    }
  };

  const handleDateUpdate = (newValue, index) => {
    const newArray = initialState?.selectedRows.map((item, i) => {
      if (index === i) {
        return { ...item, sowing_date: dayjs(newValue).format('DD-MM-YYYY') };
      } else {
        return item;
      }
    });
    setSelectedRows(newArray);
    setInitialState({ ...initialState, selectedRows: newArray });
  };

  const handleOptionChange = (index, newValue, name) => {
    const newArray = initialState?.selectedRows.map((item, i) => {
      if (index === i) {
        if (name === 'soil') {
          return { ...item, soil_type: newValue?.nom_arvalis };
        }

        if (name === 'region') {
          return { ...item, [name]: newValue, soil_type: '' };
        } else {
          return { ...item, [name]: newValue };
        }
      } else {
        return item;
      }
    });
    setSelectedRows(newArray);
    setInitialState({ ...initialState, selectedRows: newArray });
  };

  const handleGrowerUpdate = (e) => {
    setGrowerState((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
    setInitialState({
      ...initialState,
      growerState: { ...growerState, [e.target.name]: e.target.value },
    });
  };
  const handleSalesRepCheckboxClick = (id) => {
    const selectedIndex = selectedSalesPersons.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = [...selectedSalesPersons, id];
    } else {
      newSelected = [
        ...selectedSalesPersons.slice(0, selectedIndex),
        ...selectedSalesPersons.slice(selectedIndex + 1),
      ];
    }

    setSelectedSalesPersons(newSelected);
  };

  const handleGrowerCheckboxClick = (id) => {
    const selectedIndex = growers.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = [...growers, id];
    } else {
      newSelected = [...growers.slice(0, selectedIndex), ...growers.slice(selectedIndex + 1)];
    }

    setGrowers(newSelected);
  };

  const login = (user) => {
    setUser(user);
  };

  const logout = () => {
    setUser(null);
    localStorage.clear();
    sessionStorage.clear();
    setSubscriptionList([]);
    navigate('/');
  };

  const updateSharedState = async (newState) => {
    if (newState?.orders.length > 0) {
      await setSharedState({ ...sharedState, ...newState });
      await setInitialState({ ...initialState, sharedState: { ...newState } });
      await navigate('/home/growerOverview');
      return;
    } else {
      await setSharedState({ ...sharedState, ...newState });
      await setInitialState({ ...initialState, sharedState: { ...newState } });
      await navigate('/home/growerdetail');
    }
  };

  function validateMandatoryProperties(array, mandatoryProperties) {
    return array.reduce((isValid, obj) => {
      if (!isValid) {
        return false;
      }
      for (const prop of mandatoryProperties) {
        if (obj[prop] === null || obj[prop] === undefined || obj[prop] === '') {
          return false;
        }
      }
      return true;
    }, true);
  }

  const getCurrentValue = (array, value) => {
    return array.find((x) => x.bbch_code === value);
  };

  const getRisk = (array) => {
    let date2 = dayjs(new Date()).format('YYYY-MM-DD');
    const properties = ['septoriose', 'rouillebrune', 'rouillejaune', 'fusariose'];
    const highestValues = {};

    properties.forEach((property) => {
      const values = array.map((entry) => {
        return dayjs(entry.date).isAfter(date2) ? entry[property] : 0;
      });
      const maxValue = Math.max(...values);
      highestValues[property] = maxValue;
    });
    highestValues['global_risk'] = Math.max(...Object.values(highestValues));
    return highestValues;
  };
  const getRiskValues = (array) => {
    let result = array.reduce((acc, item) => {
      ['septoriose', 'rouillebrune', 'rouillejaune', 'fusariose', 'global_risk'].forEach((key) => {
        if (key === 'global_risk') {
          acc[key] = acc[key] || [];
          acc[key].push({
            date: item.date,
            value: Math.max(...Object.values(item).filter((x) => !isNaN(x))),
          });
        } else {
          acc[key] = acc[key] || [];
          acc[key].push({ date: item.date, value: item[key] });
        }
      });
      return acc;
    }, {});
    return result;
  };
  const getTreatments = (array) => {
    return array.reduce((acc, init) => {
      return { ...acc, [`${init.t_window}`]: init };
    }, {});
  };
  const getTreatments1 = (array) => {
    return array.reduce((acc, init) => {
      return {
        ...acc,
        [`${init.pd_window}id`]: init.id,
        [`${init.pd_window}id`]: init.id,
        [`${init.pd_window}pd_window`]: init.pd_window,
        [`${init.pd_window}product`]: init.product,
        [`${init.pd_window}dosage`]: init.dosage,
        [`${init.pd_window}sent`]: init.sent,
      };
    }, {});
  };
  const getTreatments2 = (spray_dates) => {
    return spray_dates.reduce((acc, init) => {
      return { ...acc, [init.spray_window]: init };
    }, {});
  };
  const getTreatments3 = (spray_dates) => {
    let rec_spray_date = spray_dates.find((x) => {
      if (x.spray_window === 'T1') {
        return x.rec_spray_date;
      } else if (x.spray_window === 'T2') {
        return x.rec_spray_date;
      } else if (x.spray_window === 'T3') {
        return x.rec_spray_date;
      }
      return '';
    });
    return rec_spray_date?.rec_spray_date ? rec_spray_date?.rec_spray_date : null;
  };

  const getData = async () => {
    setLoading(true);
    await GrowerAPI.getAllGrowers({
      role: userDetails.role,
      distributor: userDetails.distributor_id,
    })
      .then((list) => {
        setList(list);
        setLoading(false);
      })
      .finally(() => {
        //setLoading(false)
      });
  };

  useMemo(() => {
    let init = [];
    let prodList = [];
    list.forEach((item) => {
      item?.orders.length > 0 && item?.orders[0]?.order_fields
        ? item?.orders[0]?.order_fields.forEach((field) => {
            init.push({
              ...item,
              created_user_name: item?.created_user?.name,
              invite_sent: item?.invite_sent,
              distributor: item?.created_user?.distributor?.name,
              contract: item.contract ? item.contract : 'Ongoing',
              status: item.status ? item.status : 'Ongoing',
              field_name: field.field_name,
              field_area: field.area,
              price: field.area * 59,
              observation_date: field?.observation_date
                ? dayjs(field.observation_date, 'YYYY-MM-DD')
                : null,
              bbch: getCurrentValue(field?.growth_stages, field?.current_bbch_stage),
              current_bbch_stage: field?.current_bbch_stage,
              legend: field?.risk.length > 0 ? getRiskValues(field?.risk) : {},
              risk: field?.risk.length > 0 ? getRisk(field?.risk) : {},
              alert_date: field?.spray_dates[0]?.alert_date,
              rec_spray_date1:
                field?.spray_dates.length > 0 ? getTreatments3(field?.spray_dates) : null,
              rec_spray_date:
                field?.spray_dates.length > 0 ? getTreatments2(field?.spray_dates) : null,
              treatments: field?.application.length > 0 ? getTreatments(field?.application) : {},
              compliance: field?.compliance,
              field_id: field?.field_id,
              comments: field?.compliance_comments,
            });
          })
        : init.push({
            ...item,
            created_user_name: item?.created_user?.name,
            distributor: item?.created_user?.distributor?.name,
            contract: item.contract ? item.contract : 'Ongoing',
            status: item.status ? item.status : 'Ongoing',
            field_name: '',
            field_area: 0,
            price: 0,
            observation_date: '',
            risk: {},
            current_bbch_stage: '',
            bbch: '',
          });
      if (item?.orders[0]?.order_fields) {
        prodList.push({
          created_user_name: item?.created_user?.name,
          grower_name: item?.contact_name,
          farm_name: item?.name,
          total_area: item?.orders[0]?.order_total_ha,
          order_id: item?.orders[0]?.id,
          ...getTreatments1(item?.orders[0]?.product_delivery),
        });
      }
    });

    setLoading(false);
    setSubscriptionList(init);
    setProductList(prodList);
  }, [list]);

  const userData = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
  const userDetails = localStorage.getItem('userDetails')
    ? JSON.parse(localStorage.getItem('userDetails'))
    : null;
  const token = localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')) : null;
  const local = localStorage.getItem('locale') ? JSON.parse(localStorage.getItem('locale')) : null;
  const [editFromReview, setEditFromReview] = useState(false);

  useEffect(() => {
    localStorage.setItem('data', JSON.stringify(initialState));
  }, [initialState]);

  return (
    <StateContext.Provider
      value={{
        t,
        local,
        navigate,
        sharedState,
        updateSharedState,
        list,
        setList,
        subscriptionList,
        setSubscriptionList,
        productList,
        setProductList,
        user,
        setUser,
        userData,
        userDetails,
        login,
        logout,
        rows,
        setRows,
        selectedRows,
        setSelectedRows,
        handleCheckboxClick,
        getTotalArea,
        selectedSalesPersons,
        setSelectedSalesPersons,
        handleSalesRepCheckboxClick,
        handleFieldsUpdate,
        growerState,
        setGrowerState,
        handleGrowerUpdate,
        handleDateUpdate,
        handleOptionChange,
        editable,
        setEditable,
        editFromGrower,
        setEditFromGrower,
        open,
        setOpen,
        severity,
        setSeverity,
        alertMessage,
        setAlertMessage,
        validateMandatoryProperties,
        editFromReview,
        setEditFromReview,
        token,
        initialState,
        setInitialState,
        defaultState,
        setSelectedIndexes,
        growers,
        setGrowers,
        handleGrowerCheckboxClick,
        agronomyList,
        setAgronomyList,
        notifications,
        setNotifications,
        rowsPerPage,
        setRowsPerPage,
        getData,
        loading,
        selectedField,
        setSelectedField,
        openComment,
        setOpenComment,
        snackbar,
        comments,
        setComments,
        agronomy,
      }}
    >
      {children}
    </StateContext.Provider>
  );
}

export function useStateContext() {
  return useContext(StateContext);
}
