import { Typography, Select, MenuItem } from '@mui/material';
import React from 'react';

const BooleanFilter = ({ onChange, value = 'All' }) => {
  return (
    <Select sx={{ display: 'flex', flex: 1, height: 30 }} value={value} onChange={onChange}>
      <MenuItem value="All" sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography sx={{ fontSize: '0.7em', textAlign: 'center' }}>All</Typography>
      </MenuItem>
      <MenuItem value={'true'} sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography sx={{ fontSize: '0.7em', textAlign: 'center' }}>Yes</Typography>
      </MenuItem>
      <MenuItem value={'false'} sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography sx={{ fontSize: '0.7em', textAlign: 'center' }}>No</Typography>
      </MenuItem>
    </Select>
  );
};

export default BooleanFilter;
