import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Grid,
  OutlinedInput,
  InputAdornment,
  Icon,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddFormImage from '../../images/Addform2x.png';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import HouseOutlinedIcon from '@mui/icons-material/HouseOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import * as Yup from 'yup';
import House from '../../images/house.png';
import GrowerAPI from '../../api/grower';
import { DateComponent } from '../../utils/commonFunctions';
import SnackbarCustom from '../Snackbar';
import { useStateContext } from '../../utils/stateContext';

import Header from '../Header';
const AddForm = () => {
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  const {
    t,
    navigate,
    getData,
    setSubscriptionList,
    initialState,
    setInitialState,
    editable,
    userDetails,
    setEditable,
    editFromReview,
    setEditFromReview,
  } = useStateContext();
  const { sharedState } = initialState;
  const phoneNumberRules = /^(?:(?:\+|00)33|0)[67]\d{8}$/;

  const validationSchema = Yup.object().shape({
    start_date: Yup.date().required(t('ADDFORM.DATE')),
    consent: Yup.boolean().oneOf([true], 'Please accept terms and conditions to register'),
    name: Yup.string().required(t('ADDFORM.FARM_NAME')),
    contact_name: Yup.string().required(t('ADDFORM.NAME')),
    mobile_no: Yup.string()
      .matches(phoneNumberRules, { message: t('ADDFORM.MOBILE') })
      .required('Phone Number Required !'),
    email: Yup.string().required(t('REGISTER.ERR_EMAIL')).email(t('REGISTER.ERR_EMAIL_INVALID')),
    telephone_no: Yup.string().matches(/^(\d{10})?$/, {
      message: 'Enter valid phone number',
      excludeEmptyString: true,
    }),
    address1: Yup.string().required(t('ADDFORM.ADDRESS')),
    city1: Yup.string().required(t('ADDFORM.CITY')),
    postal_code1: Yup.string()
      .required(t('ADDFORM.POSTAL_CODE'))
      .matches(/^(\d{5})?$/, {
        message: t('ADDFORM.POSTAL_CODE_INVALID'),
        excludeEmptyString: true,
      }),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
  } = useForm({ resolver: yupResolver(validationSchema) });

  useEffect(() => {
    if (editable || editFromReview) {
      setValue('start_date', dayjs(sharedState.start_date, 'DD-MM-YYYY'));
      setValue('name', sharedState.name);
      setValue('contact_name', sharedState.contact_name);
      setValue('mobile_no', sharedState.mobile_no);
      setValue('email', sharedState.email);
      setValue('telephone_no', sharedState.telephone_no);
      setValue('address1', sharedState.address1);
      setValue('city1', sharedState.city1);
      setValue('postal_code1', sharedState.postal_code1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editable, editFromReview]);

  const submit = (data) => {
    let body = {};
    if (editable || editFromReview) {
      body = {
        ...data,
        start_date: dayjs(data.start_date).format('DD-MM-YYYY'),
        uuid: sharedState?.uuid,
      };
      return GrowerAPI.updateGrower(body).then((response) => {
        if (response?.status === 200 || response?.status === 201) {
          if (editFromReview) {
            setInitialState({ ...initialState, sharedState: { ...response.grower } });
          }
          setOpen(true);
          setAlertMessage(t('ALERT.MSG1'));
          setSeverity('success');
          setTimeout(() => {
            if (editFromReview) {
              navigate(`/home/reviewdetails`);
            } else {
              navigate(`/home`);
            }
            setEditable(false);
            setEditFromReview(false);
          }, 3000);
        } else {
          setOpen(true);
          setAlertMessage(t('ALERT.MSG2'));
          setSeverity('error');
          setTimeout(() => {
            setEditable(false);
            navigate('/home');
          }, 3000);
        }
      });
    } else {
      body = {
        ...data,
        start_date: dayjs(data.start_date).format('DD-MM-YYYY'),
        created_by: userDetails.id,
      };
      return GrowerAPI.addGrower(body).then((response) => {
        if (response?.status === 200 || response?.status === 201) {
          setOpen(true);
          setAlertMessage(t('ALERT.MSG3'));
          setSeverity('success');
          setSubscriptionList([]);
          getData();
          setTimeout(() => {
            navigate('/home');
          }, 2000);
        } else {
          setOpen(true);
          setAlertMessage(`${t('ALERT.MSG4')} ${getValues('email')}`);
          setSeverity('error');
        }
      });
    }
  };

  return (
    <Box>
      <Header isToggle={true} />
      <SnackbarCustom
        open={open}
        setOpen={setOpen}
        alertMessage={alertMessage}
        severity={severity}
      />
      <Box
        sx={{
          bgcolor: '#fff',
          border: '2px solid #E6E9ED',
          borderRadius: '5px',
          margin: '1vw',
          width: 'auto',
          height: 'auto',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: '1vw',
            borderBottom: '3px solid #DDDDDD',
            padding: '0.5vw 0.5vw',
          }}
        >
          <ArrowBackIcon
            fontSize="small"
            onClick={() => {
              if (editable) {
                navigate(-1);
                setEditable(false);
              } else {
                navigate(`/home`);
              }
            }}
          />
          <p style={{ fontsize: '10px' }}>{t('HOME.ADD_GROWER')}</p>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ width: '40%', height: '100%', mt: 2 }}>
            <img src={AddFormImage} alt="AddForm Icon" style={{ width: '100%', height: '100%' }} />
          </Box>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit(submit)}
            sx={{ height: '100%', width: '60%', padding: '1vw 2vw 3vw 3vw' }}
          >
            <Typography sx={{ textTransform: 'uppercase', fontWeight: '550', color: '#0B617F' }}>
              {t('FIRSTMEETING.TEXT')}
            </Typography>
            <Typography variant="body2">{t('FIRSTMEETING.NOTE')}</Typography>
            <div>
              <Grid container rowSpacing={2} columnSpacing={2} sx={{ padding: '2vw 0vw' }}>
                <Grid item xs={8} sm={5}>
                  <DateComponent
                    name="start_date"
                    label={t('GENERIC.START_DATE') + '*'}
                    control={control}
                    errors={errors}
                    disabled={editable}
                  />
                  <p style={{ color: 'red', fontSize: '12px' }}>{errors.start_date?.message}</p>
                </Grid>
                <Grid item xs={8} sm={5}></Grid>
                <Grid item xs={8} sm={5}>
                  <FormControl variant="outlined" size="small" fullWidth>
                    <InputLabel htmlFor="outlined-adornment-farmName">
                      {t('GENERIC.FARM') + '*'}
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-farmName"
                      type="text"
                      name="name"
                      disabled={editable}
                      endAdornment={
                        <InputAdornment position="end">
                          <Icon color="primary" edge="end">
                            <HouseOutlinedIcon />
                          </Icon>
                        </InputAdornment>
                      }
                      label={t('GENERIC.FARM')}
                      {...register('name')}
                      error={errors.name ? true : false}
                    />
                    <span style={{ color: 'red', fontSize: '12px' }}>{errors.name?.message}</span>
                  </FormControl>
                </Grid>
                <Grid item xs={8} sm={5}>
                  <FormControl variant="outlined" size="small" fullWidth>
                    <InputLabel htmlFor="outlined-adornment-contactName">
                      {t('GENERIC.CONTACT') + '*'}
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-contactName"
                      type="text"
                      name="contact_name"
                      endAdornment={
                        <InputAdornment position="end">
                          <Icon color="primary" edge="end">
                            <PersonOutlinedIcon />
                          </Icon>
                        </InputAdornment>
                      }
                      label={t('GENERIC.CONTACT')}
                      {...register('contact_name')}
                      error={errors.contact_name ? true : false}
                    />
                    <span style={{ color: 'red', fontSize: '12px' }}>
                      {errors.contact_name?.message}
                    </span>
                  </FormControl>
                </Grid>
                <Grid item xs={8} sm={5}>
                  <FormControl variant="outlined" size="small" fullWidth>
                    <InputLabel htmlFor="outlined-adornment-mobilenum">
                      {t('GENERIC.MOBILE_NO') + '*'}
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-mobilenum"
                      type="text"
                      name="mobile_no"
                      endAdornment={
                        <InputAdornment position="end">
                          <Icon color="primary" edge="end">
                            <PhoneAndroidOutlinedIcon />
                          </Icon>
                        </InputAdornment>
                      }
                      placeholder="+33 X XX XX XX XX"
                      label={t('GENERIC.MOBILE_NO')}
                      {...register('mobile_no')}
                      error={errors.mobile_no ? true : false}
                    />
                    <span style={{ color: 'red', fontSize: '12px' }}>
                      {errors.mobile_no?.message}
                    </span>
                  </FormControl>
                </Grid>
                <Grid item xs={8} sm={5}>
                  <FormControl variant="outlined" size="small" fullWidth>
                    <InputLabel htmlFor="outlined-adornment-email">
                      {`${t('REGISTRATION.EMAIL')} (FieldView)*`}
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-email"
                      type="text"
                      name="email"
                      disabled={editable}
                      endAdornment={
                        <InputAdornment position="end">
                          <Icon color="primary" edge="end">
                            <EmailOutlinedIcon />
                          </Icon>
                        </InputAdornment>
                      }
                      label={`${t('REGISTRATION.EMAIL')} (FieldView)`}
                      {...register('email')}
                      error={errors.email ? true : false}
                    />
                    <span style={{ color: 'red', fontSize: '12px' }}>{errors.email?.message}</span>
                  </FormControl>
                </Grid>
                <Grid item xs={8} sm={5}>
                  <FormControl variant="outlined" size="small" fullWidth>
                    <InputLabel htmlFor="outlined-adornment-telephone">
                      {t('GENERIC.TELEPHONE')}
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-telephone"
                      type="text"
                      name="telephone_no"
                      endAdornment={
                        <InputAdornment position="end">
                          <Icon color="primary" edge="end">
                            <PhoneOutlinedIcon />
                          </Icon>
                        </InputAdornment>
                      }
                      label={t('GENERIC.TELEPHONE')}
                      {...register('telephone_no')}
                      error={errors.telephone_no ? true : false}
                    />
                    <span style={{ color: 'red', fontSize: '12px' }}>
                      {errors.telephone_no?.message}
                    </span>
                  </FormControl>
                </Grid>
              </Grid>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  gap: '1vw',
                }}
              >
                <img src={House} alt="" />
                <Typography sx={{ fontWeight: '550', color: '#0B617F' }}>
                  {t('GENERIC.ADDRESS')}
                </Typography>
                <hr style={{ border: '0.1px solid #0B617F', width: '70%' }} />
              </Box>
              <Grid container rowSpacing={2} columnSpacing={2} sx={{ padding: '2vw 0vw' }}>
                <Grid item xs={8} sm={5}>
                  <FormControl variant="outlined" size="small" fullWidth>
                    <InputLabel htmlFor="outlined-adornment-farmAddress">
                      {t('FIRSTMEETING.ADDRESS') + '*'}
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-farmAddress"
                      type="text"
                      name="address1"
                      endAdornment={
                        <InputAdornment position="end">
                          <Icon color="primary" edge="end"></Icon>
                        </InputAdornment>
                      }
                      label={t('FIRSTMEETING.ADDRESS')}
                      {...register('address1')}
                      error={errors.address1 ? true : false}
                    />
                    <span style={{ color: 'red', fontSize: '12px' }}>
                      {errors.address1?.message}
                    </span>
                  </FormControl>
                </Grid>
                <Grid item xs={8} sm={5}>
                  <FormControl variant="outlined" size="small" fullWidth>
                    <InputLabel htmlFor="outlined-adornment-farmCity">
                      {t('FIRSTMEETING.CITY') + '*'}
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-farmCity"
                      type="text"
                      name="city1"
                      endAdornment={
                        <InputAdornment position="end">
                          <Icon color="primary" edge="end"></Icon>
                        </InputAdornment>
                      }
                      label={t('FIRSTMEETING.CITY')}
                      {...register('city1')}
                      error={errors.city1 ? true : false}
                    />
                    <span style={{ color: 'red', fontSize: '12px' }}>{errors.city1?.message}</span>
                  </FormControl>
                </Grid>
                <Grid item xs={8} sm={5}>
                  <FormControl variant="outlined" size="small" fullWidth>
                    <InputLabel htmlFor="outlined-adornment-postalCode">
                      {t('FIRSTMEETING.POSTAL_CODE') + '*'}
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-postalCode"
                      type="text"
                      name="postal_code1"
                      endAdornment={
                        <InputAdornment position="end">
                          <Icon color="primary" edge="end"></Icon>
                        </InputAdornment>
                      }
                      label={t('FIRSTMEETING.POSTAL_CODE')}
                      {...register('postal_code1')}
                      error={errors.postal_code1 ? true : false}
                    />
                    <span style={{ color: 'red', fontSize: '12px' }}>
                      {errors.postal_code1?.message}
                    </span>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container justifyContent="center">
                <Button type="submit" sx={{ width: 250, height: 40, mt: 2 }} variant="contained">
                  {editable ? t('GENERIC.UPDATE') : t('GENERIC.SAVE')}
                </Button>
              </Grid>
            </div>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default AddForm;
