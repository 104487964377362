import { useMemo } from 'react';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import TableCell from '@mui/material/TableCell';
import React, { useState } from 'react';
import useSortedList from '../../hooks/useSortedList';
import useFilteredList from '../../hooks/useFilteredList';
import ResetMail from '../../images/resetMail.png';
import CustomTablePagination from '../../utils/CustomTablePagination';
import {
  BpCheckbox,
  HtmlTooltip,
  NoDataAvailable,
  StyledTableCell1,
  formatDate,
  YYYYMMDD,
} from '../../utils/commonFunctions';
import FilterBar from '../Table/FilterBar';
import { useStateContext } from '../../utils/stateContext';
import CircularProgress from '../CircularProgress';
import Dot from '../Layout/Dot';
import TableNoResults from '../Layout/TableNoResults';
import HeaderRow from '../Table/HeaderRow';
import './agronomy.css';

const Card = ({ riskValues }) => {
  if (!riskValues) return null;
  return (
    <Box sx={{ padding: 1 }}>
      {riskValues?.map((item, index) => (
        <Typography sx={{ fontWeight: 500 }} key={index}>
          {formatDate(item?.date, { inputFormat: YYYYMMDD })}{' '}
          <Dot color={item?.value} size="0.5em" />
        </Typography>
      ))}
    </Box>
  );
};

const getTwindowValue = (row) => {
  const treatments = ['T3', 'T2', 'T1'];
  for (let t of treatments) {
    if (row.treatments[t]) {
      return t;
    }
  }
  return null;
};

const Summary = ({ subscriptionList }) => {
  const {
    t,
    growers,
    setGrowers,
    handleGrowerCheckboxClick,
    updateSharedState,
    loading,
    agronomy: {
      selectedField: [, setSelectedField],
      BBCHModal: [, { on: openBBCHModal }],
      ActualSprayDateModal: [, { on: openActualSprayDateModal }],
      SearchBar: [isSearchBarOpen],
    },
  } = useStateContext();

  const columns = useMemo(() => {
    return [
      { name: 'distributor', label: t('GENERIC.DISTRIBUTOR'), search: true, width: '7%' },
      { name: 'created_user_name', label: t('AGRONOMY.SALES_REP_NAME'), search: true, width: '7%' },
      { name: 'contact_name', label: t('SUBSCRIPTION.GROWER_NAME'), search: true, width: '7%' },
      { name: 'name', label: t('GENERIC.FARM'), search: true, width: '7%' },
      { name: 'global_risk', label: t('AGRONOMY.GLOBAL_RISK'), risk: true, width: '7%' },
      { name: 'field_name', label: t('GENERIC.FIELD'), search: true, width: '7%' },
      {
        name: 'bbch',
        label: 'BBCH',
        search: true,
        width: '7%',
        valueGetter: (dataRow) => dataRow?.current_bbch_stage?.replace('Z', '').toLowerCase(),
      },
      { name: 'observation_date', label: 'BBCH 30', date: true, width: '7%' },
      { name: 'sowing_date', label: 'Sowing date', date: true, width: '7%' },
      {
        name: 'septoriose',
        label: t('AGRONOMY.SEPTORIA'),
        risk: true,
        width: '7%',
        valueGetter: (rowData) => rowData.risk.septoriose,
      },
      {
        name: 'rouillebrune',
        label: t('AGRONOMY.BROWN_RUST'),
        risk: true,
        width: '7%',
        valueGetter: (rowData) => rowData.risk.rouillebrune,
      },
      {
        name: 'rouillejaune',
        label: t('AGRONOMY.YELLOW_RUST'),
        risk: true,
        width: '7%',
        valueGetter: (rowData) => rowData.risk.rouillejaune,
      },
      {
        name: 'fusariose',
        label: t('AGRONOMY.FUSARIUM'),
        risk: true,
        width: '7%',
        valueGetter: (rowData) => rowData.risk.fusariose,
      },
      {
        name: 'treatment_window',
        label: t('ADD_PRODUCT.T_WINDOW'),
        treatmentFiter: true,
        width: '7%',
        valueGetter: (rowData) => getTwindowValue(rowData),
      },
      { name: 'alert_date', label: t('AGRONOMY.ALERT_DATE'), date: true, width: '7%' },
      {
        name: 'rec_spray_date1',
        label: t('AGRONOMY.REC_DATE'),
        date: true,
        width: '7%',
        inputFormat: YYYYMMDD,
      },
      {
        name: 'treatment_date',
        label: t('AGRONOMY.ACT_DATE'),
        date: true,
        width: '7%',
        valueGetter: (rowData) =>
          rowData?.treatments?.T3 || rowData?.treatments?.T2 || rowData?.treatments.T1,
      },
    ];
  }, [t]);

  const [page, setPage] = useState(0);
  const { sortedData, ...sortingProps } = useSortedList(subscriptionList, columns);
  const { filteredData: tableData, ...filterProps } = useFilteredList(sortedData, columns);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = tableData.map((n) => n.email);
      setGrowers(newSelected);
      return;
    }
    setGrowers([]);
  };

  const handleEditActualSprayDate = (row) => {
    setSelectedField({
      field_id: row?.field_id,
      fieldName: row.field_name,
      treatments: row.treatments,
    });
    openActualSprayDateModal();
  };

  const handleEditBBCH = (row) => {
    setSelectedField({ field_id: row?.field_id, fieldName: row?.field_name });
    openBBCHModal();
  };

  return (
    <Box sx={{ width: '100%' }}>
      <TableContainer sx={{ maxHeight: 600 }}>
        <Table stickyHeader aria-label="sticky table" sx={{ width: '120%' }}>
          <TableHead sx={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 2 }}>
            <HeaderRow
              {...sortingProps}
              columns={columns}
              onSelectAll={handleSelectAllClick}
              checked={growers.length === tableData.length}
            />
            <FilterBar {...filterProps} isOpen={isSearchBarOpen} columns={columns} />
          </TableHead>
          <TableBody>
            {tableData.map((row, index) => (
              <TableRow key={index} sx={{ '&:hover': { backgroundColor: '#BEDCE7' } }}>
                <TableCell padding="checkbox">
                  <BpCheckbox
                    color="primary"
                    checked={growers.indexOf(row.email) !== -1}
                    onChange={() => handleGrowerCheckboxClick(row.email)}
                  />
                </TableCell>
                <StyledTableCell1 align="center">{row?.distributor}</StyledTableCell1>
                <StyledTableCell1 align="center">{row?.created_user_name}</StyledTableCell1>
                <StyledTableCell1 align="center">{row.contact_name}</StyledTableCell1>
                <StyledTableCell1 align="left">
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      p: '0vw 2px',
                    }}
                  >
                    <Typography
                      variant="body2"
                      fontSize="1em"
                      onClick={() => updateSharedState(row)}
                    >
                      {row.name}
                    </Typography>
                    {row.invite_sent && (
                      <img
                        src={ResetMail}
                        style={{ width: '1.8em', height: '1.5em' }}
                        alt={t('AGRONOMY.RESET_EMAIL')}
                      />
                    )}
                  </Box>
                </StyledTableCell1>
                <StyledTableCell1 align="center">
                  <HtmlTooltip title={<Card riskValues={row.legend && row.legend?.global_risk} />}>
                    <Box>
                      <Dot color={row.risk && row.risk?.global_risk} size="0.7em" />
                    </Box>
                  </HtmlTooltip>
                </StyledTableCell1>
                <StyledTableCell1 align="center">{row.field_name}</StyledTableCell1>
                <StyledTableCell1 align="center" className="tooltip">
                  {row.current_bbch_stage ? row.current_bbch_stage.replace('Z', '') : '--'}
                  {row.bbch ? (
                    <Paper className="tooltiptext">
                      <Box sx={{ padding: '0vw 0.3vw' }}>
                        <Typography variant="body2" fontSize="1em">
                          <b>Start</b>
                          <br />
                          {row.bbch?.start_date}
                        </Typography>
                        <Typography variant="body2" fontSize="1em">
                          <b>End</b>
                          <br />
                          {row?.bbch?.end_date}
                        </Typography>
                      </Box>
                    </Paper>
                  ) : null}
                </StyledTableCell1>
                <StyledTableCell1 align="center" className="tooltip1">
                  {row.observation_date ? formatDate(row.observation_date) : '--'}
                  <EditOutlinedIcon
                    color="primary"
                    fontSize={'small'}
                    className="tooltiptext1"
                    onClick={() => handleEditBBCH(row)}
                  />
                </StyledTableCell1>
                <StyledTableCell1>{row?.sowing_date}</StyledTableCell1>
                <StyledTableCell1 align="center">
                  <HtmlTooltip title={<Card riskValues={row.legend && row.legend?.septoriose} />}>
                    <Box>
                      <Dot color={row.risk && row.risk?.septoriose} size="0.7em" />
                    </Box>
                  </HtmlTooltip>
                </StyledTableCell1>
                <StyledTableCell1 align="center">
                  <HtmlTooltip title={<Card riskValues={row.legend && row.legend?.rouillebrune} />}>
                    <Box>
                      <Dot color={row.risk && row.risk?.rouillebrune} size="0.7em" />
                    </Box>
                  </HtmlTooltip>
                </StyledTableCell1>
                <StyledTableCell1 align="center">
                  <HtmlTooltip title={<Card riskValues={row.legend && row.legend?.rouillejaune} />}>
                    <Box>
                      <Dot color={row.risk && row.risk?.rouillejaune} size="0.7em" />
                    </Box>
                  </HtmlTooltip>
                </StyledTableCell1>
                <StyledTableCell1 align="center">
                  <HtmlTooltip title={<Card riskValues={row.legend && row.legend?.fusariose} />}>
                    <Box>
                      <Dot color={row.risk && row.risk?.fusariose} size="0.7em" />
                    </Box>
                  </HtmlTooltip>
                </StyledTableCell1>
                <StyledTableCell1 align="center">{getTwindowValue(row)}</StyledTableCell1>
                <StyledTableCell1 align="center">
                  {row.alert_date ? formatDate(row.alert_date, { inputFormat: YYYYMMDD }) : '--'}
                </StyledTableCell1>
                <StyledTableCell1 align="center">
                  {row.rec_spray_date1
                    ? formatDate(row.rec_spray_date1, { inputFormat: YYYYMMDD })
                    : '--'}
                </StyledTableCell1>
                <StyledTableCell1 align="center" className="tooltip1">
                  {row.treatments &&
                  (row.treatments['T3'] || row.treatments['T2'] || row.treatments['T1'])
                    ? formatDate(
                        (row.treatments['T3'] || row.treatments['T2'] || row.treatments['T1'])
                          .treatment_date,
                        { inputFormat: YYYYMMDD }
                      )
                    : '--'}
                  <EditOutlinedIcon
                    className="tooltiptext1"
                    color="primary"
                    fontSize="small"
                    onClick={() => handleEditActualSprayDate(row)}
                  />
                </StyledTableCell1>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {tableData.length === 0 && <TableNoResults />}
      <CustomTablePagination dataList={tableData} page={page} setPage={setPage} />
      {loading ? <CircularProgress /> : null}
      {!loading && tableData.length === 0 ? (
        <NoDataAvailable text1={t('HOME.NO_DATA_NOTE')} text2={t('HOME.ADD_GROWER_TEXT')} />
      ) : null}
    </Box>
  );
};
export default Summary;
