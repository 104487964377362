import React, { useState, useEffect } from 'react';
import CustomModal from '../CustomModal';
import {
  Grid,
  Box,
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  Typography,
} from '@mui/material';
import { useStateContext } from '../../utils/stateContext';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { DateComponent } from '../../utils/commonFunctions';
import GrowerAPI from '../../api/grower';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';

const randomNumber = () => {
  return Math.floor(Math.random() * 100);
};
const Addproduct = ({ openProduct, setOpenProduct, product, setProduct, edit, setEdit }) => {
  const {
    t,
    getData,
    snackbar: { error, success },
  } = useStateContext();
  let initial = { id: randomNumber(), product: '', dosage: '' };
  const [products, setProducts] = useState([{ ...initial }]);
  const [pd_window, setPDWindow] = useState('');

  const handleChange = (index, key, value) => {
    const newProducts = [...products];
    newProducts[index][key] = key === 'dosage' ? value.toString().replaceAll(',', '.') : value;
    setProducts(newProducts);
  };
  const addProduct = () => {
    if (products.length >= 5) {
      return false;
    } else {
      const newId = randomNumber();
      setProducts([...products, { id: newId, product: '', dosage: '' }]);
    }
  };
  const removeProduct = (id) => {
    if (products.length <= 1) {
      return false;
    } else {
      const filteredProducts = products.filter((product) => product.id !== id);
      setProducts(filteredProducts);
    }
  };
  const handleTreatment = (value) => {
    setPDWindow(value);
  };
  const validationSchema = Yup.object().shape({
    treatment_date: Yup.date().required(),
  });
  const {
    setValue,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema) });

  useEffect(() => {
    if (edit) {
      setValue('product', product?.product);
      setValue('dosage', product?.dosage);
    }
    setPDWindow(product?.pd_window);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  const submit = (data) => {
    let productValue = products.map((x) => x.product).join(' / ');
    let dosage = products.map((x) => x.dosage).join(' / ');
    let body;
    if (edit) {
      body = {
        order_id: product?.order_id,
        product: productValue,
        dosage: dosage,
        id: product?.id,
        pd_window: product?.pd_window,
        treatment_date: dayjs(data.treatment_date).format('YYYY-MM-DD'),
      };
    } else {
      body = {
        order_id: product?.order_id,
        product: productValue,
        dosage: dosage,
        pd_window: product?.pd_window,
        treatment_date: dayjs(data.treatment_date).format('YYYY-MM-DD'),
      };
    }
    setOpenProduct(false);
    GrowerAPI.addProduct(body).then((response) => {
      if (response.status === 200) {
        success(`Product ${edit ? 'updated' : 'added'} successfully`);
        setEdit(false);
        getData();
        reset({ product: '', dosage: '' });
        setProduct({});
      }
    });
  };
  const deleteProduct = () => {
    setOpenProduct(false);
    GrowerAPI.deleteProduct({ id: product?.id }).then((response) => {
      if (response.status === 200) {
        error(`Product deleted successfully`);
        getData();
        reset({ product: '', dosage: '' });
        setProduct({});
      }
    });
  };
  return (
    <CustomModal
      title={'Add Product'}
      open={openProduct}
      handleClose={() => {
        setOpenProduct(false);
        setProduct({});
        reset({});
        setEdit(false);
        setPDWindow('');
      }}
    >
      <Box component="form" onSubmit={handleSubmit(submit)}>
        <Grid container rowSpacing={2} columnSpacing={2}>
          <Grid item xs={8} sm={5}>
            <DateComponent
              name="treatment_date"
              label={t('AGRONOMY.PICK_DATE')}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item xs={8} sm={4}>
            <Typography sx={{ m: 0, p: 0, fontSize: 14 }}>{t('ADD_PRODUCT.T_WINDOW')}</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '1vw' }}>
              <Button
                sx={{ width: 28, height: 20 }}
                variant={pd_window === 'T1' ? 'contained' : 'outlined'}
                value={pd_window}
                onClick={() => handleTreatment('T1')}
              >
                {t('ADD_PRODUCT.T1')}
              </Button>
              <Button
                sx={{ width: 28, height: 20 }}
                variant={pd_window === 'T2' ? 'contained' : 'outlined'}
                value={pd_window}
                onClick={() => handleTreatment('T2')}
              >
                {t('ADD_PRODUCT.T2')}
              </Button>
              <Button
                sx={{ width: 28, height: 20 }}
                variant={pd_window === 'T3' ? 'contained' : 'outlined'}
                value={pd_window}
                onClick={() => handleTreatment('T3')}
              >
                {t('ADD_PRODUCT.T3')}
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Grid container rowSpacing={2} columnSpacing={2} sx={{ mt: 0.5 }}>
          {products.map((product, index) => (
            <>
              <Grid item xs={8} sm={5}>
                <FormControl variant="outlined" size="small" fullWidth>
                  <InputLabel htmlFor="outlined-adornment-farmName">{'Product'}</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-farmName"
                    type="text"
                    name="product"
                    label="Product"
                    value={product?.product}
                    onChange={(e) => handleChange(index, 'product', e.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={8} sm={4}>
                <FormControl variant="outlined" size="small" fullWidth>
                  <InputLabel htmlFor="outlined-adornment-farmName">{'Dosage'}</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-farmName"
                    type="text"
                    name="dosage"
                    label="Dosage"
                    value={product?.dosage}
                    onChange={(e) => handleChange(index, 'dosage', e.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={8} sm={1} alignContent="center">
                {products.length - 1 === index ? (
                  <AddCircleIcon sx={{ ml: 2 }} color="primary" onClick={addProduct} />
                ) : null}
              </Grid>
              <Grid item xs={8} sm={1} alignContent="center">
                <DeleteIcon
                  sx={{ ml: 2, visibility: `${products.length <= 1 ? 'hidden' : 'display'}` }}
                  onClick={() => removeProduct(product?.id)}
                  color="primary"
                />
              </Grid>
            </>
          ))}
        </Grid>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            mt: 2,
          }}
        >
          <Button
            onClick={() => deleteProduct()}
            sx={{ width: 150, height: 35 }}
            variant="outlined"
          >
            <Typography variant="body2">{t('GENERIC.DELETE')}</Typography>
          </Button>
          <Button type="submit" sx={{ width: 150, height: 35 }} variant="contained">
            {t('GENERIC.SAVE')}
          </Button>
        </Box>
      </Box>
    </CustomModal>
  );
};
export default Addproduct;
