import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  Box,
  Typography,
  TextField,
  Grid,
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  InputAdornment,
  Icon,
  OutlinedInput,
  Paper,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import Truck from '../../images/truck.png';
import { StyledBox, BpCheckbox } from '../../utils/commonFunctions';
import { useStateContext } from '../../utils/stateContext';
import Header from '../Header';
import SnackbarCustom from '../Snackbar';
import GrowerAPI from '../../api/grower';

const AddForm = () => {
  const [focused, setFocused] = useState(false);
  const [checked, setChecked] = useState(false);
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  const {
    t,
    navigate,
    initialState,
    setInitialState,
    setSelectedRows,
    getTotalArea,
    setGrowerState,
    handleGrowerUpdate,
    editable,
    setEditable,
    validateMandatoryProperties,
  } = useStateContext();
  const { sharedState, selectedRows, growerState } = initialState;

  const {
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm();

  const handleCheckboxChange = (e) => {
    if (e.target.checked === true) {
      setChecked(true);
      setGrowerState((prev) => {
        return {
          ...prev,
          order_del_address: sharedState.address1,
          order_del_city: sharedState.city1,
          order_del_postal_code: sharedState.postal_code1,
          order_contact_name: sharedState.contact_name,
          order_mobile_no: sharedState.mobile_no,
        };
      });
      setInitialState({
        ...initialState,
        growerState: {
          ...growerState,
          order_del_address: sharedState.address1,
          order_del_city: sharedState.city1,
          order_del_postal_code: sharedState.postal_code1,
          order_contact_name: sharedState.contact_name,
          order_mobile_no: sharedState.mobile_no,
        },
      });
      setFocused(true);
    } else {
      setChecked(false);
      setGrowerState((prev) => {
        return {
          ...prev,
          order_del_address: '',
          order_del_city: '',
          order_del_postal_code: '',
          order_contact_name: '',
          order_mobile_no: '',
        };
      });
      setInitialState({
        ...initialState,
        growerState: {
          ...growerState,
          order_del_address: '',
          order_del_city: '',
          order_del_postal_code: '',
          order_contact_name: '',
          order_mobile_no: '',
        },
      });
      setFocused(false);
    }
  };
  useEffect(() => {
    if (editable) {
      setChecked(true);
      setFocused(true);
      setGrowerState({
        id: sharedState?.orders[0]?.id,
        order_del_address: sharedState?.orders[0]?.order_del_address,
        order_contact_name: sharedState?.orders[0]?.order_contact_name,
        order_mobile_no: sharedState?.orders[0]?.order_mobile_no,
        order_add_info: sharedState?.orders[0]?.order_add_info,
        order_del_postal_code: sharedState?.orders[0]?.order_del_postal_code,
        order_del_city: sharedState?.orders[0]?.order_del_city,
      });
      setInitialState({
        ...initialState,
        growerState: {
          id: sharedState?.orders[0]?.id,
          order_del_address: sharedState?.orders[0]?.order_del_address,
          order_contact_name: sharedState?.orders[0]?.order_contact_name,
          order_mobile_no: sharedState?.orders[0]?.order_mobile_no,
          order_add_info: sharedState?.orders[0]?.order_add_info,
          order_del_postal_code: sharedState?.orders[0]?.order_del_postal_code,
          order_del_city: sharedState?.orders[0]?.order_del_city,
        },
        selectedRows: sharedState?.orders[0]?.order_fields,
      });
      setSelectedRows(sharedState?.orders[0]?.order_fields);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editable]);

  const submit = (data) => {};

  return (
    <>
      <Header isToggle={true} />
      <SnackbarCustom
        open={open}
        setOpen={setOpen}
        alertMessage={alertMessage}
        severity={severity}
      />
      <Box
        sx={{
          bgcolor: '#fff',
          border: '2px solid #E6E9ED',
          borderRadius: '5px',
          margin: '1vw',
          width: 'auto',
          height: 'auto',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: '0.5vw',
            borderBottom: '3px solid #DDDDDD',
            padding: '0.5vw 0.5vw',
          }}
        >
          <ArrowBackIcon
            fontSize="small"
            onClick={() => {
              if (editable) {
                navigate(-1);
                setEditable(false);
              } else {
                navigate(`/home/fielddetails`);
              }
            }}
          />
          <p style={{ fontSize: '14px', fontWeight: 550 }}>
            {t('GENERIC.DELIVERY') + ' ' + t('GENERIC.DETAILS')}
          </p>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Box
            sx={{ width: '40%', height: '100%', padding: '1vw 2vw 2vw 8vw', borderRadius: '10px' }}
          >
            <Paper
              sx={{ backgroundColor: '#F0F9FD', borderRadius: '10px', border: '2px solid #F0F9FD' }}
            >
              <Typography
                sx={{
                  textAlign: 'center',
                  fontSize: '12px',
                  color: 'white',
                  backgroundColor: '#0091DF',
                  padding: '0.5vw 0vw',
                  borderTopLeftRadius: '10px',
                  borderTopRightRadius: '10px',
                }}
              >
                {t('DELIVERY.ORDER_SUMMARY')}
              </Typography>
              <Box sx={{ padding: '0.5vw 1vw' }}>
                <StyledBox borderBottom="1px solid #B5CDD7">
                  <Typography sx={{ fontSize: '14px', fontWeight: 550 }}>
                    {t('DELIVERY.SELECTED_FIELDS')}
                  </Typography>
                  <Typography sx={{ fontSize: '14px', fontWeight: 550 }}>
                    {t('GENERIC.SIZE')}
                  </Typography>
                </StyledBox>
                {selectedRows.map((item) => {
                  return (
                    <StyledBox>
                      <Typography sx={{ fontSize: '12px' }}>{item.field_name} </Typography>
                      <Typography sx={{ fontSize: '12px' }}>{item.area}</Typography>
                    </StyledBox>
                  );
                })}
                <StyledBox>
                  <Typography sx={{ fontSize: '14px', fontWeight: 550 }}>
                    {t('DELIVERY.TOTAL_AREA')}
                  </Typography>
                  <Typography sx={{ fontSize: '14px', fontWeight: 550 }}>
                    {getTotalArea()}
                  </Typography>
                </StyledBox>
                <Typography sx={{ fontSize: '12px', padding: '0.7vw 0vw' }}>
                  {t('DELIVERY.ACCEPTED')}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.3vw' }}>
                  <span>€</span>
                  <Typography variant="body2" sx={{ lineHeight: 1 }}>
                    <h4 style={{ fontSize: '20px', color: '#0091DF' }}>
                      {(getTotalArea() * 59).toFixed(2)}
                    </h4>
                    <span style={{ fontSize: '10px' }}>{t('CHECKOFFER.PRICE')}</span>
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Box>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit(submit)}
            sx={{ height: '100%', width: '60%', padding: '1vw 2vw 3vw 3vw' }}
          >
            <Typography sx={{ textTransform: 'uppercase', fontWeight: '550', color: '#0B617F' }}>
              {t('DELIVERY.THANK_YOU')}
            </Typography>
            <Typography variant="body2" sx={{ fontSize: '12px' }}>
              {t('DELIVERY.NOTE') + '  ' + t('GENERIC.MANDATORY')}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: '1vw',
                mt: 2,
              }}
            >
              <img src={Truck} style={{ height: '5%', width: '5%' }} alt="" />
              <Typography sx={{ fontWeight: '550', color: '#0B617F' }}>
                {t('GENERIC.DEL_ADDRESS')}
              </Typography>
              <hr style={{ border: '0.1px solid #0B617F', width: '65%' }} />
            </Box>

            <FormControlLabel
              sx={{ mt: 1 }}
              control={
                <BpCheckbox checked={checked} onChange={handleCheckboxChange} color="primary" />
              }
              label={<Typography sx={{ fontSize: '80%' }}>{t('GENERIC.SAME_AS')}</Typography>}
            />

            <Grid container rowSpacing={2} columnSpacing={2} sx={{ padding: '0vw 0vw' }}>
              <Grid item xs={12} sm={6}>
                <FormControl variant="outlined" size="small" fullWidth focused={focused}>
                  <InputLabel htmlFor="outlined-adornment-deliveryAddress">
                    {t('FIRSTMEETING.ADDRESS') + '*'}
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-deliveryAddress"
                    type="text"
                    name="order_del_address"
                    value={growerState.order_del_address}
                    onChange={handleGrowerUpdate}
                    endAdornment={
                      <InputAdornment position="end">
                        <Icon color="primary" edge="end"></Icon>
                      </InputAdornment>
                    }
                    label={t('FIRSTMEETING.ADDRESS')}
                    // {...register("order_del_address")}
                    error={
                      getValues('order_del_address')
                        ? false
                        : errors.order_del_address
                          ? true
                          : false
                    }
                  />
                  <span style={{ color: 'red', fontSize: '12px' }}>
                    {errors.order_del_address?.message}
                  </span>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl variant="outlined" size="small" fullWidth focused={focused}>
                  <InputLabel htmlFor="outlined-adornment-deliveryCity">
                    {t('FIRSTMEETING.CITY') + '*'}
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-deliveryCity"
                    type="text"
                    name="order_del_city"
                    value={growerState.order_del_city}
                    onChange={handleGrowerUpdate}
                    endAdornment={
                      <InputAdornment position="end">
                        <Icon color="primary" edge="end"></Icon>
                      </InputAdornment>
                    }
                    label={t('FIRSTMEETING.CITY')}
                    //   {...register("order_del_city")}
                    error={
                      getValues('order_del_city') ? false : errors.order_del_city ? true : false
                    }
                  />
                  <span style={{ color: 'red', fontSize: '12px' }}>
                    {errors.order_del_city?.message}
                  </span>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl variant="outlined" size="small" fullWidth focused={focused}>
                  <InputLabel htmlFor="outlined-adornment-deliveryPostalCode">
                    {t('FIRSTMEETING.POSTAL_CODE') + '*'}
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-deliveryPostalCode"
                    type="text"
                    name="order_del_postal_code"
                    value={growerState.order_del_postal_code}
                    onChange={handleGrowerUpdate}
                    endAdornment={
                      <InputAdornment position="end">
                        <Icon color="primary" edge="end"></Icon>
                      </InputAdornment>
                    }
                    label={t('FIRSTMEETING.POSTAL_CODE')}
                    //    {...register("order_del_postal_code")}
                    error={
                      getValues('order_del_postal_code')
                        ? false
                        : errors.order_del_postal_code
                          ? true
                          : false
                    }
                  />
                  <span style={{ color: 'red', fontSize: '12px' }}>
                    {errors.order_del_postal_code?.message}
                  </span>
                </FormControl>
              </Grid>
            </Grid>
            <Box sx={{ display: 'flex', gap: '2vw', marginTop: '1vw' }}>
              <FormControl variant="outlined" size="small" fullWidth focused={focused}>
                <InputLabel htmlFor="outlined-adornment-contactName">
                  {t('GENERIC.CONTACT') + '*'}
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-contactName"
                  type="text"
                  name="order_contact_name"
                  value={growerState.order_contact_name}
                  onChange={handleGrowerUpdate}
                  endAdornment={
                    <InputAdornment position="end">
                      <Icon color="primary" edge="end">
                        <PersonOutlinedIcon />
                      </Icon>
                    </InputAdornment>
                  }
                  label={t('GENERIC.CONTACT')}
                />
                <span style={{ color: 'red', fontSize: '12px' }}></span>
              </FormControl>
              <FormControl variant="outlined" size="small" fullWidth focused={focused}>
                <InputLabel htmlFor="outlined-adornment-mobilenum">
                  {t('GENERIC.MOBILE_NO') + '*'}
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-mobilenum"
                  type="text"
                  name="order_mobile_no"
                  value={growerState.order_mobile_no}
                  onChange={handleGrowerUpdate}
                  endAdornment={
                    <InputAdornment position="end">
                      <Icon color="primary" edge="end">
                        <PhoneAndroidOutlinedIcon />
                      </Icon>
                    </InputAdornment>
                  }
                  label={t('GENERIC.MOBILE_NO')}
                />
                <span style={{ color: 'red', fontSize: '12px' }}></span>
              </FormControl>
            </Box>
            <TextField
              label={t('DELIVERY.ADDITIONAL_INFO')}
              fullWidth
              name="order_add_info"
              value={growerState.order_add_info}
              onChange={handleGrowerUpdate}
              size="small"
              sx={{ margin: '2vw 0vw' }}
            />
            <Button
              onClick={() => {
                let mandatoryProperties = [
                  'order_del_address',
                  'order_del_city',
                  'order_del_postal_code',
                  'order_contact_name',
                  'order_mobile_no',
                ];
                let isValid = validateMandatoryProperties(
                  [{ ...growerState }],
                  mandatoryProperties
                );
                if (editable) {
                  if (!isValid) {
                    setOpen(true);
                    setAlertMessage(t('ALERT.MSG8'));
                    setSeverity('error');
                    return;
                  }
                  return GrowerAPI.updateOrder(growerState).then((response) => {
                    if (response.status === 200) {
                      setOpen(true);
                      setAlertMessage(t('ALERT.MSG7'));
                      setSeverity('success');
                      setTimeout(() => {
                        setEditable(false);
                        navigate(`/home`);
                      }, 3000);
                    } else {
                      navigate(`/home/growerOverview`);
                    }
                  });
                } else {
                  if (isValid) {
                    navigate(`/home/reviewdetails`);
                  } else {
                    setOpen(true);
                    setAlertMessage(t('ALERT.MSG8'));
                    setSeverity('error');
                  }
                }
              }}
              sx={{ display: 'block', height: 40, width: 300, margin: '1vw 16vw' }}
              variant="contained"
            >
              {editable ? t('GENERIC.UPDATE') : t('DELIVERY.PROCEED')}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default AddForm;
