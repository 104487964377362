import React, { useState } from 'react';
import {
  Select,
  Box,
  Button,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Typography,
  InputAdornment,
  Icon,
  OutlinedInput,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MailIcon from '@mui/icons-material/Mail';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Header from '../Header';
import { useStateContext } from '../../utils/stateContext';
import CustomModal from '../CustomModal';
import LoginAPI from '../../api/login';
import UserAPI from '../../api/user';
import { CSVLink } from 'react-csv';

const Profile = () => {
  const {
    t,
    navigate,
    setSubscriptionList,
    userDetails,
    setUser,
    snackbar: { success, error },
  } = useStateContext();
  const [salutation, setSalutation] = useState('');
  const [language, setLanguage] = useState('');

  const [openModal, setOpenModal] = useState(false);

  const getCharacterValidationError = (str) => {
    return `Your password must have at least 1 ${str} character`;
  };

  const validationSchema = Yup.object().shape({
    salutation: Yup.string(),
    firstName: Yup.string().required(t('REGISTER.ERR_FIRST')),
    lastName: Yup.string().required(t('REGISTER.ERR_LAST')),
    email: Yup.string().required(t('REGISTER.ERR_EMAIL')).email(t('REGISTER.ERR_EMAIL_INVALID')),
    password: Yup.string()
      .required(t('REGISTER.ERR_PASS'))
      // check minimum characters
      .min(8, t('REGISTER.ERR_MIN'))
      // different error messages for different requirements
      .matches(/[0-9]/, getCharacterValidationError('digit'))
      .matches(/[a-z]/, getCharacterValidationError('lowercase'))
      .matches(/[A-Z]/, getCharacterValidationError('uppercase'))
      .matches('[!@#$%^&*(),.?":{}|<>]', getCharacterValidationError('special')),
    confirmPassword: Yup.string().oneOf([Yup.ref('password'), null]),
    language: Yup.string(),
  });

  const defaultValues = {
    salutation: '',
    firstName: '',
    lastName: '',
    email: '',
    lang: '',
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues,
  });

  const submit = (data) => {
    let body = {
      UID: userDetails?.gigya_uuid,
      email: data?.email,
      profile: JSON.stringify({ firstName: data?.firstName, lastName: data?.lastName }),
      password: data?.password,
      newPassword: data?.newPassword,
      lang: data?.lang,
    };
    LoginAPI.setAccountInfo(body).then((response) => {
      console.log(response, 'res');
    });
  };

  const deleteAccount = () => {
    UserAPI.deleteUser({ id: userDetails?.id }).then((response) => {
      if (response?.status === 200) {
        LoginAPI.deleteAccount(userDetails?.gigya_uuid)
          .then((response) => {
            if (response?.statusCode === 200) {
              setUser(null);
              localStorage.clear();
              sessionStorage.clear();
              success(t('PROFILE.DELETED_SUCCESFULLY'));
              setSubscriptionList([]);
              setTimeout(() => {
                navigate('/');
              }, 3000);
            }
          })
          .catch(errors);
      } else {
        error(t('PROFILE.CANNOT_DELETE'));
        setSubscriptionList([]);
      }
    });
  };

  const csvData = [
    ['Gigya uuid', 'Email', 'Name'],
    [`${userDetails?.gigya_uuid}`, `${userDetails?.email}`, `${userDetails?.name}`],
  ];

  return (
    <Box sx={{ width: '100%' }}>
      <Header isToggle={true} />
      <CustomModal open={openModal} handleClose={() => setOpenModal(false)}>
        <Typography>{t('PROFILE.DELETE_ACCOUNT')}</Typography>

        <Typography sx={{ mt: 3, fontWeight: 550, fontSize: 14 }}>
          {t('PROFILE.DELETE_ACCOUNT_CONFIRM')}
        </Typography>
        <Typography sx={{ fontSize: 12, mt: 2, textAlign: 'center' }}>
          {t('PROFILE.DELETE_ALL_DATA')}
        </Typography>
        <Typography sx={{ fontSize: 12, mt: 1, mb: 3 }}>
          {t('PROFILE.DELETE_24_HOUR_WARNING')}
        </Typography>
        <Button
          type="button"
          sx={{ width: 150, height: 30, m: 'auto', display: 'block' }}
          variant="contained"
          onClick={deleteAccount}
        >
          {t('PROFILE.DELETE_CONFIRM_BUTTON')}
        </Button>
      </CustomModal>
      <Box
        sx={{
          bgcolor: '#fff',
          border: '2px solid #E6E9ED',
          borderRadius: '5px',
          margin: '1vw',
          width: '98%',
          height: '85vh',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '1vw',
            borderBottom: '2px solid #DDDDDD',
            padding: '0.5vw',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5vw' }}>
            <ArrowBackIcon
              fontSize="small"
              onClick={() => {
                navigate(`/home`);
              }}
            />
            <p style={{ fontSize: '14px', fontWeight: 550 }}>{t('PROFILE.MY_PROFILE')}</p>
          </Box>
          <Box>
            <CSVLink data={csvData} filename={'user_data.csv'}>
              <Button
                sx={{
                  color: '#00BCFF',
                  border: '1.5px solid #00BCFF',
                  fontSize: '12px',
                  fontWeight: 530,
                  padding: '0.1vw 0.2vw',
                  mr: 2,
                }}
              >
                Export
              </Button>
            </CSVLink>
            <Button
              onClick={() => setOpenModal(true)}
              sx={{
                color: 'red',
                border: '1.5px solid red',
                fontSize: '12px',
                fontWeight: 530,
                padding: '0.1vw 0.2vw',
              }}
            >
              {t('PROFILE.DELETE_BUTTON')}
            </Button>
          </Box>
        </Box>
        <Box component="form" noValidate onSubmit={handleSubmit(submit)} sx={{ mt: 2 }}>
          <Grid container columnSpacing={1} rowSpacing={1.5} columns={12} justifyContent="center">
            <Grid item xs={10} sm={5}>
              <FormControl size="small" fullWidth>
                <InputLabel id="salutationLabel">{t('REGISTRATION.SALUTATION')}</InputLabel>
                <Select
                  labelId="salutationLabel"
                  id="salutation"
                  name="salutation"
                  value={salutation}
                  label={t('REGISTRATION.SALUTATION')}
                  {...register('salutation')}
                  onChange={(e) => setSalutation(e.target.value)}
                >
                  <MenuItem value="">
                    <em>{t('NONE')}</em>
                  </MenuItem>
                  <MenuItem value="Mr">{t('PROFILE.MR')}</MenuItem>
                  <MenuItem value="Mrs">{t('PROFILE.MRS')}</MenuItem>
                  <MenuItem value="Miss">{t('PROFILE.MISS')}</MenuItem>
                  <MenuItem value="Ms">{t('PROFILE.MS')}</MenuItem>
                  <MenuItem value="Dr">{t('PROFILE.DR')}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={10} sm={5}></Grid>
            <Grid item xs={10} sm={5}>
              <FormControl variant="outlined" size="small" fullWidth>
                <InputLabel htmlFor="outlined-adornment-firstName">
                  {t('REGISTRATION.FIRST')}
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-firstName"
                  type="text"
                  name="firstName"
                  endAdornment={
                    <InputAdornment position="end">
                      <Icon edge="end" color="primary">
                        <PersonOutlineIcon />
                      </Icon>
                    </InputAdornment>
                  }
                  label={t('REGISTRATION.FIRST')}
                  autoComplete="firstName"
                  {...register('firstName')}
                  error={errors.firstName ? true : false}
                />{' '}
                <span style={{ color: 'red', fontSize: '12px' }}>{errors.firstName?.message}</span>
              </FormControl>
            </Grid>
            <Grid item xs={10} sm={5}>
              <FormControl variant="outlined" size="small" fullWidth>
                <InputLabel htmlFor="outlined-adornment-lastName">
                  {t('REGISTRATION.LAST')}
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-lastName"
                  type="text"
                  name={t('REGISTRATION.FIRST')}
                  endAdornment={
                    <InputAdornment position="end">
                      <Icon color="primary" edge="end">
                        <PersonOutlineIcon />
                      </Icon>
                    </InputAdornment>
                  }
                  label={t('REGISTRATION.LAST')}
                  autoComplete="lastName"
                  {...register('lastName')}
                  error={errors.lastName ? true : false}
                />{' '}
                <span style={{ color: 'red', fontSize: '12px' }}>{errors.lastName?.message}</span>
              </FormControl>
            </Grid>
            <Grid item xs={10} sm={5}>
              <FormControl variant="outlined" size="small" fullWidth>
                <InputLabel htmlFor="outlined-adornment-email">{t('LOGIN.EMAIL')}</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-email"
                  type="email"
                  name="email"
                  endAdornment={
                    <InputAdornment position="end">
                      <Icon edge="end" color="primary">
                        <MailIcon />
                      </Icon>
                    </InputAdornment>
                  }
                  label={t('LOGIN.EMAIL')}
                  autoComplete="email"
                  {...register('email')}
                  error={errors.email ? true : false}
                />{' '}
                <span style={{ color: 'red', fontSize: '12px' }}>{errors.email?.message}</span>
              </FormControl>
            </Grid>

            <Grid item xs={10} sm={5}>
              <FormControl size="small" fullWidth>
                <InputLabel id="demo-language">{t('REGISTRATION.LANGUAGE_PREFERENCE')}</InputLabel>
                <Select
                  labelId="demo-language"
                  id="language"
                  name="language"
                  value={language}
                  label="Language Preference"
                  {...register('lang')}
                  onChange={(e) => setLanguage(e.target.value)}
                >
                  <MenuItem value="">
                    <em>{t('NONE')}</em>
                  </MenuItem>
                  <MenuItem value="en">{t('PROFILE.ENGLISH')}</MenuItem>
                  <MenuItem value="fr">{t('PROFILE.FRENCH')}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={10} sm={5}></Grid>
          </Grid>
          <Grid container justifyContent="center">
            <Button type="submit" sx={{ width: 250, height: 40, mt: 3, mb: 3 }} variant="contained">
              {t('PROFILE.SAVE_NOW')}
            </Button>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default Profile;
