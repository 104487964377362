import axios from 'axios';

class ApiDocs {
    baseURL: string

    constructor() {
        this.baseURL = process.env.REACT_APP_BASE_URL || '';
    }

    getAuthToken() {
        const token = localStorage.getItem('token');
        return token ? JSON.parse(token) : null;
    }

    getHeaders() {
        const authToken = this.getAuthToken();
        return {
            'Content-Type': 'application/json',
            'x-api-key': 'PreDimalAvamME5uQNXLWIw190',
            ...(authToken && { authorizationToken: `Bearer ${authToken}` }),
        };
    }

    async fetchOpenApiSpec() {
        try {
            const response = await axios.get(`${this.baseURL}/openapi.json`, {
                headers: this.getHeaders(),
            });
            return response.data;
        } catch (error) {
            console.error('Error fetching OpenAPI spec:', error);
            throw error;
        }
    }
}

const docs = new ApiDocs();
export default docs
