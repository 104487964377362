import axios from 'axios';

const Grower = new (class {
  getAuthToken() {
    const token = localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')) : null;
    return token;
  }

  headers() {
    return {
      'Content-Type': 'application/json',
      'x-api-key': 'PreDimalAvamME5uQNXLWIw190',
      authorizationToken: `Bearer ${this.getAuthToken()}`,
    };
  }
  queryString(query) {
    return Object.entries(query)
      .filter((entry) => entry[0] && entry[1])
      .map((entry) => entry.join('='))
      .join('&');
  }

  addGrower(body) {
    return axios
      .post(`/api/add_grower`, body, { headers: this.headers() })
      .then(({ data }) => data)
      .catch((err) => {
        console.log(err);
      });
  }

  updateGrower(body) {
    return axios
      .put(`/api/update_grower`, body, { headers: this.headers() })
      .then(({ data }) => data)
      .catch((err) => {
        console.log(err);
      });
  }

  addOrder(body) {
    return axios
      .post(`/api/create_order`, body, { headers: this.headers() })
      .then(({ data }) => data)
      .catch((err) => {
        console.log(err);
      });
  }

  updateOrder(body) {
    return axios
      .put(`/api/update_order_details`, body, { headers: this.headers() })
      .then(({ data }) => data)
      .catch((err) => {
        console.log(err);
      });
  }

  getAllGrowers(body) {
    const queryString = this.queryString(body);
    return axios
      .get(`/api/all_growers?${queryString}`, { headers: this.headers() })
      .then(({ data }) => data);
  }

  getAllGrowersOld(body) {
    const queryString = this.queryString(body);
    return axios
      .get(`/api/all_growers?${queryString}`, { headers: this.headers() })
      .then(({ data }) => data);
  }

  getGrowerById(id) {
    return axios.get(`/api/grower/${id}`, { headers: this.headers() }).then(({ data }) => data);
  }

  getAgronomyGrowers(body) {
    const queryString = this.queryString(body);
    return axios
      .get(`/api/agronomy_grower_details?${queryString}`, { headers: this.headers() })
      .then(({ data }) => data);
  }

  deleteGrowers(body) {
    return axios
      .post(`/api/grower_delete`, body, { headers: this.headers() })
      .then(({ data }) => data)
      .catch((err) => {
        console.log(err);
      });
  }

  getFieldViewData(userEmail) {
    return axios
      .post(`/api/fetch_field_view_data`, userEmail, { headers: this.headers() })
      .then(({ data }) => data)
      .catch((err) => {
        console.log(err);
      });
  }

  updateFieldviewData(body) {
    return axios
      .put(`/api/update_fields`, body, { headers: this.headers() })
      .then(({ data }) => data)
      .catch((err) => {
        console.log(err);
      });
  }

  inviteGrower(body) {
    return axios
      .post(`/api/send_email`, body, { headers: this.headers() })
      .then(({ data }) => data)
      .catch((err) => {
        console.log(err);
      });
  }

  setActualSprayDate(fieldName, body) {
    return axios
      .post(`/api/add_tretment_apllication_date/${fieldName}`, body, {
        headers: this.headers(),
      })
      .then(({ data }) => data)
      .catch((err) => {
        console.log(err);
      });
  }

  getNotifications(dist_id) {
    return axios
      .get(`/api/notification_list/${dist_id}`, { headers: this.headers() })
      .then(({ data }) => data)
      .catch((err) => {
        console.log(err);
      });
  }

  markAsRead(body) {
    return axios
      .post(`/api/update_notifications`, body, { headers: this.headers() })
      .then(({ data }) => data)
      .catch((err) => {
        console.log(err);
      });
  }

  deleteTreatments(body) {
    return axios
      .post(`/api/delete_product_application`, body, { headers: this.headers() })
      .then(({ data }) => data)
      .catch((err) => {
        console.log(err);
      });
  }

  addObservationDate(body, fieldName) {
    return axios
      .post(`/api/update_observations/${fieldName}`, body, { headers: this.headers() })
      .then(({ data }) => data)
      .catch((err) => {
        console.log(err);
      });
  }

  async addComment(body) {
    return axios
      .post(`/api/add_or_update_compilance_comments`, body, {
        headers: this.headers(),
      })
      .then(({ data }) => data)
      .catch((err) => {
        console.log(err);
      });
  }

  async getComplianceComments(fieldId) {
    return axios
      .get(`/api/get-compliance_comments/${fieldId}`, {
        headers: this.headers(),
      })
      .then(({ data }) => data)
      .catch((err) => console.log(err));
  }

  addProduct(body) {
    return axios
      .post(`/api/add_or_update_product_delivery`, body, { headers: this.headers() })
      .then(({ data }) => data)
      .catch((err) => {
        console.log(err);
      });
  }

  deleteProduct(body) {
    return axios
      .post(`/api/delete_product_delivery`, body, { headers: this.headers() })
      .then(({ data }) => data)
      .catch((err) => {
        console.log(err);
      });
  }
})();

export default Grower;
