import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  InputBase,
  Icon,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Header from '../Header';
import FieldView from '../../images/fieldview.png';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import HouseOutlinedIcon from '@mui/icons-material/HouseOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import Divider from '@mui/material/Divider';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GrowerAPI from '../../api/grower';
import CircularProgress from '../CircularProgress';
import {
  StyledTableCell,
  NoDataAvailable,
  FlexBox,
  EditIcon,
  sortData,
} from '../../utils/commonFunctions';
import { useStateContext } from '../../utils/stateContext';
import SnackbarCustom from '../Snackbar';
import SearchIcon from '@mui/icons-material/Search';

const GrowerDetail = () => {
  const {
    t,
    navigate,
    initialState,
    rows,
    setRows,
    setSelectedRows,
    handleCheckboxClick,
    setEditable,
    open,
    setOpen,
    severity,
    alertMessage,
    setInitialState,
  } = useStateContext();

  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState('');
  const [data, setData] = useState(rows);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  let { sharedState, selectedIndexes } = initialState;

  function getData() {
    setLoading(false);
    setInitialState({ ...initialState, selectedRows: [], selectedIndexes: [] });
    return GrowerAPI.getFieldViewData({ user_email: sharedState.email })
      .then((data) => {
        setLoading(true);
        if (data?.status === 200) {
          let sortedData = sortData(data?.body, 'field_name').map((x) => ({
            ...x,
            address: 'sub',
          }));
          setRows(sortedData);
        } else {
          setRows([
            {
              field_id: '0837d6e5-5519-457f-b510-7b7ab745708e',
              item_status: 'ACTIVE',
              area: 13.16,
              field_name: 'kazlak',
              variety: '',
              soil_type: '',
              sowing_date: '',
              mixed_variety: '',
              region: '',
              user_email: 'attila.hajdu@climate.com',
              id: 632,
              country_code: 'HU',
              centroid_coord: '18.85754870379993, 46.08119041468179',
              address: null,
              previous_crop: 'Peas',
              tillage: false,
              crop: 'Wheat',
            },
            {
              field_id: '014e8f2b-17f7-42f0-9c08-6b978aedc712',
              item_status: 'ACTIVE',
              area: 13.29,
              field_name: 'tuna_2017',
              variety: '',
              soil_type: '',
              sowing_date: '',
              mixed_variety: '',
              region: '',
              user_email: 'attila.hajdu@climate.com',
              id: 631,
              country_code: 'RS',
              centroid_coord: '19.063723525987733, 45.99351536604761',
              address: null,
              previous_crop: 'Sunflower',
              tillage: false,
              crop: 'Wheat',
            },
          ]);
        }
      })
      .catch((err) => console.log(err));
  }

  const search = (data, query) => {
    if (query) {
      return data.filter((row) => {
        return row.field_name.toLowerCase().includes(query.toLowerCase());
      });
    } else {
      return data;
    }
  };
  useEffect(() => setData(rows), [rows]);
  useEffect(() => {
    let data = search(rows, query);
    setData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return (
    <Box sx={{ width: '100%' }}>
      <Header isToggle={true} />
      <SnackbarCustom
        open={open}
        setOpen={setOpen}
        alertMessage={alertMessage}
        severity={severity}
      />
      <Box
        sx={{
          bgcolor: '#fff',
          border: '2px solid #E6E9ED',
          borderRadius: '5px',
          margin: '1vw',
          width: '98%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '1vw',
            borderBottom: '2px solid #DDDDDD',
            padding: '0.5vw',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5vw' }}>
            <ArrowBackIcon
              fontSize="small"
              onClick={() => {
                navigate(`/home`);
                setRows([]);
                setSelectedRows([]);
                setEditable(false);
              }}
            />
            <p style={{ fontSize: '14px', fontWeight: 550 }}>{t('GROWER.DETAIL')}</p>
          </Box>
          <Button
            onClick={() => getData()}
            sx={{
              color: '#00617F',
              border: '1.5px solid #00617F',
              fontSize: '12px',
              fontWeight: 530,
              padding: '0.1vw 0.2vw',
            }}
          >
            {t('GROWER.IMPORT_DATA')}
            <span sx={{ height: 30, width: 100, alignContent: 'center' }}>
              <img src={FieldView} alt="fieldview" />
            </span>
          </Button>
        </Box>
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary
            sx={{ height: 10 }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: '' }}>
              <FlexBox
                children={<CalendarMonthOutlinedIcon />}
                text1={t('GENERIC.START_DATE')}
                text2={sharedState.start_date ? sharedState.start_date : ''}
              />
              <Divider orientation="vertical" variant="middle" flexItem />
              <FlexBox
                children={<HouseOutlinedIcon />}
                text1={t('GENERIC.FARM')}
                text2={sharedState.name}
              />
              <Divider orientation="vertical" variant="middle" flexItem />
              <FlexBox
                children={<PersonOutlinedIcon />}
                text1={t('GENERIC.CONTACT')}
                text2={sharedState.contact_name}
              />
              <Divider orientation="vertical" variant="middle" flexItem />
              <FlexBox
                children={<PhoneAndroidOutlinedIcon />}
                text1={t('GENERIC.MOBILE_NO')}
                text2={sharedState.mobile_no ? sharedState.mobile_no : 'not available'}
              />
              <Divider orientation="vertical" variant="middle" flexItem />
              <FlexBox
                children={<EmailOutlinedIcon />}
                text1={t('REGISTRATION.EMAIL')}
                text2={sharedState.email}
              />
              <Divider orientation="vertical" variant="middle" flexItem />
              <FlexBox
                children={<PhoneOutlinedIcon />}
                text1={t('GENERIC.TELEPHONE')}
                text2={sharedState.telephone_no}
              />
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex' }}>
                <FlexBox
                  children={<HouseOutlinedIcon />}
                  text1={t('GENERIC.ADDRESS')}
                  text2={sharedState.address1}
                />
                {/* <Divider orientation="vertical" variant="middle" flexItem />
       <FlexBox children={<LocalShippingOutlinedIcon/>} text1={t('GENERIC.DEL_ADDRESS')} text2={sharedState.address2}/> */}
              </Box>
              <EditIcon
                onClick={() => {
                  setEditable(true);
                  navigate('/home/addgrower');
                }}
              />
            </Box>
          </AccordionDetails>
        </Accordion>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '0.5vw 0.5vw',
          }}
        >
          <Typography sx={{ fontSize: '12px' }}>
            <span style={{ color: 'red' }}>*</span>
            {t('GROWER.MIN_FIELD_SIZE')}
          </Typography>
          <Typography sx={{ fontSize: '12px' }}>
            <span style={{ color: 'red' }}>*</span>
            {t('GROWER.TYPE')}
          </Typography>
          <Typography sx={{ fontSize: '12px' }}>
            <span style={{ color: 'red' }}>*</span>
            {t('GROWER.MAX_FIELD_SIZE')}
          </Typography>

          <Button
            onClick={() => navigate('/home/checkoffer')}
            variant="contained"
            disabled={selectedIndexes.length < 1 ? true : false}
            sx={{ height: 30, width: 130, fontSize: '70%', padding: '0.5vw', marginRight: '1vw' }}
          >
            {t('GROWER.CHECK_OFFER')}
          </Button>
        </Box>
        <Box>
          <TableContainer sx={{ maxHeight: 800, overflow: 'auto' }}>
            <Table sx={{ width: '100%' }} size="small" aria-label="a dense table" stickyHeader>
              <TableHead>
                <TableRow>
                  <StyledTableCell sx={{ width: '50%', backgroundColor: '#0091DF' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Typography>{t('GENERIC.FIELD')}</Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          backgroundColor: 'white',
                          padding: '0vw 0.5vw',
                        }}
                      >
                        <InputBase
                          sx={{ width: 300, height: 25 }}
                          placeholder={t('SEARCH')}
                          onChange={(e) => setQuery(e.target.value)}
                          inputProps={{ 'aria-label': 'search fields' }}
                        />
                        <Icon sx={{ color: 'black' }}>
                          <SearchIcon />
                        </Icon>
                      </Box>
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell sx={{ width: '50%', backgroundColor: '#0091DF' }}>
                    {`${t('GENERIC.SIZE')} (HA)`}
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, index) => {
                  return (
                    <TableRow>
                      <TableCell
                        size="small"
                        padding="none"
                        sx={{ width: '50%', border: '1px solid #DDDDDD' }}
                      >
                        {' '}
                        <Checkbox
                          color="primary"
                          checked={selectedIndexes.indexOf(row.id) !== -1}
                          onChange={() => handleCheckboxClick(row)}
                        />
                        {row.field_name}
                      </TableCell>
                      <TableCell sx={{ width: '50%', border: '1px solid #DDDDDD' }}>
                        {row.area}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        {!loading && (
          <Box>
            <CircularProgress />
          </Box>
        )}
        {loading && !rows.length > 0 && (
          <NoDataAvailable text1={t('GROWER.NO_DATA_NOTE')} text2={t('GROWER.IMPORT_NOTE')} />
        )}
      </Box>
    </Box>
  );
};
export default GrowerDetail;
