import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './components/Login/login';
import ResetPassword from './components/ResetPassword/sendResetMail';
import Admin from './components/Admin';
import ApiDocsPage from './pages/api-docs';
import Registration from './components/Registration/registration';
import AddForm from './components/AddForm/AddForm';
import GrowerDetail from './components/GrowerDetail';
import CheckOfferPage from './components/CheckOfferPage';
import FieldDetails from './components/FieldDetails';
import DeliveryDetails from './components/DeliveryDetails';
import ReviewDetails from './components/ReviewDetails';
import GrowerOverview from './components/GrowerDetailOverview';
import Profile from './components/Profile';
import Notifications from './components/Notifications';
import { ProtectedRoutes } from './utils/ProtectedRoutes';
import { StateProvider } from './utils/stateContext';
import { Box, Snackbar, Alert, Typography } from '@mui/material';
import { onMessageListener } from './firebase';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';

import '@tcc/labz-design-system/dist/assets/style.css';

function App() {
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: '', body: '' });

  onMessageListener()
    .then((payload) => {
      setShow(true);
      setNotification({ title: payload.notification.title, body: payload.notification.body });
      console.log(payload);
    })
    .catch((err) => console.log('failed: ', err));

  return (
    <Router>
      <div className="App">
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          sx={{ mt: 4 }}
          open={show}
          autoHideDuration={5000}
          onClose={() => setShow(false)}
        >
          <Alert
            severity="info"
            variant="filled"
            onClose={() => setShow(false)}
            sx={{
              width: '100%',
              '&.MuiAlert-filledInfo': {
                backgroundColor: '#0091DF',
                color: 'white',
              },
            }}
            icon={<CircleNotificationsIcon fontSize="large" />}
          >
            <Typography variant="body2">
              <b>{notification.title}</b>
              <br />
              {notification.body}
            </Typography>
          </Alert>
        </Snackbar>
      </div>
      <StateProvider>
        <Routes>
          <Route path="/" element={<Login />}></Route>
          <Route path="/send-reset-mail" element={<ResetPassword />}></Route>
          <Route element={<ProtectedRoutes />}>
            <Route path="/home" element={<Admin />}></Route>
            <Route path="/api_docs" element={<ApiDocsPage />}></Route>
            <Route path="/home/addgrower" element={<AddForm />}></Route>
            <Route path="/home/growerdetail" element={<GrowerDetail />}></Route>
            <Route path="/home/checkoffer" element={<CheckOfferPage />}></Route>
            <Route path="/home/fielddetails" element={<FieldDetails />}></Route>
            <Route path="/home/deliverydetails" element={<DeliveryDetails />}></Route>
            <Route path="/home/reviewdetails" element={<ReviewDetails />}></Route>
            <Route path="/home/growerOverview" element={<GrowerOverview />}></Route>
            <Route path="/home/notifications" element={<Notifications />}></Route>
            <Route path="/profile" element={<Profile />}></Route>
          </Route>
          <Route path="/register" element={<Registration />}></Route>
          <Route
            path="*"
            element={
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: 450,
                  width: '100%',
                }}
              >
                Page not Found
              </Box>
            }
          ></Route>
        </Routes>
      </StateProvider>
    </Router>
  );
}

export default App;
