import React, { useState, useEffect } from 'react';
import { HtmlTooltip } from '../../utils/commonFunctions';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Icon from '@mui/material/Icon';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { useStateContext } from '../../utils/stateContext';

const getPasswordStrength = (password) => {
  if (
    password.length >= 8 &&
    /[A-Z]/.test(password) &&
    /[0-9]/.test(password) &&
    // eslint-disable-next-line no-useless-escape
    /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(password)
  ) {
    return { color: 'green', text: 'STRONG' };
  }
  if (password.length >= 6 && /[A-Z]/.test(password) && /[0-9]/.test(password)) {
    return { color: '#40ff00', text: 'MEDIUM' };
  }
  if (password.length > 0) {
    return { color: 'red', text: 'WEAK' };
  }
  return { color: '', text: '' };
};

export default function CustomizedTooltips({ children, password, ...props }) {
  const { t } = useStateContext();
  const styleObj = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  };
  const [tooltip, setTooltip] = useState({ color: '', text: '' });

  useEffect(() => {
    setTooltip(getPasswordStrength(password));
  }, [password]);
  return (
    <div>
      <HtmlTooltip
        arrow
        placement="right"
        title={
          <Box>
            <Typography>{t('REGISTER.ERR_MIN')}</Typography>
            <Box sx={styleObj}>
              <p>{t('PASSWORD.HINT1')}</p>
            </Box>
            <Box sx={styleObj}>
              <Icon>
                <TaskAltIcon fontSize="small" sx={{ color: 'other' }} />
              </Icon>
              <p>{t('PASSWORD.HINT2')}</p>
            </Box>
            <Box sx={styleObj}>
              <Icon>
                <TaskAltIcon fontSize="small" sx={{ color: 'other' }} />
              </Icon>
              <p>{t('PASSWORD.HINT3')}</p>
            </Box>
            <Box sx={styleObj}>
              <Icon>
                <TaskAltIcon fontSize="small" sx={{ color: 'other' }} />
              </Icon>
              <p>{t('PASSWORD.HINT4')}</p>
            </Box>
            <Typography variant="body2">
              {t('PASSWORD.STRENGTH')}:{t(`${tooltip.text}`)}
            </Typography>
            <Box sx={{ height: 7, width: 180, bgcolor: tooltip.color, borderRadius: '2px' }}></Box>
            <Typography variant="body2" sx={{ opacity: 0.5 }}>
              {t('PASSWORD.NOTE')}
            </Typography>
          </Box>
        }
      >
        {children}
      </HtmlTooltip>
    </div>
  );
}
