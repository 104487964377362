import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Typography, Button, Paper, Grid } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import HouseOutlinedIcon from '@mui/icons-material/HouseOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import { StyledBox, FlexBox, EditIcon } from '../../utils/commonFunctions';
import { useStateContext } from '../../utils/stateContext';
import Header from '../Header';
import SnackbarCustom from '../Snackbar';
import GrowerAPI from '../../api/grower';

const ReviewDetails = () => {
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  const {
    t,
    navigate,
    initialState,
    getTotalArea,
    setEditable,
    setEditFromReview,
    userDetails,
    getData,
  } = useStateContext();
  const { sharedState, growerState, selectedRows } = initialState;
  const { handleSubmit } = useForm();

  const submit = () => {
    let body = {
      order_farm_address: sharedState?.address1,
      order_del_address: growerState.order_del_address,
      order_del_city: growerState.order_del_city,
      order_del_postal_code: growerState.order_del_postal_code,
      order_contact_name: growerState.order_contact_name,
      order_mobile_no: growerState?.order_mobile_no,
      order_price: getTotalArea() * 59,
      order_add_info: growerState.order_add_info,
      order_total_ha: getTotalArea(),
      order_total_no_fields: selectedRows.length,
      grower_uuid: sharedState.uuid,
      created_by: userDetails?.id,
      order_fields: selectedRows.map(
        ({
          field_id,
          variety,
          previous_crop,
          sowing_date,
          soil_type,
          tillage,
          mixed_variety,
          region,
          field_name,
          area,
          soil_code,
          centroid_coord,
        }) => {
          return {
            field_id,
            variety: variety,
            previous_crop,
            sowing_date,
            soil_type: soil_type,
            tillage: tillage ? tillage : false,
            mixed_variety: mixed_variety ? mixed_variety : false,
            region,
            field_name,
            area,
            soil_code,
            longitude: centroid_coord.split(', ')[0],
            latitude: centroid_coord.split(', ')[1],
          };
        }
      ),
    };
    GrowerAPI.addOrder(body).then((response) => {
      if (response.status === 201) {
        setOpen(true);
        setAlertMessage(t('ALERT.MSG18'));
        setSeverity('success');
        getData();
        setTimeout(() => {
          navigate('/home');
        }, 2000);
        return;
      } else {
        setOpen(true);
        setAlertMessage(t('ALERT.MSG12'));
        setSeverity('error');
      }
    });
  };
  return (
    <>
      <Header isToggle={true} />
      <Box
        sx={{
          bgcolor: '#fff',
          border: '2px solid #E6E9ED',
          borderRadius: '5px',
          margin: '1vw',
          width: 'auto',
          height: 'auto',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: '0.5vw',
            borderBottom: '3px solid #DDDDDD',
            padding: '0.5vw 0.5vw',
          }}
        >
          <ArrowBackIcon fontSize="small" onClick={() => navigate(`/home/deliverydetails`)} />
          <p style={{ fontSize: '14px', fontWeight: 550 }}>{t('REVIEW.DETAILS')}</p>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Box
            sx={{ width: '40%', height: '100%', padding: '1vw 2vw 2vw 8vw', borderRadius: '10px' }}
          >
            <Paper
              sx={{ backgroundColor: '#F0F9FD', borderRadius: '10px', border: '2px solid #F0F9FD' }}
            >
              <Typography
                sx={{
                  textAlign: 'center',
                  fontSize: '12px',
                  color: 'white',
                  backgroundColor: '#0091DF',
                  padding: '0.5vw 0vw',
                  borderTopLeftRadius: '10px',
                  borderTopRightRadius: '10px',
                }}
              >
                {t('DELIVERY.ORDER_SUMMARY')}
              </Typography>
              <Box sx={{ padding: '0.5vw 1vw' }}>
                <StyledBox borderBottom="1px solid #B5CDD7">
                  <Typography sx={{ fontSize: '14px', fontWeight: 550 }}>
                    {t('DELIVERY.SELECTED_FIELDS')}
                  </Typography>
                  <Typography sx={{ fontSize: '14px', fontWeight: 550 }}>
                    {t('GENERIC.SIZE')}
                  </Typography>
                </StyledBox>
                {selectedRows.map((item) => {
                  return (
                    <StyledBox>
                      <Typography sx={{ fontSize: '12px' }}>{item.field_name} </Typography>
                      <Typography sx={{ fontSize: '12px' }}>{item.area}</Typography>
                    </StyledBox>
                  );
                })}
                <StyledBox>
                  <Typography sx={{ fontSize: '14px', fontWeight: 550 }}>
                    {t('DELIVERY.TOTAL_AREA')}{' '}
                  </Typography>
                  <Typography sx={{ fontSize: '14px', fontWeight: 550 }}>
                    {getTotalArea()}
                  </Typography>
                </StyledBox>
                <Typography sx={{ fontSize: '12px', padding: '0.7vw 0vw' }}>
                  {t('DELIVERY.ACCEPTED')}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.3vw' }}>
                  <span>€</span>
                  <Typography variant="body2" sx={{ lineHeight: 1 }}>
                    <h4 style={{ fontSize: '20px', color: '#0091DF' }}>
                      {(getTotalArea() * 59).toFixed(2)}
                    </h4>
                    <span style={{ fontSize: '10px' }}>(Price per HA-59 euros)</span>
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Box>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit(submit)}
            sx={{ height: '100%', width: '60%', padding: '1vw 2vw 3vw 3vw' }}
          >
            <Typography sx={{ textTransform: 'uppercase', fontWeight: '550', color: '#0B617F' }}>
              {t('REVIEW.DETAILS').toUpperCase()}
            </Typography>
            <Typography variant="body2">{t('REVIEW.NOTE')}</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              <EditIcon
                onClick={() => {
                  setEditable(true);
                  setEditFromReview(true);
                  navigate('/home/addgrower');
                }}
              />
            </Box>
            <Box
              sx={{
                border: '2px solid #0091DF4A',
                borderRadius: '10px',
                padding: '0.5vw',
                margin: '0.5vw 0vw',
              }}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Grid container>
                  <Grid item xs={4}>
                    <FlexBox
                      children={<CalendarMonthOutlinedIcon />}
                      text1={t('GENERIC.START_DATE')}
                      text2={sharedState.start_date}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FlexBox
                      children={<HouseOutlinedIcon />}
                      text1={t('GENERIC.FARM')}
                      text2={sharedState.name}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FlexBox
                      children={<PersonOutlinedIcon />}
                      text1={t('GENERIC.CONTACT')}
                      text2={sharedState.contact_name}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FlexBox
                      children={<PhoneAndroidOutlinedIcon />}
                      text1={t('GENERIC.MOBILE_NO')}
                      text2={sharedState.mobile_no ? sharedState.mobile_no : 'not available'}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FlexBox
                      children={<PhoneOutlinedIcon />}
                      text1={t('GENERIC.TELEPHONE')}
                      text2={sharedState.telephone_no}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FlexBox
                      children={<EmailOutlinedIcon />}
                      text1={t('REGISTRATION.EMAIL')}
                      text2={sharedState.email}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FlexBox
                      children={<HouseOutlinedIcon />}
                      text1={t('GENERIC.ADDRESS')}
                      text2={sharedState.address1}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FlexBox
                      children={<LocalShippingOutlinedIcon />}
                      text1={t('GENERIC.DEL_ADDRESS')}
                      text2={growerState?.order_del_address}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography sx={{ fontSize: '14px', fontWeight: 550 }}>
                {t('FIELD.DETAILS')}
              </Typography>
              <EditIcon
                onClick={() => {
                  setEditFromReview(true);
                  navigate('/home/fielddetails');
                }}
              />
            </Box>
            <Box sx={{ border: '2px solid #0091DF4A', borderRadius: '10px', padding: '0.5vw' }}>
              {selectedRows.map((field, index) => (
                <>
                  <Box sx={{ display: 'flex', gap: '3vw', margin: '0.5vw 0vw' }}>
                    <Typography sx={{ fontSize: '13px' }}>
                      {t('GENERIC.FIELD')} <br />
                      <b>{field?.field_name}</b>
                    </Typography>
                    <Typography sx={{ fontSize: '13px' }}>
                      {t('GENERIC.SIZE')} <br />
                      {field?.area}
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', gap: '2vw', margin: '0.5vw 0vw' }}>
                    <Typography sx={{ fontSize: '13px' }}>
                      {t('FIELD.VARIETY')} <br />
                      {field?.variety}
                    </Typography>
                    <Typography sx={{ fontSize: '13px' }}>
                      {t('FIELD.MIXED')} <br />
                      {field?.mixed_variety ? 'Yes' : 'No'}
                    </Typography>
                    <Typography sx={{ fontSize: '13px' }}>
                      {t('FIELD.PREVIOUS_CROP')}
                      <br />
                      {field?.previous_crop}
                    </Typography>
                    <Typography sx={{ fontSize: '13px' }}>
                      {t('FIELD.TILLAGE')}
                      <br />
                      {field?.tillage ? 'Yes' : 'No'}
                    </Typography>
                    <Typography sx={{ fontSize: '13px' }}>
                      {t('FIELD.SOWING_DATE')} <br />
                      {field?.sowing_date}
                    </Typography>
                    <Typography sx={{ fontSize: '80%' }}>
                      {t('FIELD.SOIL_TYPE')} <br />
                      {field?.soil_type}
                    </Typography>
                  </Box>
                  <hr
                    style={{
                      color: '#0091DF4A',
                      display: `${selectedRows.length - 1 === index ? 'none' : 'visible'}`,
                    }}
                  />
                </>
              ))}
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                margin: '0.5vw 0vw',
              }}
            >
              <Typography sx={{ fontSize: '14px', fontWeight: 550 }}>
                {t('GENERIC.DELIVERY') + ' ' + t('GENERIC.DETAILS')}
              </Typography>
            </Box>
            <Box
              sx={{
                border: '2px solid #0091DF4A',
                borderRadius: '10px',
                padding: '0.5vw',
                margin: '0.5vw 0vw',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '7vw' }}>
                <FlexBox
                  children={<PersonOutlinedIcon />}
                  text1={t('GENERIC.CONTACT')}
                  text2={growerState.order_contact_name}
                />
                <FlexBox
                  children={<PhoneAndroidOutlinedIcon />}
                  text1={t('GENERIC.MOBILE_NO')}
                  text2={
                    growerState.order_mobile_no ? growerState.order_mobile_no : 'not available'
                  }
                />
              </Box>
              <Typography sx={{ fontSize: '13px', color: '#0B617F', paddingLeft: '0.6vw' }}>
                {t('DELIVERY.ADDITIONAL_INFO')} <br />
                {growerState.order_add_info}
              </Typography>
            </Box>
            <SnackbarCustom
              open={open}
              setOpen={setOpen}
              alertMessage={alertMessage}
              severity={severity}
            />
            <Button
              type="submit"
              sx={{ display: 'block', height: 40, width: 300, margin: '2vw 16vw' }}
              variant="contained"
            >
              {t('DELIVERY.PROCEED')}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default ReviewDetails;
