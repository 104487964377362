import * as React from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Box } from '@mui/material';
import resetMail from '../../images/resetMail.png';
import CancelIcon from '@mui/icons-material/Cancel';
import { useTranslation } from 'react-i18next';

const InviteModal = ({ open, handleClose, growers }) => {
  const { t } = useTranslation();
  return (
    <Box sx={{ height: '100%', width: '100%', position: 'relative' }}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
            height: 'auto',
            bgcolor: 'white',
            borderRadius: '10px',
            p: 2,
          }}
        >
          <Box>
            <CancelIcon onClick={handleClose} sx={{ float: 'right' }} />
            <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
              <img src={resetMail} alt="congratulations" />
              <Typography variant="body2" sx={{ my: 1, fontWeight: 550 }}>
                {t('AGRONOMY.TEXT1')}
              </Typography>
              <Typography variant="body2" sx={{ mb: 2, flexGrow: 10 }}>
                {t('AGRONOMY.TEXT2')}:
              </Typography>
              {growers.map((email, i) => (
                <Typography key={`${email}${i}`} variant="body2">
                  <b>{email}</b>
                </Typography>
              ))}
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};
export default InviteModal;
