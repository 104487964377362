import React from 'react';
import { Box, Modal, Typography } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

const CustomModal = ({ children, open, handleClose, title }) => {
  return (
    <Box sx={{ height: '100%', width: '100%', position: 'relative' }}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 'auto',
            padding: '1vw',
            height: 'auto',
            bgcolor: 'white',
            borderRadius: '10px',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography sx={{ fontWeight: 550, fontSize: '0.9em' }}>{title}</Typography>
            <CancelIcon onClick={() => handleClose()} fontSize="small" sx={{ cursor: 'pointer' }} />
          </Box>
          <Box sx={{ mt: 2, p: 0 }}>{children}</Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default CustomModal;
